import React from "react";
import { useTranslation } from "react-i18next";

interface TimeComparisonBarProps {
  travelTime: string;
  idealTime: any;
  operationsTime: string;
  breakTime: string;
}

const convertToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return (hours * 3600 + minutes * 60 + seconds) || 0;
};
const formatTime = (seconds: number): string => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return [hrs, mins, secs].map(unit => String(unit).padStart(2, "0")).join(":");
};
const TimeComparisonBar: React.FC<TimeComparisonBarProps> = ({
  travelTime,
  idealTime,
  operationsTime,
  breakTime,
}) => {
  const travelSeconds = convertToSeconds(travelTime);
  const idealSeconds = idealTime?.idle_time_seconds;
  const operationsSeconds = convertToSeconds(operationsTime);
  const breakSeconds = convertToSeconds(breakTime);
  const { t } = useTranslation();
  const idletime = Math.max(
    0,
    (idealSeconds || 0) - operationsSeconds - breakSeconds
  );
  const drivingTime = travelSeconds
    ? Math.max(0, travelSeconds - idletime)
    : 0;
  const totalTime = drivingTime  + idletime+ operationsSeconds + breakSeconds;
  if (totalTime === 0) {
    return <div className="text-center text-gray-500">{t("visit.No_Data_Available")}</div>;
  }

  const getPercentage = (time: number) => (time / totalTime) * 100;


  return (
    <div className="w-full flex flex-col">
      <div className="relative w-full h-6 rounded-lg overflow-hidden border border-gray-300 flex">
        <div
          style={{ width: `${getPercentage(drivingTime)}%`, backgroundColor: "#A855F7" }}
          title={`Travel Time: ${formatTime(drivingTime)}`}
        ></div>
        <div
style={{ width: `${getPercentage(idletime)}%`, backgroundColor: "#FACC15" }}
title={`Idle Time: ${formatTime(idletime)}`}
        ></div>
        <div
          style={{ width: `${getPercentage(operationsSeconds)}%`, backgroundColor: "#22C55E" }}
          title={`Operations Time: ${operationsTime}`}
        ></div>
        <div
          style={{ width: `${getPercentage(breakSeconds)}%`, backgroundColor: "#FB923C" }}
          title={`Break Time: ${breakTime}`}
        ></div>
      </div>
      <div className="flex justify-between mt-2 text-sm">
        <div className="flex items-center">
          <span className="w-4 h-4 rounded-sm mr-2" style={{ backgroundColor: "#A855F7" }}></span>{t('visit.TRAVEL_TIME')}
        </div>
        <div className="flex items-center">
          <span className="w-4 h-4 rounded-sm mr-2" style={{ backgroundColor: "#FACC15" }}></span>{t('visit.IDEAL_TIME')}
        </div>
        <div className="flex items-center">
          <span className="w-4 h-4 rounded-sm mr-2" style={{ backgroundColor: "#22C55E" }}></span>{t('visit.OPERATIONS_TIME')}
        </div>
        <div className="flex items-center">
          <span className="w-4 h-4 rounded-sm mr-2" style={{ backgroundColor: "#FB923C" }}></span>{t('visit.BREAK_TIME')}
        </div>
      </div>
    </div>
  );
};

export default TimeComparisonBar;
