const ROLE_GROUPS = {
  ZAMZAM_ADMIN: ["NWC_Admin"],
  ZAMZAM_INVENTORY:["NWC_Inventory"],
  ZAMZAM_ORDER:["NWC_C_Order"],
  ZAMZAM_FINANCE:["NWC_Finance"],
  ZAMZAM_ADDRESS:["NWC_Address"],
  DELIVERY_DRIVER: ["Delivery Supervisor"],
  PRESELLER_SALESMAN_SUPERVISOR: [
      "Supervisor"
    ],
  ZAMZAM_ORDER_HISTORY:["NWC_R_Order"],
};


export default ROLE_GROUPS;