import React from "react";
import {formatNumberWithoutFraction} from '../utility/index'

interface MetricBarProps {
  text?: string;
  count: number;
  color: string;
  bgcolor?: string;
  number?: string;
  highestValue: number;
}

const MetricBar: React.FC<MetricBarProps> = ({
  text,
  count,
  color,
  bgcolor,
  number,
  highestValue,
}) => {
  const widthPercentage = highestValue > 0 ? (count / highestValue) * 100 : 0;

  return (
    <li className="flex justify-between items-center border-b-2 pb-2">
      <div className="flex gap-6 w-[40%]">
        <div className="text-[13px] font-normal text-[#4A4A4A]">{number}</div>
        <span className="text-[13px] font-normal text-[#4A4A4A]">{text}</span>
      </div>
      <div className="flex items-center gap-6 w-[60%] justify-end">
        <div className="w-full flex justify-end">
          <div
            className="h-2 rounded-full transition-all duration-300"
            style={{
              width: `${widthPercentage}%`,
              backgroundColor: color,
            }}
          ></div>
        </div>
        <span
          className="font-bold text-lg border rounded-md px-6 py-1 w-full max-w-[70px] min-w-[35px] flex items-center justify-center"
          style={{
            color: color,
            borderColor: color,
            backgroundColor: bgcolor,
          }}
        >
          {formatNumberWithoutFraction(count)}
        </span>
      </div>
    </li>
  );
};

export default MetricBar;
