import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

interface CustomDatePickerProps {
  selectedYear: Date | null;
  setSelectedYear: React.Dispatch<React.SetStateAction<Date | null>>;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedYear,
  setSelectedYear,
}) => {
  const { t } = useTranslation();

  const currentYear = new Date();
  return (
    <DatePicker
      selected={selectedYear}
      onChange={(date) => setSelectedYear (date)}
      showYearPicker
      dateFormat="yyyy"
      placeholderText={t("finance.selectYear")}
      popperPlacement="bottom"
      portalId="root-portal"
      maxDate={currentYear}
      className="lg:w-52 w-[90vw] md:w-full mt-0 lg:mt-6 px-3 py-2 border rounded-md text-sm text-gray-700 focus:ring-2 outline-none z-10 placeholder-gray-600"
    />
  );
};

export default CustomDatePicker;
