import React, { useEffect, useState } from "react";
import Search from "../../../assets/Search.svg";
import PreviousArrow from "../../../assets/previous-arrow.svg";
import NextArrow from "../../../assets/next-arrow.svg";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";
interface FilterAndPaginationProps {
  toggeleLoding: () => void;
  currentPage: any,
  setCurrentPage: any,
  setSearch: any,
  search: any,
  totalPages: any,
  handleSearch: any,
  isPopupOpen: any,
  setIsPopupOpen: any,
}

const PaginationAndSearch: React.FC<FilterAndPaginationProps> = ({
  toggeleLoding,
  currentPage,
  setCurrentPage,
  search,
  setSearch,
  totalPages,
  handleSearch,
  isPopupOpen,
  setIsPopupOpen
}) => {

  const { t } = useTranslation();

  const [lang, setLang] = useState<string | null>(null);

  useEffect(()=>{
    const lang = currentLang();
    setLang(lang);
  },[])


  return (
    <div className="flex flex-col space-y-4 p-4 bg-white shadow-sm rounded-md md:flex-row md:items-center md:justify-between">
      <div className="flex flex-col space-y-2 sm:flex-row sm:items-center sm:justify-between text-sm text-gray-800">
        <span className="flex items-end pt-2 text-sm" >{t("manage.showing")} {" "} {t("manage.page")} {currentPage} {t("manage.outOf")} {totalPages}</span>
        <div className="flex items-center space-x-4">
          <div
            className={`flex items-center justify-center space-x-1 ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : ""
              }`}
           
          >
           <img className="pr-2" src={PreviousArrow} alt="previous" style={{marginLeft:'8px'}} />

            <button
              onClick={() => setCurrentPage((prev: any) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              {t("manage.previous")}
            </button>
          </div>
          <div
          
            className={`flex items-center justify-center${currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : ""
              }`}
          >
            <button
            
              onClick={() =>
                setCurrentPage((prev: any) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              {t("manage.next")}
            </button>
            <img className="pl-2" src={NextArrow} alt="next" />
          </div>
        </div>
      </div>

      <div className={`flex flex-col gap-2 sm:flex-row lg:items-center lg:space-x-4 w-full lg:w-auto ${lang === 'Arabic' ? 'gap-2' : 'gap-1'}`}>
      <div className="flex items-center border rounded-md px-3 py-2 w-full sm:w-80">
          <img src={Search} alt="search" />
          <input
            type="text"
            placeholder={t("manage.searchByReceiverName")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            className={`border-none outline-none w-full ${lang === 'Arabic' ? 'pr-2' : 'pl-3'} text-sm text-gray-700 placeholder-gray-500`}
          />
        </div>


        <button
          onClick={handleSearch}
          className="bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-full sm:w-20"
        >
          {t("manage.search")}
        </button>
        {/* <button
          onClick={() => setIsPopupOpen(true)}
          className="bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-full sm:w-20 lg:w-40"
        >
          {t("createOrder.Add_New_Address")}
        </button> */}
      </div>
    </div>
  );
};

export default PaginationAndSearch;
