import React, { useEffect, useState } from "react";
import icon from "../assets/rem.png";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

interface Warehouse {
  warehouse_name: string;
  warehouse_nameAr:string;
  production_id: string;
  UnitText: string;
  UoMTextAr: string;
  description: string;
  descriptionArabic:string;
  unit: string;
  available_stocks: number;
  in_quantity: number;
  blocked: number;
  returns: number;
  in_transits: number;
  open_qty: number;
  shipped_order_qty: number;
}

interface WarehouseData {
  items: Warehouse[];
}

interface TableData {
  results: Array<{
    StorageLocName: string;
    StorageLocNameAr:string;
    NavStockToItem: Array<{
      MaterialId: string;
      MaterialDesc: string;
      MaterialDescAr:string
      AvailableStock: string;
      UnitText: string;
      InQualityInspection: string;
      Blocked: string;
      Return: string;
      InTransit: string;
      OpenQty: string;
      ShippedOrderQty: string;
      UoMTextAr: string;
      TotalOpenQty: string;
      TotalShippedOrderQty: string;
    }>;
  }>;
}

interface ViewInventoryProps {
  showSummesion: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
  tableData: TableData | null;
}

const Table: React.FC<ViewInventoryProps> = ({
  showSummesion,
  loading,
  setLoading,
  tableData,
}) => {
  const [warehouseData, setWarehouseData] = useState<WarehouseData | null>(null);
  const [sumData, setSumData] = useState({
    total_available_stocks: 0,
    total_in_quantity: 0,
    total_blocked: 0,
    total_returns: 0,
    total_in_transits: 0,
    TotalOpenQty: 0,
    TotalShippedOrderQty: 0
  });
  console.log("sumData", sumData);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    setWarehouseData(null);
    if (tableData) {
      const transformedData = {
        items: tableData?.results.flatMap((result) =>
          result.NavStockToItem.map((item) => ({
            warehouse_name: result.StorageLocName,
            warehouse_nameAr: result.StorageLocNameAr,

            production_id: item.MaterialId,
            description: item.MaterialDesc,
            descriptionArabic:item.MaterialDescAr,
            UoMTextAr: item.UoMTextAr,
            unit: "N/A",
            UnitText: item.UnitText,
            available_stocks: parseFloat(item.AvailableStock) || 0,
            in_quantity: parseFloat(item.InQualityInspection) || 0,
            blocked: parseFloat(item.Blocked) || 0,
            returns: parseFloat(item.Return) || 0,
            in_transits: parseFloat(item.InTransit) || 0,
            open_qty: parseInt(item.OpenQty) || 0,
            shipped_order_qty: parseInt(item.ShippedOrderQty) || 0,
            TotalOpenQty: parseInt(item.TotalOpenQty) || 0,
            TotalShippedOrderQty: parseInt(item.TotalShippedOrderQty) || 0,

          }))
        ),
      };

      setTimeout(() => {
        setWarehouseData(transformedData);
        setLoading(false);
      }, 2000);
    }
  }, [tableData, setLoading]);

  const getAllSubmissions = () => {
    if (!warehouseData || !warehouseData.items) return;

    let totals = {
      total_available_stocks: 0,
      total_in_quantity: 0,
      total_blocked: 0,
      total_returns: 0,
      total_in_transits: 0,
      TotalOpenQty: 0,
      TotalShippedOrderQty: 0
    };

    warehouseData.items.forEach((item) => {
      console.log('item',item);
      totals.total_available_stocks += item.available_stocks;
      totals.total_in_quantity += item.in_quantity;
      totals.total_blocked += item.blocked;
      totals.total_returns += item.returns;
      totals.total_in_transits += item.in_transits;
      totals.TotalOpenQty += item.open_qty;
      totals.TotalShippedOrderQty += item.shipped_order_qty;
    });

    setSumData(totals);
  };

  useEffect(() => {
    if (showSummesion) {
      getAllSubmissions();
    }
  }, [showSummesion, warehouseData]);
const [lang,setLang]=useState(false)

  useEffect(()=>{
    var arabic= localStorage.getItem("lang");
    console.log('arabic',arabic);
 
    if(arabic ==="Arabic")
    {
      setLang(true)
    }
   
  else{
    setLang(false)
  }
   },[])

  return (
<div className="pt-5 bg-gray-50">
      <div className="bg-white shadow-sm rounded-md overflow-hidden">
      <div
        className="overflow-y-auto"
      >
        <table className="min-w-full border-collapse overflow-x-auto scrollbar-hidden">
            <thead className="bg-[#E8F6FC] sticky top-0 ">
            <tr>
  <th
    className={`px-4  py-3 border text-xs font-medium text-black-500 whitespace-nowrap ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    {t("inventory.warehouseName")}
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    {t("inventory.productionID")}
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    {t("inventory.description")}
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    {t("inventory.unit")}
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
      className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
      
      <p className="text-xs">{t("inventory.availableStocks")}</p>
      
    </div>
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
      className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
      
      <p className="text-xs">{t("inventory.inQuantity")}</p>
      
    </div>
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
     className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
      
      <p className="text-xs">{t("inventory.blocked")}</p>
      
    </div>
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
      className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
      
      <p className="text-xs">{t("inventory.returns")}</p>
      
    </div>
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
      className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
       {/* {!lang && <img className="cursor-pointer w-4 h-4"  src={icon} alt="summesion" />} */}
      <p className="text-xs">{t("inventory.inTransits")}</p>
      
    </div>
    
    
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
     className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
      
      <p className="text-xs">{t("inventory.openOrderQty")}</p>
      
    </div>
  </th>
  <th
    className={`px-4 whitespace-nowrap py-3 border text-xs font-medium text-black-500 ${
      lang ? "text-right rtl" : "text-left"
    }`}
  >
    <div
     className={`flex ${lang ? "item-end space-x-reverse space-x-3" : "space-x-3 items-center"}`}
    >
      
      <p className="text-xs">{t("inventory.deliveryInTransist")}</p>
      
    </div>
  </th>
</tr>

            </thead>
            <tbody>
              {warehouseData && warehouseData.items?.length > 0 ? (
                warehouseData.items.map((warehouse, index) => (
                  <tr key={index} className="text-xs hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {/* {warehouse.warehouse_name} */}
                      {lang ? warehouse.warehouse_nameAr : warehouse.warehouse_name || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.production_id || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                        {lang ? warehouse.descriptionArabic : warehouse.description || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {lang ? warehouse.UoMTextAr : warehouse.UnitText || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.available_stocks.toLocaleString()|| 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.in_quantity.toLocaleString() || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.blocked.toLocaleString() || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.returns.toLocaleString() || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.in_transits.toLocaleString() || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.open_qty.toLocaleString() || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {warehouse.shipped_order_qty.toLocaleString() || 0}
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <tr>
                  <td colSpan={9} className="h-40 text-center align-middle">
                    <div className="flex justify-center items-center h-full">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={9} className="text-center py-4 text-xs">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
            {showSummesion && (
           <thead className="bg-[#005879] sticky bottom-0 text-gray-100">
           <tr>
             <th
               className={`px-4 py-3 border text-xs font-bold whitespace-nowrap ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
               {t("inventory.total")}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             ></th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             ></th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             ></th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
               {sumData.total_available_stocks.toLocaleString()}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
               {sumData.total_in_quantity.toLocaleString()}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
               {sumData.total_blocked.toLocaleString()}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
               {sumData.total_returns.toLocaleString()}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
               {sumData.total_in_transits.toLocaleString()}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
                {sumData.TotalOpenQty.toLocaleString()}
             </th>
             <th
               className={`px-4 py-3 border text-xs font-bold ${
                 lang ? "text-right rtl" : "text-left"
               }`}
             >
                {sumData.TotalShippedOrderQty.toLocaleString()}
             </th>
           </tr>
         </thead>
         
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;