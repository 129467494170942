import React, { useState, useEffect } from "react";
import Close from "../../../assets/close-circle.svg";
import ReturnCancelSelector from "../../../components/RejectionSelector";
import {
  getrejectedOrderReport,
  cancelDelivery,
  addDeliveryNote,
  reassignDelivery, 
  getDeliveryNotes
} from "../../../api/delivery-driver";

const cancellationReasonsEnum: Record<string, string> = {
  "Customer Cancelled": "customer_cancelled",
  "No answer": "no_answer",
  "Wrong Location": "wrong_location",
  "Outside delivery area": "outside_delivery_area",
  "Modify Quantity SKU": "modify_quantity_sku",
  "Payment/Credit issue": "payment_credit_issue",
  "Postponed delivery": "postpone_delivery",
  "Late delivery": "late_delivery",
  "Duplicate order": "duplicate_order",
  "Technical error": "technical_error",
  "Out of stock": "out_of_stock",
};

interface TimelinePopupProps {
  onClose: () => void;
  deliveryNumber: any;
  reasonofcancellation: any;
  driverId?: any;
}

const TimelinePopup: React.FC<TimelinePopupProps> = ({
  onClose,
  deliveryNumber,
  reasonofcancellation,
  driverId,
}) => {
  const [feedback, setFeedback] = useState("");
  const [selectedOption, setSelectedOption] = useState("Return Delivery to Driver");
  const [loading, setLoading] = useState(false);
  const [previousNotes, setPreviousNotes] = useState<any>([]);
  const [notesLoading, setNotesLoading] = useState(false);

  useEffect(() => {
    const fetchPreviousNotes = async () => {
      setNotesLoading(true);
      try {
        const notes = await getDeliveryNotes(deliveryNumber);
        setPreviousNotes(notes.data.notes.map((noteObj: any) => noteObj.note));
        console.log(notes.data.notes)
      } catch (error) {
        console.error("Failed to fetch previous notes", error);
      } finally {
        setNotesLoading(false);
      }
    };
    fetchPreviousNotes();
  }, [deliveryNumber]);

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value);
  };

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      alert("Please enter feedback.");
      return;
    }

    setLoading(true);

    const convertedReason = cancellationReasonsEnum[reasonofcancellation];

    try {
      if (selectedOption === "Cancel Order") {
        console.log("Cancelling Order...");
        await cancelDelivery(deliveryNumber, { reason: convertedReason });
        console.log("Order Cancelled Successfully");
      }

      if (selectedOption === "Return Delivery to Driver") {
        console.log("Reassigning Delivery...");
        await reassignDelivery(deliveryNumber, driverId);
        console.log("Delivery Reassigned Successfully");
      }

      console.log("Adding Note...");
      await addDeliveryNote(deliveryNumber, feedback, {});
      console.log("Note Added Successfully");

      setFeedback("");
      onClose();
    } catch (error) {
      console.error("Something went wrong", error);
      alert("Failed to submit feedback. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-lg w-full h-auto overflow-auto relative">
        <div className="flex justify-between items-center pb-6 border-b">
          <h2 className="text-2xl font-bold">Feedback</h2>
          <button className="text-3xl font-bold" onClick={onClose}>
            <img src={Close} alt="Close" />
          </button>
        </div>

        <ReturnCancelSelector
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <div className="mt-6">
          <h3 className="text-lg font-semibold">Previous Feedback</h3>
          <div className="border p-2 rounded-md bg-gray-100 max-h-32 overflow-auto">
            {notesLoading ? (
              <p>Loading notes...</p>
            ) : previousNotes.length > 0 ? (
              previousNotes.map((note:any, index:any  ) => (
                <p key={index} className="border-b last:border-none p-2">{note}</p>
              ))
            ) : (
              <p>No previous notes available.</p>
            )}
          </div>
        </div>

        <div className="mt-6">
          <textarea
            className="w-full border p-2 rounded-md"
            rows={4}
            placeholder="Enter your feedback..."
            value={feedback}
            onChange={handleFeedbackChange}
          />
          <div className="flex justify-end">
            <button
              className="bg-purple text-white px-4 py-2 mt-4 rounded-md hover:bg-purple-300"
              onClick={handleFeedbackSubmit}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelinePopup;
