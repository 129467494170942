import { getHeader, getUserInfo } from "../utility/index";
import axios from "./config";

interface ApiResponse {
    data: any;
}

const depositSummary = async (params: any, data: any): Promise<ApiResponse> => {
    const user_info = getUserInfo();
    const response = await axios.post<ApiResponse>(
        `/reports/${user_info.emp_id}/deposite-summary-report`,
        data,
        { headers: getHeader(), params }
    );
    return response;
};

const salesSummary = async (params: any, data: any): Promise<ApiResponse> => {
    const user_info = getUserInfo();
    const response = await axios.post<ApiResponse>(
        `/reports/${user_info.emp_id}/sales-summary-report`,
        data,
        { headers: getHeader(), params }
    );
    return response;
};

const invoiceSummary = async (params: any, data: any): Promise<ApiResponse> => {
    const user_info = getUserInfo();
    const response = await axios.post<ApiResponse>(
        `/reports/${user_info.emp_id}/invoice-summary-report`,
        data,
        { headers: getHeader(), params }
    );
    return response;
};

const getAccessibleReports = async (): Promise<ApiResponse> => {
    const user_info = getUserInfo();
    const response = await axios.get<ApiResponse>(
        `/reports/reports/accessible/${user_info.emp_id}`,
        {
            headers: getHeader()
        }
    );
    return response;
};

export default depositSummary;
export { salesSummary, invoiceSummary, getAccessibleReports  }
