import React, { useState } from "react";
import JourneyPlan from "./JourneyPlan";
import Report from "./Report";
import { useTranslation } from "react-i18next";
import UpArrow from '../../assets/up-arrow.svg'
import DownArrow from '../../assets/down-arrow.svg'
import Refresh from '../../assets/refresh.svg'
import TimeComparisonBar from "./TimeComparisonBar";
import { motion } from "framer-motion";
import { RotateCw } from "lucide-react";

interface ScheduleProps {
  employeeSchedule: any;
  handleDateChange: any;
  selectedDate: any;
  loader: boolean;
  routeForSelectedCustomer: any;
  setRouteForSelectedCustomer: any;
  idleTime: any,
  distanceTravelled: any
  handleRefresh: any
}

const ButtonGroup: React.FC<ScheduleProps> = ({
  employeeSchedule,
  handleDateChange,
  selectedDate,
  loader,
  routeForSelectedCustomer,
  setRouteForSelectedCustomer,
  idleTime,
  distanceTravelled,
  handleRefresh
}) => {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(t("ButtonGroup.button_journey_plan"));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttons = [
    t("ButtonGroup.button_journey_plan"),
    t("ButtonGroup.button_report"),
  ];

  const handleJourney = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <div className="w-full">
      <div className="flex gap-2 pl-4 pr-4 pt-1 pb-1 h-auto border-b-2 flex-wrap hidden md:flex">
        {buttons.map((button) => (
          <button
            onClick={() => setActiveButton(button)}
            className={`px-4 text-13 py-2  focus:outline-none transition-all duration-200 ease-in-out h-max font-semibold ${activeButton === button
              ? "bg-cyan text-white active:bg-blue-700"
              : "bg-lightgrey hover:bg-gray-300 active:bg-gray-700"
              }`}
          >
            {button}
          </button>
        ))}
        <div>
          <motion.button
            onClick={handleRefresh}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9, rotate: 360 }}
            className="p-2 rounded-full bg-purple text-white flex items-center justify-center   transition"
          >
            <RotateCw className="w-4 h-4" />
          </motion.button>
        </div>
        <div className="flex flex-col max-w-[120px]">
          <label className="text-grey text-10 font-medium ">{t('visit.select_date')}</label>
          <input
            className="font-semibold text-13"
            type="date"
            onChange={handleDateChange}
            value={selectedDate}
          />
        </div>
      </div>
      <div className="w-full md:hidden p-4">
        <div className="w-full flex justify-between pt-2">
          <div className="flex flex-col max-w-[120px] p-2">
            <label className="text-grey text-10 font-medium ">{t('visit.select_date')}</label>
            <input
              className="font-semibold text-13"
              type="date"
              onChange={handleDateChange}
              value={selectedDate}
            />
          </div>
          <div>
            <motion.button
              onClick={handleRefresh}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9, rotate: 360 }}
              className="p-2 rounded-full bg-purple text-white flex items-center justify-center   transition"
            >
              <RotateCw className="w-4 h-4" />
            </motion.button>
          </div>
        </div>

        <button
          className="w-full flex items-center justify-between p-2 border rounded-md shadow-sm  font-semibold p-4"
          onClick={handleJourney}
        >
          <span>{t('visit.journey_plan')}</span>
          <span className="ml-2">
            {isDropdownOpen ? <img src={UpArrow} alt='up' /> : <img src={DownArrow} alt='down' />}
          </span>
        </button>

      </div>

      <div>
        <div className="hidden md:block">
          {activeButton === t("ButtonGroup.button_journey_plan") && (
            <>
              <JourneyPlan
                employeeSchedule={employeeSchedule}
                loader={loader}
                setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                routeForSelectedCustomer={routeForSelectedCustomer}
              />
            </>
          )}
          {activeButton === t("ButtonGroup.button_report") && (
            <>
              <div className="p-4">
                <h2 className="font-semibold mb-4">{t('visit.TIME_COMPARISON')}</h2>
                <TimeComparisonBar travelTime={employeeSchedule?.employee_details?.driving_time || '0'}
                  idealTime={idleTime !== undefined ? idleTime : "0"} // keeping it static
                  operationsTime={employeeSchedule?.employee_details?.operation_time || '0'}
                  breakTime={employeeSchedule?.employee_details?.break_time || '0'} />
              </div>
              <Report employeeSchedule={employeeSchedule?.employee_details} idleTime={idleTime} distanceTravelled={distanceTravelled} />
            </>
          )}
        </div>
        <div className="md:hidden">
          {isDropdownOpen && (
            <>
              <JourneyPlan
                employeeSchedule={employeeSchedule}
                loader={loader}
                setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                routeForSelectedCustomer={routeForSelectedCustomer}

              />
            </>
          )}
          <div className="text-16 font-bold p-4 px-6">Report</div>
          <div className="p-4">
            <h2 className="font-semibold mb-4">{t("visit.TIME_COMPARISON")}</h2>
            <TimeComparisonBar travelTime={employeeSchedule?.employee_details?.driving_time || '0'}
              idealTime={idleTime !== undefined ? idleTime : "0"} // keeping it static
              operationsTime={employeeSchedule?.employee_details?.operation_time || '0'}
              breakTime={employeeSchedule?.employee_details?.break_time || '0'} />
          </div>
          <Report employeeSchedule={employeeSchedule?.employee_details} idleTime={idleTime} distanceTravelled={distanceTravelled} />
        </div>
      </div>
    </div>
  );
};

export default ButtonGroup;
