import React, { useEffect, useState } from "react";
import { DeliveryData } from "../OrderHistory";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";
import { currentLang } from "../../../utility/index";
import { useTranslation } from "react-i18next";

interface DeliveryModalProps {
  data: DeliveryData | undefined;
}

const metadata = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc: nwc,
  title: "Delivery Report",
};

function convertToMonth(monthString: number | null): string {
    if (!monthString) {
      return "Invalid month";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = month;
    if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }

const month = new Date(Date.now()).getMonth();
const year = new Date(Date.now()).getFullYear();

const ComponentToPrintDelivery: React.FC<DeliveryModalProps> = ({ data }) => {
  const { t } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);
  useEffect(() => {
    const lang = currentLang();
    if (lang === "Arabic") setIsArabic(true);
    else setIsArabic(false);
  }, []);
  return (
    <div id="print-content-delivery" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>{t("orders.date")}: {metadata?.date || "-"}</p>
      
            <div className="flex item center space-x-2">
              <img src={logo} alt="Logo" className="h-16" />
              <img
                src={nwc}
                alt="NWC Logo"
                className="h-16 self-start"
              />
            </div>
   
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {t(metadata?.title || "Dynamic Report")}
          </h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>{t("finance.month")} : </b>
            {convertToMonth(month)}
          </p>
          <p className="text-sm">
            <b>{t("finance.year")}: </b>
            {year}
          </p>
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-xl w-full lg:w-[80%] p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700 mt-4">
          <p>
            <span className="font-medium">{t("orders.shipmentNo")}:</span>{" "}
            {data?.ShipmentNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.deliveryNo")}:</span>{" "}
            {data?.DeliveryNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.orderNo")}:</span>{" "}
            {data?.OrderNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.outletName")}:</span>{" "}
            {data?.OutletName || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.receiverName")}:</span>{" "}
            {data?.ReceiverName || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.sfaOrderID")}:</span>{" "}
            {data?.SFAOrderID || "N/A"}
          </p>
          <p className="col-span-2">
            <span className="font-medium">{t("orders.address")}:</span>{" "}
            {data?.Address || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.customerID")}:</span>{" "}
            {data?.Customer || "N/A"}
          </p>
        </div>

        <div className="mt-4 border-t pt-2">
          <h3 className="text-lg font-semibold text-gray-800">{t("orders.items")}</h3>
          {data?.Items && data?.Items?.length > 0 ? (
            <div className="w-full">
              <table className="w-full mt-2 border-collapse text-sm">
                <thead>
                  <tr className="border-b bg-gray-100">
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.deliveryNo")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.itemCode")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.materialCode")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.materialEN")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.materialAR")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.quantity")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.remainingOrderQuantity")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${isArabic ? "text-right" : "text-left"}`}>
                      {t("orders.uom")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.Items.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2 whitespace-nowrap">
                        {item.DeliveryNo || "N/A"}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        {item.ItemCode || "N/A"}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        {item.MaterialCode || "N/A"}
                      </td>
                      <td className="p-2 truncate">
                        {item.MaterialTextEn || "N/A"}
                      </td>
                      <td className="p-2 truncate">
                        {item.MaterialTextAr || "N/A"}
                      </td>
                      <td className="p-2 text-center">
                        {item.Quantity || "0"}
                      </td>
                      <td className="p-2">{item.pending_quantity || "N/A"}</td>
                      <td className="p-2 whitespace-nowrap">
                        {item.UoMText || "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-500 mt-2">No items available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponentToPrintDelivery;
