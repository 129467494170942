import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import { useTranslation } from "react-i18next";
import PieChart from "../../components/PieChart";
import ProgressBar from "../../components/HorizontalProgressBar";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getUserInfo,
} from "../../utility";
import getLeaderBoardList, { getDashboardMatrics } from "../../api/dashobard";
import PaginationComponent from "../../components/Pagination";
import Loader from "../../components/Loader";
import VisitSummaryCard from "../../components/VisitSummaryCard";
import LeaderTable from "../../components/LeaderTable";

const Index = () => {
  const { t } = useTranslation();

  const [leaderData, setLeaderData] = useState<any>([]);
  const [dashobardData, setDashboardData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [dataLoader, setDataLoader] = useState<boolean>(false);

  const getLeaderBoardListDate = async (currentPage: any) => {
    const param = {
      page: currentPage,
    };
    const data = {
      start_date: formatCurrentStartDate(new Date()),
      end_date: formatCurrentEndDate(new Date()),
    };
    setLoader(true);
    await getLeaderBoardList(param, data)
      .then((res) => {
        setLeaderData(res.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  const getBoardMatrics = async () => {
    const param = {
      start_date: formatCurrentStartDate(new Date()),
      end_date: formatCurrentEndDate(new Date()),
    };
    setDataLoader(true);
    await getDashboardMatrics(param)
      .then((res) => {
        setDashboardData(res.data);
        setDataLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setDataLoader(false);
      });
  };

  const getPieChartData = (data: any) => {
    const formatedData = [
      {
        id: "Preseller",
        label: "Preseller",
        value: data?.total_preseller_count,
        color: "purple",
      },
      {
        id: "Salesman",
        label: "Salesman",
        value: data?.total_salesman_count,
        color: "cyan",
      },
    ];

    return formatedData;
  };
  const getIndex = (data: any, index: any) => {
    return Number(
      (Number(currentPage) - 1) * Number(data.size) + Number(index) + 1
    );
  };

  useEffect(() => {
    getBoardMatrics();
  }, []);

  useEffect(() => {
    getLeaderBoardListDate(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <DashboardPage>
      <div className=" flex w-full items-center text-13 p-1 text-[#FF4D68] justify-center bg-culightred">
        You have 12 pending request
      </div>
      <div className="px-6 flex flex-col gap-4 ">
        <div>
          <div className="flex justify-between pt-4 md:flex-row flex-col gap-2">
            <div className="max-w-[450px] w-full">
              <div className="text-36 font-bold">
                {t("Dashboard.greeting", { name: getUserInfo().name_eng })}
              </div>
              <p>{t("Dashboard.welcomeMessage")}</p>
            </div>

            <div>
              <div className="grid md:flex gap-4 w-full justify-end grid-cols-2 md:grid-cols-4">
                <VisitSummaryCard
                  title={t("Dashboard.totalVisits")}
                  value={dashobardData?.visits_summary?.total_visits}
                  backgroundColor="bg-[#F1D4FF80]"
                  textColor="text-purple"
                />
                <VisitSummaryCard
                  title={t("Dashboard.SuccessfulVisits")}
                  value={dashobardData?.visits_summary?.total_successful_visits}
                  backgroundColor="bg-[#E8FAF5]"
                  textColor="text-green"
                />
                <VisitSummaryCard
                  title={t("Dashboard.CollectionVisits")}
                  value={dashobardData?.visits_summary?.total_collection_visits}
                  backgroundColor="bg-[#FCF8EB]"
                  textColor="text-yellow"
                />
                <VisitSummaryCard
                  title={t("Dashboard.failedVisits")}
                  value={dashobardData?.visits_summary?.total_failed_visits}
                  backgroundColor="bg-[#FCEFF0]"
                  textColor="text-orange"
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" flex flex-col md:flex-col lg:flex-row gap-2 ">
          <div className="relaive flex flex-col gap-2 w-full md:w-full lg:w-[60%] ">
            {dataLoader && (
              <div className="absolute top-[50%] left-[50%] z-50">
                <Loader />
              </div>
            )}
            <div className="flex flex-col md:flex-row gap-2 ">
              <div className=" border-2 w-full  md:w-[40%] min-h-[309px] rounded">
                <div className="px-3 p-2 font-bold">Team</div>
                <PieChart data={getPieChartData(dashobardData?.team_summary)} />
              </div>
              <div className=" border-2 w-full md:w-[30%] min-h-[309px] p-2 rounded">
                <div className="px-3 p-2 font-bold">Preseller Status</div>
                <ProgressBar
                  active={dashobardData?.team_summary?.active_preseller_count}
                  inactive={
                    dashobardData?.team_summary?.inactive_preseller_count
                  }
                  onLeave={
                    dashobardData?.team_summary?.on_leave_preseller_count
                  }
                />
              </div>
              <div className=" border-2 w-full md:w-[30%] min-h-[309px] p-2 rounded">
                <div className="px-3 p-2 font-bold">Salesmen Status</div>
                <ProgressBar
                  active={dashobardData?.team_summary?.active_salesman_count}
                  inactive={
                    dashobardData?.team_summary?.inactive_salesman_count
                  }
                  onLeave={dashobardData?.team_summary?.on_leave_salesman_count}
                />
              </div>
            </div>
            <div className="flex  gap-2 flex-col md:flex-row ">
              <div className="flex flex-col gap-4 w-full md:w-[38%] ">
                <div className="flex gap-5 flex-col lg:flex-row">
                  <div className=" w-full bg-[#E8FAF5]">
                    <div className="pt-2 font-bold px-4 text-cugreen">
                      Total COD Orders
                    </div>
                    <div className="px-4 text-36 font-bold text-cugreen">
                      {dashobardData?.visits_summary?.total_cod_orders}
                    </div>
                  </div>
                  <div className=" w-full bg-[#E3EDFF]">
                    <div className="pt-2 font-bold px-4 text-[#408BFC]">
                      Average SKU / Invoice
                    </div>
                    <div className="px-4 text-36 font-bold text-[#408BFC]">
                      {dashobardData?.visits_summary?.average_sku_per_invoice}
                    </div>
                  </div>
                </div>
                <div className="w-full bg-[#E3EDFF80]">
                  <div className="pt-2 font-bold px-4 text-cugreen">
                    Total Collected Amount
                  </div>
                  <div className="px-4 text-36 font-bold text-cugreen">
                    {dashobardData?.visits_summary?.total_amount_collected} SAR
                  </div>
                </div>
              </div>
              <div className=" border-2 w-full md:w-[30%] min-h-[268px] p-2 rounded">
                <div className="px-3 p-2 font-bold">Total SAR Sales</div>
                {/* <VerticalProgressBar
                  firstAmount={
                    dashobardData?.visits_summary?.preseller_sale_value
                  }
                  secondAmount={
                    dashobardData?.visits_summary?.salesman_sale_value
                  }
                /> */}
              </div>
              <div className=" border-2 w-full  md:w-[30%] min-h-[268px] p-2 rounded">
                <div className="px-3 p-2 font-bold">Total Carton Sales</div>
                {/* <VerticalProgressBar
                  firstAmount={
                    dashobardData?.visits_summary?.preseller_cartons_sold
                  }
                  secondAmount={
                    dashobardData?.visits_summary?.salesman_cartons_sold
                  }
                /> */}
              </div>
            </div>
          </div>
          <div className="md:w-full lg:w-[40%] w-full rounded">
            <div className="p-5 border-2 rounded">
              <h2 className="text-lg font-semibold p-4 text-white bg-purple ">
                Leaderboard
              </h2>
              <div className="overflow-x-auto h-full lg:h-[550px] overflow-auto">
                {/* <LeaderTable
                  leaderData={leaderData}
                  loader={loader}
                  getIndex={getIndex},
                /> */}
              </div>
            </div>
            <PaginationComponent
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalCount={leaderData?.total}
              itemsPerPage={leaderData?.size}
            />
          </div>
        </div>
      </div>
    </DashboardPage>
  );
};

export default Index;
