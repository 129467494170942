import React, { useState, useEffect, useRef } from "react";
import DashboardPage from "../../layout/index";
import SalesmanList from "./Salesman-list";
import SalesmanOption from "./SalesmanOption";
import GoogleMap from "../../components/GoogleMapUpdated";
import getEmployeeList, {
  getEmployeeSchedule,
  getMarkerInfo,
  getStream,
  getLastLocation,
  getSnappedHistory,
  getDistanceTravelled,
  getDeviation,
  getIdleTime
} from "../../api/mapView";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getUserInfo,
} from "../../utility";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { getGlobalFilter } from "../../api/filters";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownMulti from "../../components/DropdownMultiple";
import Search from '../../assets/search-icon.svg'
import { motion } from "framer-motion";
import { RotateCw } from "lucide-react";

interface Employee {
  emp_id: string;
  name: string;
  age: number;
  department: string;
}

export default function MapView() {
  const [selctedEmp, setSelectedEmp] = useState<any>("All employees");
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [employeeSchedule, setEmployeeSchedule] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<any | null>();
  const [endDate, setEndDate] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [locationData, setLocationData] = useState<any | null>([]);
  const [markarData, setMarkarData] = useState<any | null>(null);
  const [selectedEmpPopup, setSelectedEmpPopup] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("All employees");
  const [actualData, setActualdata] = useState<any>([]);
  const [lastLoader, setLastLoader] = useState<any>(false);
  const [empLoader, setEmpLoader] = useState<any>(false);
  const [actualDataLoader, setActualdataLoader] = useState<any>(false);
  const [routeForSelectedCustomer, setRouteForSelectedCustomer] =
    useState<any>("");
  const [vehicleLocation, setVehicleLoaction] = useState<any>();
  const [locationPoint, setLocationPoint] = useState<any>(false);
  const [startPoint, setStartPoint] = useState<any>([
    { lat: 24.774265, lng: 46.738586 },
  ]);
  const [snappedHistory, setSnappedHistory] = useState<any>();
  const [vehicleAnimation, setMoveVehicleAnimation] = useState<any>(false);
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenPlan, setIsOpenPlan] = useState(true);
  const [minMax, setMinMax] = useState(true);
  const [reSize, setResize] = useState(true);
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [loadingFilter, setLoadingForFilter] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<any>([]);
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>([]);
  const [selectedFromReports, setSelectedFromReports] = useState(false);
  const [idletime, setIdletime] = useState<any>(null);
  const [distanceTravelled, setDistance] = useState<any>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const toggleCollapsePlan = () => {
    setIsOpenPlan(!isOpenPlan);
  };

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleRouteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedRoute === e.target.value) {
      setSelectedRoute("All employees");
    } else {
      if (e.target.value === "actual") {
        setActualdata([]);
        setVehicleLoaction("");
        const data = {
          requested_emp_ids: [selctedEmp.emp_id],
          start_time: formatCurrentStartDate(new Date(selectedDate)),
          end_time: formatCurrentEndDate(new Date(selectedDate)),
        };
        setActualdataLoader(true);
        getStreamDataHistory(data);
      } else if (e.target.value === 'compare') {
        getDataForCompare()
      }
      setSelectedRoute(e.target.value);
      setLocationPoint(true);
      setTimeout(() => {
        setLocationPoint(false);
      }, 5000);
    }
  };

  const getActualPathCall = (person: any) => {
    setActualdata([]);
    setVehicleLoaction("");
    setActualdataLoader(true);
    const data = {
      requested_emp_ids: [person.emp_id],
      start_time: formatCurrentStartDate(new Date(selectedDateEmpRef.current)),
      end_time: formatCurrentEndDate(new Date(selectedDateEmpRef.current)),
    };
    getStreamDataHistory(data);

    setLocationPoint(true);
    setTimeout(() => {
      setLocationPoint(false);
    }, 5000);
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    const today = new Date();
    setDateRange(today);
    getEmployee();

    // const data: any = {};

    // if (selectedRegion.length > 0) {
    //   data["regions"] = selectedRegion.toString();
    // }
    // if (selectedSalesOffice.length > 0) {
    //   data["sales_office_ids"] = selectedSalesOffice.toString();
    // }
    // data["endpoint"] = "map-view";
    // data["start_date"] = formatCurrentStartDate(new Date());
    // data["end_date"] = formatCurrentEndDate(new Date());

    // getGlobalFilters(data);
  }, []);

  const getStreamData = async (emp_data: any[]) => {
    const empIds = emp_data?.map((emp: any) => emp.emp_id);
    const user_info = getUserInfo();
    const data = {
      emp_id: user_info.emp_id,
      requested_emp_ids: empIds,
    };
    await getStream(data)
      .then((res) => {
        getEventSource(res.data.stream_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let lastApiCallTime = 0;

  function handleUpdateLocation() {
    const currentTime = Date.now();
    const oneMinute = 10 * 1000;

    if (currentTime - lastApiCallTime >= oneMinute) {
      lastApiCallTime = currentTime;
      const data = {
        requested_emp_ids: [selectedEmpRef.current.emp_id],
        start_time: formatCurrentStartDate(
          new Date(selectedDateEmpRef.current)
        ),
        end_time: formatCurrentEndDate(new Date(selectedDateEmpRef.current)),
      };
      getStreamDataHistory(data);
    } else {
      // const timeLeft = oneMinute - (currentTime - lastApiCallTime);
      // console.log(
      //   `Skipping API call. Please wait ${Math.ceil(timeLeft / 1000)} seconds.`
      // );
    }
  }

  const updateVehicleLastLocation = (data: any) => {
    if (formatDate(new Date()) === selectedDateEmpRef.current) {
      handleUpdateLocation();
      setVehicleLoaction({
        lat: Number(data.latitude),
        lng: Number(data.longitude),
      });
    }
  };

  const selectedEmpRef = useRef(selctedEmp);
  const selectedDateEmpRef = useRef(selectedDate);
  const locationDataRef = useRef(locationData);
  const selectedRouteRef = useRef(selectedRoute);

  useEffect(() => {
    selectedEmpRef.current = selctedEmp;
    selectedDateEmpRef.current = selectedDate;
    fetchIdleTime(selctedEmp,selectedDate)
  }, [selctedEmp, selectedDate]);

  useEffect(() => {
    locationDataRef.current = locationData;
  }, [locationData]);

  useEffect(() => {
    selectedRouteRef.current = selectedRoute;
  }, [selectedRoute]);

  const handleStreamResponse = (data: any) => {
    if (data?.init) {
      return;
    }
    const existingMarkerIndex = locationDataRef.current.findIndex(
      (marker: any) => marker.emp_id === data.emp_id
    );

    if (selectedEmpRef.current.emp_id === data.emp_id) {
      // check user is current and status is not idle
      if (selectedRouteRef.current === "actual") {
        return updateVehicleLastLocation(data);
      } else {
        return;
      }
    }
    if (selectedRoute === "All employees") {
      if (existingMarkerIndex !== -1) {
        // Update existing marker
        const updatedMarkers = [...locationDataRef.current];
        updatedMarkers[existingMarkerIndex] = {
          ...updatedMarkers[existingMarkerIndex],
          location: {
            lat: Number(data.latitude), // Update latitude
            lng: Number(data.longitude), // Update longitude
          },
          status:
            data.ischecked_in === "True"
              ? "at_location"
              : data.is_idle === "True"
                ? "idle"
                : "enroute",
        };
        setLocationData(updatedMarkers);
      } else {
        // Add new marker
        if (data.emp_id) {
          setLocationData([
            ...locationDataRef.current,
            {
              location: {
                lat: Number(data.latitude),
                lng: Number(data.longitude),
              },
              emp_id: data.emp_id,
              status:
                data.ischecked_in === "True"
                  ? "at_location"
                  : data.is_idle === "True"
                    ? "idle"
                    : "enroute",
            },
          ]);
        }
      }
    }
  };

  let eventSource: EventSource | null = null;

  const getEventSource = (streamId: number) => {
    const token = Cookies.get("token");

    const env = process.env.REACT_APP_ENV;
    let baseUrlGps = null;

    if (env === "development") {
      baseUrlGps = process.env.REACT_APP_DEV_GPS_API_URL;
    } else if (env === "staging") {
      baseUrlGps = process.env.REACT_APP_STAGING_GPS_API_URL;
    } else if (env === "production") {
      baseUrlGps = process.env.REACT_APP_PROD_GPS_API_URL;
    } else {
      console.error("No valid environment specified");
    }
    eventSource?.close();
    eventSource = new EventSource(
      `${baseUrlGps}/gps/stream/${streamId}?token=${token}`
    );
    eventSource.onmessage = (event: any) => {
      handleStreamResponse(JSON.parse(event.data));
    };

    eventSource.onerror = (err) => {
      console.error("EventSource error:", err);
      eventSource?.close();
    };
  };

  const stopEventSource = () => {
    if (eventSource) {
      eventSource.close();
      eventSource = null; // Reset the reference
    }
  };

  React.useEffect(() => {
    return () => {
      stopEventSource();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatSnappedHistory = (data: any) => {
    const polyline = data?.polylines[selectedEmpRef.current.emp_id]?.features; //todo make id dyanamic
    setSnappedHistory(polyline);
    setVehicleLoaction(null);
  };

  const getStreamDataHistory = async (data: any) => {
    await getSnappedHistory(data)
      .then((res) => {
        formatSnappedHistory(res.data);
        setMoveVehicleAnimation(true);
        setActualdataLoader(false);
        setTimeout(() => {
          setMoveVehicleAnimation(false);
        }, 3000);
      })
      .catch((error) => {
        setActualdataLoader(false);
        console.log(error);
      });
  };
  const fetchIdleTime = async (emp_id:any,data:any) => {
    try {
      const response = await getIdleTime(emp_id,data);
      setIdletime(response.data);
    } catch (error) {
      console.error("Error fetching idle time:", error);
    }
  };
  const fetchDistanceTravelled = async (emp_id:any,data:any) => {
    try {
      const response = await getDistanceTravelled(emp_id,data);
      setDistance(response.data);
    } catch (error) {
      console.error("Error fetching idle time:", error);
    }
  };
  
  const hanldeLocationData = (data: any) => {
    const convertedData = data.map((item: any) => ({
      ...item,
      location: {
        lat: parseFloat(item.location.lat),
        lng: parseFloat(item.location.lng),
      },
      status: "enroute",
    }));
    if (convertedData) {
      return convertedData;
    } else {
      return;
    }
  };
  const getLastLocationData = async (data: any) => {
    setLastLoader(true);
    const empIds = data.map((employee: any) => employee.emp_id);
    await getLastLocation(empIds)
      .then((res) => {
        setLocationData(hanldeLocationData(res?.data?.locations));
        setLastLoader(false);
      })
      .catch((error) => {
        setLastLoader(false);
        console.log(error);
        setLocationData([]);
      });
  };

  const setDateRange = (date: any) => {
    const formattedToday = formatDate(date);
    setSelectedDate(formattedToday);

    setStartDate(formatCurrentStartDate(date));
    setEndDate(formatCurrentEndDate(date));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);
    setRouteForSelectedCustomer("");
    if (!isNaN(selectedDate.getTime())) {
      setStartDate(formatCurrentStartDate(selectedDate));
      setEndDate(formatCurrentEndDate(selectedDate));

      const formattedToday = formatDate(selectedDate);
      setSelectedDate(formattedToday);

      getEmpSchedule(selctedEmp, {
        start_date: formatCurrentStartDate(selectedDate),
        end_date: formatCurrentEndDate(selectedDate),
      });
      const idlparams= {
        date:formattedToday
      };
      const paramsD = {
        start_time: formatCurrentStartDate(new Date(selectedDate)),
        end_time: formatCurrentEndDate(new Date(selectedDate)),
      };
      fetchIdleTime(selctedEmp.emp_id,idlparams)
      fetchDistanceTravelled(selctedEmp.emp_id,paramsD)
    }
  };

  const location = useLocation();
  const state = location.state || {};


  useEffect(() => {
    const { id, selectDate } = state;
    if (location.state) {
      const { id, selectDate } = location.state;
      setSelectedEmp({ emp_id: id });
      setSelectedDate(selectDate);
      setIsDropdownOpen(false);
      setLocationPoint(true);
      setVehicleLoaction(null);

      setTimeout(() => {
        setLocationPoint(false);
      }, 5000);

      const params = {
        start_date: formatCurrentStartDate(new Date(selectDate || new Date())),
        end_date: formatCurrentEndDate(new Date(selectDate || new Date())),
      };

      getEmpSchedule({ emp_id: id }, params);
      setStartDate(formatCurrentStartDate(new Date(selectDate)))
      setEndDate(formatCurrentEndDate(new Date(selectDate)))
      setSelectedFromReports(true);
      navigate(".", { replace: true });
    }
  }, [location.state, navigate])


  const handleEmpSected = (person: any,called:boolean) => {
    setRouteForSelectedCustomer("");
    if (selctedEmp.emp_id === person.emp_id && called) {
      setSelectedEmp("All employees");
      setIsDropdownOpen(false);
      return;
    } else {
      setSelectedEmp(person);
      setIsDropdownOpen(false);
      setLocationPoint(true);
      setVehicleLoaction(null);
      setTimeout(() => {
        setLocationPoint(false);
      }, 5000);
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      const idlparams={
        date:startDate
      }
      const paramsD = {
        start_time: formatCurrentStartDate(new Date(selectedDate)),
        end_time: formatCurrentEndDate(new Date(selectedDate)),
      };
      getEmpSchedule(person, params);
      setSelectedFromReports(false);
      fetchIdleTime(person.emp_id,idlparams);
      fetchDistanceTravelled(person.emp_id,paramsD)
    }
  };

  const getEmpSchedule = async (person: any, params: any) => {

    setLoader(true);
    setActualdataLoader(true);
    // setSelectedRoute("planned");
    setSelectedRoute("actual");
    const sub_id = person.emp_id;
    await getEmployeeSchedule(sub_id, params)
      .then((res) => {
        setEmployeeSchedule(res.data);
        setStartPoint({
          lat: Number(res.data.planned_route_details.origin.lat),
          lng: Number(Number(res.data.planned_route_details.origin.lng)),
        });
        setLoader(false);
        getActualPathCall(person);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });

  };

  const getMarkerInfoData = async (emp_id: any) => {
    const params = {
      start_date: formatCurrentStartDate(new Date(selectedDate)),
      end_date: formatCurrentEndDate(new Date(selectedDate)),
    };
    const paramsD = {
      start_time: formatCurrentStartDate(new Date(selectedDate)),
      end_time: formatCurrentEndDate(new Date(selectedDate)),
    };
    Promise.all([
      getDistanceTravelled(emp_id, paramsD),
      getMarkerInfo(emp_id, params),
    ])
      .then(([
        distanceRes,
         markerRes]) => {
        setMarkarData({
          ...markerRes.data,
          distance: distanceRes.data || 0, 
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getEmployee = async () => {
    // const data: any = {};
    // if (selectedRegion.length > 0) {
    //   data["regions"] = selectedRegion;
    //   // data["regions"] = `[${selectedRegion.toString()}]`;
    // }
    // if (selectedSalesOffice.length > 0) {
    //   data["sales_office_ids"] = selectedSalesOffice;
    //   // data["sales_office_ids"] = `[${selectedSalesOffice.toString()}]`;
    // }

    const data: any = new URLSearchParams();

    if (selectedRegion.length > 0) {
      selectedRegion.forEach((region: string) => data.append("regions", region));
    }

    if (selectedSalesOffice.length > 0) {
      selectedSalesOffice.forEach((office: string) => data.append("sales_office_ids", office));
    }

    const queryString = data.toString();
    setEmpLoader(true)
    await getEmployeeList(queryString)
      .then((res) => {

        if (location?.state) {
          setEmployeeData(res.data?.filter((emp: any) => emp.emp_id === location.state.id));
        } else {
          setEmployeeData(res.data);
        }
        getLastLocationData(res.data);
        setEmpLoader(false)
        getStreamData(res.data);
      })
      .catch((error) => {
        setEmpLoader(false)
        console.log(error);
      });
  };
  const [activePopupIndex, setActivePopupIndex] = useState<number | null>(null);

  const handleSelectedEmpIcon = () => {
    setSelectedEmpPopup(!selectedEmpPopup);
    if (!selectedEmpPopup) {
      getMarkerInfoData(selctedEmp.emp_id);
    }
  };

  const infoIconClick = (index: number, id: any) => {
    setActivePopupIndex(activePopupIndex === index ? null : index);
    if (activePopupIndex !== index) {
      getMarkerInfoData(id);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setActivePopupIndex(null);
      }else{
        setSelectedEmpPopup(false)
      }
    };

    if (activePopupIndex !== null || selectedEmpPopup === true) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activePopupIndex]);

  const handleMarkerClick = (marker: any) => {
    setMarkarData(null);
    // setSelectedMarker(marker);
    // getMarkerInfoData(marker?.emp_id);
    setSelectedEmp(marker);
    setIsDropdownOpen(false);
    setLocationPoint(true);
    setVehicleLoaction(null);
    setTimeout(() => {
      setLocationPoint(false);
    }, 5000);
    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    setEmployeeData(
      employeeData?.sort((a, b) =>
        a.emp_id === marker.emp_id ? -1 : b.emp_id === marker.emp_id ? 1 : 0
      )
    );
    getEmpSchedule(marker, params);
  };

  const handleMinMax = (flag: any) => {
    setIsOpen(flag);
    setIsOpenPlan(flag);
    setMinMax(flag);
  };

  const handleMobileMap = () => {
    setResize(!reSize);
  };

  const getGlobalFilters = async (data: any) => {
    if (loadingFilter) {
      return
    }
    try {
      setLoadingForFilter(true);
      const response = await getGlobalFilter(data);
      setLoadingForFilter(false);
      setGlobalFilters(response);
    } catch (error) {
      console.error("Error fetching global filters:", error);
    }
  };

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    data["endpoint"] = "map-view";
    data["start_date"] = formatCurrentStartDate(new Date());
    data["end_date"] = formatCurrentEndDate(new Date());
    getGlobalFilters(data);
    // getEmployee();
  };

  useEffect(() => {
    handleFiltersUpdate()
  }, [selectedSalesOffice, selectedRegion])

  useEffect(() => {
    setSelectedSalesOffice([]);
  }, [selectedRegion]);


  const getDataForCompare = () => {
    if(!snappedHistory){
      return
    }
    const data = {
      "actual_geojson": snappedHistory[0].geometry ,
      "planned_polyline": employeeSchedule?.planned_route_details?.directions?.routes[0]?.polyline?.encodedPolyline
    }
    getDeviationData(data)
  }

  const [deviationData, setDeviationData] = useState()

  const getDeviationData = async (data: any) => {

    await getDeviation(data).then((res) => {
      setDeviationData(res?.data?.features)
    }).catch((error) => {
      console.log(error)
    })
  }


  const handleRefresh =()=>{
    handleEmpSected(selctedEmp ,false)
  }

  return (
    <DashboardPage>
      <div className="relative h-[calc(100vh-54px)] flex-grow md:flex overflow-y-auto scrollbar scrollbar-thin">
        <div
          className={` overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? "w-full md:w-[25%]  md:h-full" : "w-0"
            }`}
        >
          <div className="flex gap-2 sticky top-0 z-10 w-full px-4 y-2 md:flex-wrap xl:flex-nowrap">
            <DropdownMulti
              options={globalFilters?.regions.map((sales: any) => ({
                value: sales,
                label: sales,
              }))}
              selectedValues={selectedRegion}
              // onClose={handleFiltersUpdate}
              onChange={setSelectedRegion}
              placeholder={"Select Region"}
            // isSingleSelect={true}
            />
            <DropdownMulti
              options={
                globalFilters?.sales_offices.map((sales: any) => ({
                  value: sales.id,
                  label: sales.name,
                })) || []
              }
              selectedValues={selectedSalesOffice}
              onChange={setSelectedSalesOffice}
              // onClose={handleFiltersUpdate}
              disabled={selectedRegion.length === 0}
              placeholder={"Select Sales Office"}
            // isSingleSelect={true}

            />
            <div className=" flex items-center justify-center">
              <button onClick={getEmployee} className="min-w-[32px] p-2 bg-purple hover:bg-[#D099D4] focus:ring-2 focus:ring-blue-500 rounded-full transition">
                <img src={Search} alt='Apply' />
              </button>
            </div>
          </div>
          <SalesmanList
            selctedEmp={selctedEmp}
            handleEmpSected={handleEmpSected}
            employeeData={employeeData}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            selectedEmpPopup={selectedEmpPopup}
            handleSelectedEmpIcon={handleSelectedEmpIcon}
            infoIconClick={infoIconClick}
            popupref={popupRef}
            activePopupIndex={activePopupIndex}
            markarData={markarData}
            employeeSchedule={employeeSchedule}
            selectedFromReports={selectedFromReports}
            empLoader={empLoader}
            selectedMarker={selectedMarker}
          />
        </div>

        {selctedEmp !== "All employees" ? (
          <>
            <div
              className={`border-none md:border-solid  md:border-l-2 md:border-r-2 w-full  p-4 px-6 min-h-[400px] 
                ${isOpen
                  ? "md:w-[50%]"
                  : isOpenPlan
                    ? "md:w-[75%]"
                    : "md:w-full"
                } 
               ${isOpenPlan ? "md:w-[50%]" : isOpen ? "md:w-[75%]" : "md:w-full"
                }  
               ${reSize ? "h-[100%]" : "h-[400px]"}
              `}
            >
              <div className="hidden md:block">
                <button
                  onClick={toggleCollapse}
                  style={!isOpen ? { left: "1%" } : {}}
                  className={`absolute  ${i18n.dir() === "rtl" ? 'right-[23.8%]' : 'left-[23.8%]'}  top-[50%] z-20 px-2 py-6 bg-[#D099D4]  to-purple-500 text-white text-sm font-semibold  transform transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none flex items-center space-x-2`}
                >
                  <span>{isOpen ? "<" : ">"}</span>
                </button>
              </div>
              <GoogleMap
                showDirections={true}
                plannedData={
                  employeeSchedule?.planned_route_details?.directions?.routes
                }
                actualData={actualData}
                snappedHistory={snappedHistory}
                vehicleAnimation={vehicleAnimation}
                actualDataLoader={actualDataLoader}
                lastLoader={lastLoader}
                startPoint={startPoint}
                selectedRoute={selectedRoute}
                handleRouteChange={handleRouteChange}
                markarData={markarData}
                employeeSchedule={employeeSchedule}
                routeForSelectedCustomer={routeForSelectedCustomer}
                vehicleLocation={vehicleLocation}
                locationPoint={locationPoint}
                selectedDate={selectedDate}
                handleMinMax={handleMinMax}
                minMax={minMax}
                deviationData={deviationData}
              />
              <div className="block md:hidden">
                <button
                  onClick={handleMobileMap}
                  className="absolute rotate-90 right-[50%] z-20 px-2 py-6 bg-[#D099D4]  to-purple-500 text-white text-sm font-semibold  transform transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none flex items-center space-x-2"
                >
                  <span>{!reSize ? ">" : "<"}</span>
                </button>
              </div>
            </div>
            <div
              className={` overflow-hidden transition-all duration-500 ease-in-out ${isOpenPlan ? "w-full md:w-[30%]  md:h-full" : "w-0"
                }`}
            >
              <div>
                <button
                  onClick={toggleCollapsePlan}
                  style={!isOpenPlan ? { right: "1%" } : {}}
                  className={`hidden md:block absolute  ${i18n.dir() === "rtl" ? 'left-[28.6%]' : 'right-[28.6%]'}  top-[50%] z-20 px-2 py-6 bg-[#D099D4]  to-purple-500 text-white text-sm font-semibold transform transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none flex items-center space-x-2`}
                >
                  <span>{isOpenPlan ? ">" : "<"}</span>
                </button>
              </div>
              <div>
                <SalesmanOption
                  employeeSchedule={employeeSchedule}
                  handleDateChange={handleDateChange}
                  selectedDate={selectedDate}
                  loader={loader}
                  setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                  routeForSelectedCustomer={routeForSelectedCustomer}
                  idleTime={idletime}
                  distanceTravelled={distanceTravelled}
                  handleRefresh={handleRefresh}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`border-l-2 min-h-[420px]   md:h-full p-4 px-6 h-[320px] ${isOpen ? "w-full md:w-[75%]" : "w-full md:w-full"
                }  ${reSize ? "h-[calc(100vh-270px)]" : "h-[420px]"}`}
            >
              <div className="hidden md:block">
                <button
                  onClick={toggleCollapse}
                  style={!isOpen ? (i18n.dir() === "rtl" ? { right: "1%" } : { left: "1%" }) : {}}
                  className={`absolute ${i18n.dir() === "rtl" ? "right-[25%]" : "left-[25%]"} top-[50%] z-20 px-2 py-6 
                  bg-[#D099D4] to-purple-500 text-white text-sm font-semibold 
                  transform transition-all duration-300 ease-in-out hover:scale-105 
                  focus:outline-none flex items-center space-x-2`}
                >
                  <span>{isOpen ? "<" : ">"}</span>
                </button>
              </div>
              <GoogleMap
                showMarkers={true}
                markersData={locationData}
                setSelectedMarker={setSelectedMarker}
                selectedMarker={selectedMarker}
                handleMarkerClick={handleMarkerClick}
                employeeSchedule={employeeSchedule}
                routeForSelectedCustomer={routeForSelectedCustomer}
                markarData={markarData}
                handleCloseInfoWindow={() => setSelectedMarker(null)}
                handleMinMax={handleMinMax}
                minMax={minMax}
                actualDataLoader={actualDataLoader}
                lastLoader={lastLoader}
                deviationData={deviationData}
              />
              <div className="flex items-center justify-center md:hidden ">
                <button
                  onClick={handleMobileMap}
                  className={`"absolute rotate-90 right-[50%]  z-20 px-2 py-6 bg-purple  to-purple-500 text-white text-sm font-semibold  transform transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none flex items-center space-x-2`}
                >
                  <span>{!reSize ? ">" : "<"}</span>
                </button>
              </div>
              {selectedMarker && (
                <div className="md:hidden w-full bg-culightgrey h-auto p-6 mt-4 border border-gray-500 rounded">
                  <div className="text-16 font-bold">
                    {selectedMarker?.label}
                  </div>
                  <div className="pt-2">
                  <p>
                      {t("visit.total_travel")} :
                      {markarData?.distance.distance_metres} {t("visit.km")}
                    </p>
                    <p>
                      {t("visit.total_visit")} : {markarData?.total_visits}
                    </p>
                    <p>
                      {t("visit.successful_visit")} :{" "}
                      {markarData?.successful_visits}
                    </p>
                    <p>
                      {t("visit.failed_visit")}: {markarData?.failed_visits}{" "}
                    </p>
                    <p>
                      {t("visit.collection_visit")}:{" "}
                      {markarData?.productive_visits}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </DashboardPage>
  );
}
