import React, { useEffect, useState } from "react";
import { TableData } from "../Finance";
import logo from "./main-logo.png";
import nwc from "./nwc.jpg";
import logo2 from "../../../assets/main-logo.png";


import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";

export type TableColumn = {
  header: string;
  key: string;
  formatter?: (value: any) => string;
};

type DynamicTableProps = {
  columns: TableColumn[];
  tableData: TableData | null;
  metadata?: {
    date?: string;
    logoSrc?: string;
    title?: string;
    logoSrcnwc?: string;
  };
  month: string | null;
  year: Date | null;
};

const ComponentToPrintFinance: React.FC<DynamicTableProps> = ({
  columns,
  tableData,
  metadata,
  month,
  year,
}) => {
  console.log(year?.getFullYear(), " <<<< DataSummesion");

  const {t} = useTranslation();
  const [isArabic, setIsArabic] = useState(false);

  function convertToMonth(monthString: string | null): string {
    if (!monthString) {
      return "Invalid month";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = parseInt(monthString, 10) - 1;
    if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }

  }
  useEffect(() => {
    const lang = currentLang();
    if(lang === "Arabic") setIsArabic(true);
    else setIsArabic(false);
  }, []);
  return (
    <div id="print-content" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {metadata?.date || "-"}</p>

            <div className="flex item center space-x-2">
              <img src={logo} alt="Logo" className="h-16" />
              <img src={nwc} alt="NWC Logo" className="h-16 self-start" />
              {/* <img src={logo2} alt="NWC Lodsdsgo" className="h-4" /> */}
            </div>

    
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {t(`finance.${metadata?.title}`)}
          </h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>{t("finance.month")} : </b>
            {convertToMonth(month)}
          </p>
          <p className="text-sm">
            <b>{t("finance.year")}: </b>
            {year?.getFullYear()}
          </p>
        </div>
      </div>
      <div className="text-left mb-6">
        <p>
          <b>{t("finance.payerID")} :</b> {tableData?.payer_id || "N/A"}
        </p>
        <p>
          {" "}
          <b>{t("finance.payerName")} :</b> {tableData?.payer_name || "N/A"}
        </p>
        <p>
          {" "}
          <b>{t("finance.vat")} :</b> {tableData?.vat || "N/A"}
        </p>
        <p>
          {" "}
          <b>{t("finance.cr")} :</b> {tableData?.cr || "N/A"}
        </p>
      </div>

      <table className="w-full mb-6 border-collapse border border-gray-300">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                className={`border border-gray-300 p-2 bg-gray-100 text-left ${ isArabic ? "text-right" : "text-left"}`}
              >
                {t(`finance.${col.key}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData && tableData.transactions?.length > 0 ? (
            tableData.transactions.map((items, index) => (
              <tr key={index} className="text-xs hover:bg-gray-50">
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.transaction_type || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.transaction_date || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.transaction_time || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.document_no || "N/A"}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.credit || 0}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.debit || 0}
                </td>
                <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                  {items.carry_forward_balance || 0}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="text-center py-4 text-xs">
                No results found
              </td>
            </tr>
          )}
        </tbody>
        <thead className="text-gray-100">
          <tr>
            <th className={`px-4 py-3 text-gray-800 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}>
              {t("finance.total")}
            </th>
            <th className={`px-4 py-3 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}></th>
            <th className={`px-4 py-3 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}></th>
            <th className={`px-4 py-3 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}></th>
            <th className={`px-4 py-3 text-gray-800 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}>
              {tableData?.total_credit || "N/A"}
            </th>
            <th className={`px-4 py-3  text-gray-800 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}>
              {tableData?.total_debit || "N/A"}
            </th>
            {/* <th className={`px-4 py-3 border text-left text-xs font-bold ${ isArabic ? "text-right" : "text-left"}`}>
              {tableData?.total_carry_forward_balance || "N/A"}
            </th> */}
          </tr>
        </thead>
      </table>

      <div className="flex flex-col items-end font-bold space-y-2">
        <p>{t("finance.openingBalance")} : {tableData?.opening_balance || "N/A"}</p>
        <p>{t("finance.closingBalance")} : {tableData?.closing_balance || "N/A"}</p>
      </div>

      <div className="flex justify-between pt-24">
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          {/* <p>Accountant's Signature</p> */}
        </div>
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          {/* <p>Salesman's Signature</p> */}
        </div>
      </div>
    </div>
  );
};

export default ComponentToPrintFinance;
