import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";

interface ApiResponse {
  data: any;

}


const getDashboardKpiData = async (data: any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.post<ApiResponse>(
    `/deliveries/${user_info.emp_id}/dashboard`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};

const getLeaderBoardList = async (param: any, data: any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.post<ApiResponse>(
    `/deliveries/${user_info.emp_id}/leader`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};

const getDeliveryReport = async (param: any, data: any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.post<ApiResponse>(
    `/deliveries/${user_info.emp_id}/delivery-report`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};

const leaderboardData = async (param: any, data: any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.post<ApiResponse>(
    `/deliveries/${user_info.emp_id}/leaderboard`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};

const getrejectedOrderReport = async (params: any, data: any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.post<ApiResponse>(
    `/deliveries/${user_info.emp_id}/rejected-order-report`,
    data,
    {
      headers: getHeader(),
      params,
    }
  );
  return response;
};

const cancelDelivery = async (deliveryNumber: string, data: any): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>(
    `/deliveries/${deliveryNumber}/cancel`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};

const addDeliveryNote = async (deliveryNumber: string, note: string, data: any): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>(
    `/deliveries/${deliveryNumber}/add-note`,
    data,
    {
      headers: getHeader(),
      params: { note },
    }
  );
  return response;
};

const getDeliveryNotes = async (deliveryNumber: string): Promise<ApiResponse> => {
  const response = await axios.get<ApiResponse>(
    `/deliveries/${deliveryNumber}/notes`,
    {
      headers: getHeader(),
    }
  );
  return response;
};


const reassignDelivery = async (deliveryNumber: string, driver_id: string): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>(
    `/deliveries/${deliveryNumber}/reassign-to-driver`,
    {},
    {
      headers: getHeader(),
      params: { driver_id },
    }
  );
  return response;
};

export default getDashboardKpiData;
export {
  getLeaderBoardList,
  getDeliveryReport,
  leaderboardData,
  getrejectedOrderReport,
  cancelDelivery,
  addDeliveryNote,
  getDeliveryNotes,
  reassignDelivery, 
};
