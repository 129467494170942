import React from "react";
import { TableData } from "../Finance";
import { useTranslation } from "react-i18next";

interface AccountBalanceProps {
  tableData: TableData | null;
}

const AccountBalance: React.FC<AccountBalanceProps> = ({ tableData }) => {
  const { t } = useTranslation()
  return (
    <div className="mt-5">
      <div className="mb-5 bg-blue-50 rounded-md shadow-md lg:p-8 sm:p-4 p-2 flex flex-wrap lg:flex-nowrap items-center text-sm text-gray-800 space-y-4 lg:space-y-0 lg:space-x-10 divide-x divide-gray-300">
        <div className="flex flex-col px-4 lg:px-8">
          <span className="text-[11px] text-gray-900">{t("finance.payerID")}</span>
          <span className="font-semibold">{tableData?.payer_id}</span>
        </div>
        <div className="flex flex-col px-4 lg:px-8">
          <span className="text-[11px] text-gray-900">{t("finance.payerName")}</span>
          <span className="font-semibold">{tableData?.payer_name}</span>
        </div>
        <div className="flex flex-col px-4 lg:px-8">
          <span className="text-[11px] text-gray-900">{t("finance.vat")}</span>
          <span className="font-semibold">{tableData?.vat}</span>
        </div>
        <div className="flex flex-col px-4 lg:px-8">
          <span className="text-[11px] text-gray-900">{t("finance.cr")}</span>
          <span className="font-semibold">{tableData?.cr}</span>
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-6 py-2">
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-800">{t("finance.openingBalance")}</span>
          <div className="px-4 py-2 bg-blue-200 text-gray-700 font-semibold rounded-md">
            {tableData?.opening_balance}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-800">{t("finance.closingBalance")}</span>
          <div className="px-4 py-2 bg-green-200 text-gray-700 font-semibold rounded-md">
            {tableData?.closing_balance}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBalance;
