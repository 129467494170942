import React, { useEffect, useState, useTransition } from "react";
import print from "../assets/printer.svg";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";

interface Item {
  item_code: string | null;
  product_id: string;
  quantity: number;
}

interface Shipment {
  shipment_id: number;
  status: string;
  shimpment_date: string;
}

interface Invoice {
  invoice_id: number;
  invoice_details: string | null;
  invoice_path: string;
  invoice_date: string;
}

export interface ModalOrderData {
  sfa_order_id: string;
  status: string;
  customer_id: string;
  address_id: string;
  items: Item[];
  shipments: Shipment[];
  invoices: Invoice[];
}

interface ModalProps {
  handleHideModal: () => void;
  data: ModalOrderData | undefined;
  printData: () => void;
}

const Modal: React.FC<ModalProps> = ({ handleHideModal, data, printData }) => {
   const { t } = useTranslation();
   const [lang, setLang] = useState<string | null>(null);

   useEffect(()=>{
    const lang = currentLang();
    setLang(lang);
   },[])
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl relative flex flex-col shadow-lg">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-lg font-semibold">{t("orders.orderDetails")}</h2>
          <div className="flex space-x-3 mr-4">
            <button
              onClick={handleHideModal}
              className="text-gray-500 hover:text-gray-700 text-xl absolute top-1 right-3"
            >
              &times;
            </button>
            <button
              onClick={printData}
              className="bg-[#1dbda1] text-white rounded-md px-4 py-2 text-sm hover:bg-[#17a488] focus:outline-none flex items-center"
            >
              <img className="mr-2" src={print} alt="Print" /> {t("orders.print")}
            </button>
          </div>
        </div>

        <div className="mt-4 text-sm flex justify-between">
          <div className="flex flex-col">
            <p>
              <strong>{t("orders.orderID")}</strong> {data?.sfa_order_id}
            </p>
            <p>
              <strong>{t("orders.status")}:</strong> {data?.status}
            </p>
          </div>
          <div className="flex flex-col text-right">
            <p>
              <strong>{t("orders.customerID")}:</strong> {data?.customer_id}
            </p>
            <p>
              <strong>{t("orders.addressID")}</strong> {data?.address_id}
            </p>
          </div>
        </div>

        <div className="mt-6">
          <h3 className="text-md font-semibold border-b pb-2">{t("orders.invoice")}</h3>
          {data && data?.invoices?.length > 0 ? (
            <table className={`min-w-full mt-2 text-sm table-auto ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
              <thead>
                <tr className="border-b">
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.invoice")} #</th>
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.date")}</th>
                  {/* <th className="py-2 px-4 text-left">Download</th> */}
                </tr>
              </thead>
              <tbody>
                {data?.invoices.map((invoice) => (
                  <tr key={invoice.invoice_id} className="border-b">
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{invoice.invoice_id}</td>
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                      {new Date(invoice.invoice_date).toLocaleDateString()}
                    </td>
                    {/* <td className="py-2 px-4">
                      <a
                        href={invoice.invoice_path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Download
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className={`text-sm text-gray-500 mt-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
              {lang === 'Arabic' ? 'لا يوجد أوردرات متاحة' : 'No invoices available.'}
            </p>
          )}
        </div>

        <div className="mt-6">
          <h3 className="text-md font-semibold border-b pb-2">{t("orders.shipments")}</h3>
          {data && data?.shipments?.length > 0 ? (
            <table className={`min-w-full mt-2 text-sm table-auto ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
              <thead>
                <tr className="border-b">
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.shipments")} #</th>
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.status")}</th>
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.date")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.shipments.map((shipment) => (
                  <tr key={shipment.shipment_id} className="border-b">
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{shipment.shipment_id}</td>
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{shipment.status}</td>
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                      {new Date(shipment.shimpment_date).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className={`text-sm text-gray-500 mt-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
              {lang === 'Arabic' ? 'لا يوجد ارسالات متاحة' : 'No shipments available.'}
            </p>
          )}
        </div>

        <div className="mt-6">
          <h3 className="text-md font-semibold border-b pb-2">{t("orders.items")}</h3>
          {data && data?.items?.length > 0 ? (
            <table className={`min-w-full mt-2 text-sm table-auto ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
              <thead>
                <tr className="border-b">
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.productID")}</th>
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.itemCode")}</th>
                  <th className={`py-2 px-4 text-left ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{t("orders.quantity")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.items.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{item.product_id}</td>
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{item.item_code || "N/A"}</td>
                    <td className={`py-2 px-4 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{item.quantity.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-sm text-gray-500 mt-2">No items available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
