import axios from "./config";

import Cookies from "js-cookie";

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}
const getOrderHistory = async (data: any, pageNumber: any): Promise<any> => {
  const response = await axios.post<any>(
    `/nwc/orders/history?page=${pageNumber}&size=20`,
    data,
    {
      headers: getHeader(),
    }
  );
  return response;
};

export const getItemByOrderId = async (orderId: Number): Promise<any> => {
  const response = await axios.get<any>(`nwc/order/${orderId}`, {
    headers: getHeader(),
  });
  return response;
};

export const getDeliveryModalData = async (orderId: Number,deliveryId:any): Promise<any> => {
  const response = await axios.get<any>(`nwc/delivery-details/${orderId}/${deliveryId}`, {
    headers: getHeader(),
  });
  return response;
};


export const getPreSigned = async (orderId: Number): Promise<any> => {
  const response = await axios.get<any>(`nwc/nwc/delivery/image/${orderId}/presigned-url`)
  return response;
};
export const getInvoiceModalData = async (invoiceId: Number): Promise<any> => {
  const response = await axios.get<any>(`/nwc/inventory/details/${invoiceId}`, {
    headers: getHeader(),
  });
  return response;
};

export default getOrderHistory;
