import React, { useEffect, useState } from "react";

interface DropdownProps {
  title: string;
  options: Array<{ warehouseId: string; warehouseName: string }>;
  label: string;
  isOpen: boolean;
  selectedOptions: string[];
  onToggle: () => void;
  onOptionChange: (selectedIds: string[]) => void;
  onClose: () => void;
  position?: string;
  section?: string;
  toasterError?: boolean;
}

const InventoryDropdown: React.FC<DropdownProps> = ({
  title,
  options,
  label,
  isOpen,
  selectedOptions,
  onToggle,
  onOptionChange,
  onClose,
  position,
  section,
  toasterError,
}) => {
  const [lang, setLang] = useState(false);

  useEffect(() => {
    var arabic = localStorage.getItem("lang");
    setLang(arabic === "Arabic");
  }, []);

  function truncateText(text: string, charLimit: number = 20): string {
    return text.length > charLimit ? text.slice(0, charLimit) + "..." : text;
  }

  const handleOptionChange = (optionId: string) => {
    let newSelectedOptions;
    if (selectedOptions.includes(optionId)) {
      newSelectedOptions = selectedOptions.filter(id => id !== optionId);
    } else {
      newSelectedOptions = [...selectedOptions, optionId];
    }
    onOptionChange(newSelectedOptions);
  };

  return (
    <div
      className={`relative inline-block ${
        section === "finance" || section === "create" ? "lg:w-52 w-full" : "w-full"
      } ${position === "top" ? "z-40" : "z-30"}`}
    >
      <p className={`text-xs mb-2 ms-1 ${lang ? "rtl text-right" : "ltr text-left"}`}>{label}</p>
      <button
        onClick={onToggle}
        className={`inline-flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none ${
          toasterError ? "border-red-500" : ""
        } ${lang && section === "create" ? "pr-9" : ""}`}
        type="button"
      >
        {selectedOptions.length > 0
          ? truncateText(selectedOptions.map(id => options.find(opt => opt.warehouseId === id)?.warehouseName).join(", "))
          : truncateText(title)}
        {section === "create" ? (
          <div className="absolute top-4 right-3 flex items-center pointer-events-none text-gray-500">▼</div>
        ) : (
          <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        )}
      </button>
      {isOpen && (
        <div
          className="absolute left-0 z-50 mt-2 w-full origin-top-right bg-white border border-gray-300 rounded-md shadow-lg overflow-visible"
          onBlur={onClose}
          tabIndex={-1}
        >
          <div className="py-1 max-h-60 overflow-y-auto" style={{ scrollbarWidth: "thin", scrollbarColor: "#d1d5db transparent" }}>
            {options.map(option => (
              <div
                key={option.warehouseId}
                className={`flex items-center w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 cursor-pointer ${
                  selectedOptions.includes(option.warehouseId) ? "bg-gray-100" : ""
                }`}
                onClick={() => handleOptionChange(option.warehouseId)}
              >
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option.warehouseId)}
                  onChange={() => handleOptionChange(option.warehouseId)}
                  className="form-checkbox h-5 w-5 text-gray-800 border-gray-300 rounded"
                />
                <span className={`${lang ? "mr-3" : "ml-3"}`}>{option.warehouseName}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryDropdown;
