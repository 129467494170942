import React from "react";
import WorkStatsCard from "../../components/WorkStatsCard";
import { useTranslation } from "react-i18next";

interface ReportProps {
  employeeSchedule: any;
  idleTime:any;
  distanceTravelled:any
}
const Report: React.FC<ReportProps> = ({ employeeSchedule,idleTime,distanceTravelled }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const convertToSeconds = (time: string): number => {
    const [hours, minutes, seconds] = time?.split(":").map(Number);
    return (hours * 3600 + minutes * 60 + seconds) || 0;
  };
  const formatTime = (seconds: number): string => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return [hrs, mins, secs].map(unit => String(unit).padStart(2, "0")).join(":");
  };
  const operationTimeSeconds = employeeSchedule?.operation_time
  ? convertToSeconds(employeeSchedule.operation_time)
  : 0;
  const breakTimeSeconds = employeeSchedule?.break_time
  ? convertToSeconds(employeeSchedule.break_time)
  : 0;

  const idletime = Math.max(
    0,
    (idleTime?.idle_time_seconds || 0) - operationTimeSeconds - breakTimeSeconds
  );
  
  const drivingTime = employeeSchedule?.driving_time
    ? Math.max(0, convertToSeconds(employeeSchedule.driving_time) - idletime)
    : 0;
  
  return (
    <div className="overflow-auto h-auto md:h-[calc(100vh-210px)] scrollbar scrollbar-thin">
      <div className="grid grid-cols-2 gap-4 p-4">
        <WorkStatsCard
          title={t("visit.REPORT")}
          workingDays={employeeSchedule?.working_days + " " + t("Days") || "-"}
        />
        <WorkStatsCard
          title={t("visit.total_working_time")}
          workingDays={employeeSchedule?.total_working_time || "-"}
        />
        <WorkStatsCard
          title={t("visit.total_visit")}
          workingDays={employeeSchedule?.total_visits || "-"}
        />
        <WorkStatsCard
          title={t("visit.successful_visit")}
          textColor="#00C48C"
          workingDays={employeeSchedule?.successful_visits || "-"}
        />
        <WorkStatsCard
          title={t("visit.failed_visit")}
          textColor="#FF4D68"
          workingDays={employeeSchedule?.failed_visits || "-"}
        />
        <WorkStatsCard
          title={t("visit.collection_visit")}
          textColor="#FFAA2A"
          workingDays={employeeSchedule?.collection_visits || "-"}
        />
        <WorkStatsCard
          title={t("visit.TOTAL_PURCHASE_ORDER")}
          workingDays={employeeSchedule?.total_purchase_orders || "-"}
        />
        <WorkStatsCard
          title={t("visit.carton_sale")}
          workingDays={employeeSchedule?.cartons_sold || "-"}
        />
        <WorkStatsCard
          title={t("visit.total_collection")}
          workingDays={employeeSchedule?.amount_collected || "-"}
        />
        <WorkStatsCard
          title={t("visit.drivingdistance")}
          workingDays={ (distanceTravelled?.distance_metres / 1000).toFixed(2) + " " + "Km" || "-"}
        />
        <WorkStatsCard
          title={t("visit.BREAK_TIME")}
          workingDays={employeeSchedule?.break_time || "-"}
        />
        <WorkStatsCard
          title={t("visit.TRAVEL_TIME")}
          workingDays={String(formatTime(drivingTime)) || "-"}
        />
        <WorkStatsCard
          title={t("visit.OPERATIONS_TIME")}
          workingDays={employeeSchedule?.operation_time || "-"}
        />
        <WorkStatsCard
  title={t("visit.IDEAL_TIME")}
  workingDays={
    formatTime(idletime)
    || "-"
  }
/>

      </div>
    </div>
  );
};

export default Report;
