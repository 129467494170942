import Cookies from "js-cookie";
import axios from "./config";

export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

export const getExportData = async (
  month: string | null,
  year: Number | null,
  accept: string | null
): Promise<any> => {
  const accept_language = accept === "Arabic" ? "ar" : "en";
  const response = await axios.get<any>(
    `nwc/export/customer-statement?month=${month}&year=${year}&accept_language=${accept_language}`,
    {
      responseType: "blob",
      headers: getHeader(),
    }
  );
  return response;
};

export const getDataTobePrinted = async (
  month: string | null,
  year: Number | null
): Promise<any> => {
  const response = await axios.get<any>(
    `nwc/customer-statement?month=${month}&year=${year}`,
    {
      headers: getHeader(),
    }
  );
  return response;
};

export const getTableData = async (
    month: string | null,
    year: Number | null
  ): Promise<any> => {
    const response = await axios.get<any>(
      `nwc/customer-statement?month=${month}&year=${year}`,
      {
        headers: getHeader(),
      }
    );
    return response;
  };
