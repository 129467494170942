import Loader from "../../../components/Loader";
import React, { useState, useEffect } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import saveAddress from "../api/editAddress";
import { toast, ToastContainer } from "react-toastify";
import { addAddress } from "../api/editAddress";
import { getAddressDropdown } from "../api/createOrder";
import { Map, APIProvider, Marker } from "@vis.gl/react-google-maps";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";
const saudiCenter = { lat: 24.774265, lng: 46.738586 };
const saudiBounds = {
  north: 33.0,
  south: 16.0,
  east: 60.0,
  west: 34.0,
};

interface ContactAndEmailTableProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
  currentPage: any;
  address: any;
  getAddressData: any;
}
const ContactAndEmailTable: React.FC<ContactAndEmailTableProps> = ({
  loading,
  setLoading,
  currentPage,
  address,
  getAddressData,
}) => {
  const [addressOptions, setAddressOptions] = useState<any>();
  const [addTitle, setAddressTitle] = useState<any>();
  const [buildNo, setBuildNo] = useState<any>();
  const [city, setCity] = useState<any>();
  const [distict, setDistrict] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [lat, setLat] = useState<any>();
  const [lng, setLng] = useState<any>();
  const [mobile, setMobile] = useState<any>();
  const [name, setName] = useState<any>();
  const [postalCode, setPostalCode] = useState<any>();
  const [street, setStreet] = useState<any>();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [menuOpen, setMenuOpen] = useState<number | null>(null);
  const toggleMenu = (id: number) => {
    setMenuOpen(menuOpen === id ? null : id);
  };

  const [showModal, setShowModal] = useState(false);
  const [dynamicID, setDynamicId] = useState();
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const { t } = useTranslation();
  const handleOpenModal = (postal_code: any, addressData: any) => {
    setDynamicId(postal_code); // Set dynamic ID if needed
    setFormData({
      name: addressData?.name || "",
      address_title: addressData?.address_title || "",
      street: addressData?.street || "",
      building_no: addressData?.building_no || "",
      city: addressData?.city || "",
      district: addressData?.district || "",
      postal_code: addressData?.postal_code || "",
      latitude: addressData?.latitude || 24.774265, // Default latitude
      longitude: addressData?.longitude || 46.738586, // Default longitude
      mobile: addressData?.mobile || "",
      email: addressData?.email || "",
    });
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [formData, setFormData] = useState<any>({
    name: "",
    address_title: "",
    street: "",
    building_no: "",
    city: "",
    district: "",
    postal_code: "",
    latitude: 24.774265,
    longitude: 46.738586,
    mobile: "",
    email: "",
  });

  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const mockData = {
      name: "keda Doe",
      address_title: "Home",
      street: "123 Main St",
      building_no: "A1",
      city: "Riyadh2",
      district: "Central",
      postal_code: "32567",
      latitude: 24.774265,
      longitude: 46.738586,
      mobile: "5551234567",
      email: "change.doe@example.com",
    };

    // setFormData(mockData);
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveAddress = async () => {
    setLoading(true);
    setError(null);

    try {
      const params = {};
      const response = await saveAddress(params, formData, dynamicID);

      if (formData.mobile.length !== 9) {
        if (lang === "Arabic") {
          toast.error("يجب أن يكون رقم الجوال 9 أرقام");
        } else {
          toast.error("Mobile number must be 9 digits");
        }
        return;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        if (lang === "Arabic") {
          toast.error("تنسيق البريد الإلكتروني غير صحيح");
        } else {
          toast.error("Invalid email format");
        }
        return;
      }

      if (lang === "Arabic") {
        toast.success("تم حفظ العنوان بنجاح");
      } else {
        toast.success("Address saved successfully!");
      }

      setMenuOpen(null);
      setShowModal(false);
      setTimeout(() => {
        setFormData({
          name: "",
          mobile: "",
          email: "",
        });

        const param = {
          page_number: currentPage,
          page_size: 20,
        };
        const data = {
          search: "",
        };
        getAddressData(param, null);
      }, 2000);
    } catch (err: any) {
      console.error("Error saving address:", err.response.data.detail);
      setShowModal(false);
      if (
        err.response.data.detail ==
          "An address with the same mobile number already exists." ||
        err.response.data.detail ==
          "An address with the same email already exists."
      ) {
        if (lang === "Arabic") {
          toast.error("يوجد عنوان بنفس رقم الجوال بالفعل.");
        } else {
          toast.error("An address with the same mobile number already exists.");
        }
      } else {
        if (lang === "Arabic") {
          toast.error("حدث خطأ ما");
        } else {
          toast.error("Error saving address:", err.message);
        }
      }

      setError("Failed to save address. Please try again.");
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  // const fetchAddresses = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await getAddressDropdown({});
  //     setAddressOptions(response.items);
  //   } catch (err: any) {
  //     setError("Failed to load addresses");
  //     console.error(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchAddresses();
  // }, []);
  const handleMapClick = (e: any) => {
    const geocoder = new google.maps.Geocoder();
    const lat = e.detail.latLng.lat;
    const lng = e.detail.latLng.lng;
    setLng(lng);
    setLat(lat);
    setMarkerPosition({ lat, lng });
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        const result = results[0];
        const addressComponents = result.address_components;
        console.log("addressComponentsaddressComponents", addressComponents);
        // Extract desired information
        const getComponent = (type: string) =>
          addressComponents.find((component) => component.types.includes(type))
            ?.long_name;

        const address = result.formatted_address;
        const city = getComponent("locality");
        const postalCode = getComponent("postal_code");
        const district = getComponent("administrative_area_level_2");
        const street = getComponent("route");

        setCity(city);
        setPostalCode(postalCode);
        setDistrict(district);
        setStreet(street);

        setFormData({
          ...formData,
          street: street || "",

          city: city || "",
          district: district || "",
          postal_code: postalCode || "",
        });
      } else {
        console.error("Geocode failed due to: " + status);
      }
    });
  };
  const onClose = () => {
    setIsPopupOpen(false);
  };

  // const handleContinue = async () => {
  //   try {
  //     const data = {
  //       address_title: addTitle,
  //       building_no: buildNo,
  //       city: city,
  //       district: distict,
  //       email: email,
  //       latitude: String(lat),
  //       longitude: String(lng),
  //       mobile: mobile,
  //       name: name,
  //       postal_code: postalCode,
  //       street: street,
  //     };
  //     const response = await addAddress(data);
  //     if (lang === "Arabic") {
  //       toast.success("تم إضافة العنوان بنجاح");
  //     } else {
  //       toast.success("Address Added Successfully");
  //     }
  //     fetchAddresses();

  //     setTimeout(() => {
  //       setShowModal(false);
  //       setIsPopupOpen(false);
  //     }, 2000);
  //   } catch (err: any) {
  //     console.error("Error saving address:", err.message);
  //     setError("Failed to save address. Please try again.");
  //     if (lang === "Arabic") {
  //       toast.error("حدث خطأ ما");
  //     } else {
  //       toast.error("Error!");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleViewAddress = (item: any) => {
    setSelectedAddress(item);
    setIsPopupOpen(true);
  };
  const [lang, setLang] = useState<string | null>(null);

  useEffect(() => {
    const lang = currentLang();
    setLang(lang);
  }, []);

  return (
    <div className="p-6 bg-gray-50">
      <ToastContainer />
      <div className="bg-white shadow-sm rounded-md overflow-y-auto">
        <div
          className="h-[61vh]"
          style={{
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <table className="min-w-full border-collapse overflow-x-scroll">
            <thead className="bg-blue-50 sticky top-0 z-20">
              <tr>
                {[
                  t("manage.title"),
                  t("manage.sapAddressID"),
                  t("manage.receiverName"),
                  t("manage.contactAndEmailID"),
                  t("manage.building"),
                  t("manage.street"),
                  t("manage.city"),
                  t("manage.district"),
                  t("manage.postalCode"),
                  t(""),
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-left text-xs text-gray-900 font-semibold border border-gray-300"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={11} className="text-center py-4">
                    <Loader />
                  </td>
                </tr>
              ) : !loading && address && address?.length > 0 ? (
                address.map((item: any, index: number) => (
                  <tr
                    key={item.SAPAdressID || index}
                    className="text-sm hover:bg-gray-50 relative"
                  >
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.address_title || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.outlet_id || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.name || "-"}
                    </td>
                    <td
                      className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap"
                      dangerouslySetInnerHTML={{
                        __html:
                          item.mobile && item.email
                            ? `${item.mobile}<br />${item.email}`
                            : item.mobile || item.email || "-",
                      }}
                    ></td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.building_no || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.street || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.city || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.district || "-"}
                    </td>
                    <td className="text-16 px-4 py-3 border border-gray-300 text-black-600 whitespace-nowrap">
                      {item.postal_code || "-"}
                    </td>
                    <td className="px-4 py-2 border relative">
                      <button
                        onClick={() => toggleMenu(index)}
                        className="text-gray-500 hover:text-gray-800"
                      >
                        ⋮
                      </button>
                      {menuOpen === index && (
                        <div
                          className={`absolute mt-2 w-40 bg-white border border-gray-200 shadow-lg rounded z-10 ${
                            lang === "Arabic" ? "left-0" : "right-0"
                          }`}
                        >
                          <ul className="py-1 text-sm text-gray-900">
                            <li
                              className="px-4 py-2 hover:bg-gray-500 cursor-pointer"
                              onClick={() => handleViewAddress(item)}
                            >
                              {t("manage.viewAddress")}
                            </li>
                            <li
                              className="px-4 py-2 hover:bg-gray-500 cursor-pointer"
                              onClick={() => handleOpenModal(item.id, item)}
                            >
                              {t("manage.editAddress")}
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} className="text-center py-4">
                    {lang === "Arabic"
                      ? "لا يوجد عناوين"
                      : "No addresses found."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 sm:p-6">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-4 sm:p-6 relative flex flex-col h-[90%] overflow-hidden">
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              &times;
            </button>

            <h2 className="text-lg font-semibold mb-4">
              {t("manage.editAddress")}
            </h2>
            <div className="flex flex-col sm:flex-row flex-grow gap-4 overflow-hidden">
              {/* Map Container */}
              <div className="w-full sm:w-1/2 h-64 sm:h-full border bg-gray-100 rounded-md">
                <APIProvider
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}
                >
                  <Map
                    style={{ width: "100%", height: "100%" }}
                    defaultCenter={saudiCenter}
                    defaultZoom={5}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                    minZoom={5}
                    maxZoom={20}
                    zoomControl={true}
                    restriction={{
                      latLngBounds: saudiBounds,
                      strictBounds: true,
                    }}
                  >
                    {markerPosition && <Marker position={markerPosition} />}
                  </Map>
                </APIProvider>
              </div>
              <div className="w-full sm:w-1/2 pl-0 sm:pl-6 h-full overflow-y-auto max-h-full">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Building_Number")}
                    </label>
                    <input
                      type="text"
                      name="building_no"
                      value={formData.building_no}
                      disabled
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Street")}
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={formData.street}
                      disabled
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.City")}
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      disabled
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.District")}
                    </label>
                    <input
                      type="text"
                      name="district"
                      value={formData.district}
                      disabled
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Postal_Code")}
                    </label>
                    <input
                      type="text"
                      name="postal_code"
                      value={formData.postal_code}
                      disabled
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Address_Title")}
                    </label>
                    <input
                      type="text"
                      name="address_title"
                      value={formData.address_title}
                      disabled
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Receiver_Name")}
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Receiver_Mobile")}
                    </label>
                    <div className="flex items-center">
                      {lang !== 'Arabic' && <span className="border border-gray-300 bg-gray-100 px-3 py-2 text-sm rounded-l-md">
                        +966
                      </span>}
                      <input
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,9}$/.test(value)) handleInputChange(e);
                        }}
                        onBlur={(e) => {
                          if (e.target.value.length !== 9)
                            toast.error("Mobile number must be 9 digits");
                        }}
                        className="w-full border border-gray-300 rounded-r-md py-2 px-3 text-sm"
                      />
                      {lang === 'Arabic' && <span className="border border-gray-300 bg-gray-100 px-3 py-2 text-sm rounded-l-md">
                        966+
                      </span>}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700">
                      {t("createOrder.Receiver_Email_ID")}
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      onBlur={(e) => {
                        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value))
                          toast.error("Invalid email format");
                      }}
                      className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleSaveAddress}
                    className="bg-[#1dbda1] text-white text-sm font-medium px-6 py-2 rounded-md shadow"
                  >
                    {t("manage.updateAddress")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPopupOpen && selectedAddress && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-4 sm:p-6 relative">
            {/* Header Section */}
            <div className="flex justify-between items-center pb-4 border-b">
              <h2 className="text-lg font-semibold">
                {t("manage.viewAddress")}
              </h2>
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={onClose}
              >
                ✕
              </button>
            </div>

            <div className="flex flex-col sm:flex-row bg-[#F5F5F5] p-4 sm:p-6 gap-4 sm:gap-6">
              <div className="w-full sm:w-1/2">
                <div className="flex flex-col sm:flex-row sm:gap-6 mt-2">
                  <div>
                    <p className="text-sm">{t("createOrder.Address_Title")}</p>
                    <p className="text tracking-wide font-semibold">
                      {selectedAddress.address_title || "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">
                      {t("createOrder.Building_Number")}
                    </p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.building_no || "-"}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row sm:gap-6 mt-2">
                  <div>
                    <p className="text-sm">{t("createOrder.Street")}</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.street || "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">{t("createOrder.City")}</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.city || "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">{t("createOrder.District")}</p>
                    <p className="tracking-wide font-semibold">
                      {selectedAddress.district || "-"}
                    </p>
                  </div>
                </div>

                <p className="text-sm mt-2">{t("createOrder.Postal_Code")}</p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.postal_code || "-"}
                </p>
              </div>
              <div className="hidden sm:block border-l border-gray-400"></div>
              <div className="w-full sm:w-1/2">
                <p className="text-sm">{t("createOrder.Receiver_Name")}</p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.name || "-"}
                </p>

                <p className="text-sm mt-2">
                  {t("createOrder.Receiver_Mobile")}
                </p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.mobile || "-"}
                </p>

                <p className="text-sm mt-2">
                  {t("createOrder.Receiver_Email_ID")}
                </p>
                <p className="tracking-wide font-semibold">
                  {selectedAddress.email || "-"}
                </p>
              </div>
            </div>

            <div className="flex justify-center mt-6">
              <button
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
                onClick={() => {
                  // handleContinue();
                  onClose();
                }}
              >
                {t("createOrder.Continue")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactAndEmailTable;
