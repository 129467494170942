import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export function getUserInfo() {
  const token = Cookies.get("token");
  if(token){
    let decoded = ''
   try {
    decoded= jwtDecode(token);
   } catch (error) {
    console.log(error)
   }
   const parse = JSON.stringify(decoded);
   return JSON.parse(parse);
  }else{
    return null
  }
}
export function getHeader() {
  const token = Cookies.get("token") || "";
  const header = {
    Authorization: token,
  };
  return header;
}

export function formatCurrentStartDate(date: any) {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T00:00:00.000Z`;
}

export function formatCurrentEndDate(date: any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T23:59:59.000Z`;
}

export function useRTL(isArabic: any) {
  const direction = isArabic ? "rtl" : "ltr";
  document.documentElement.setAttribute("dir", direction);
}

export function currentLang() {
  const lang = localStorage.getItem("lang");
  return lang;
}

export function formatDate(inputdate: any) {
  const date = new Date(inputdate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function formatNumber(number: any) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(number));
}
export function formatNumberWithoutFraction(number: any) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(Number(number));
}

export function TimeZoneDisplay() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getPagePerSize() {
  return 20;
}
export function getRoleAccess(data:string) {
    const userTeams = localStorage.getItem('section') || '';
    const hasAccess = userTeams.includes(data);
  return hasAccess
}

export default function utility() {}
