import { TableColumn } from "../Pages/ComponentToPrint";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";
import { InvoiceData } from "../OrderHistory";
export const metaData = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc: nwc,
  title: "report",
};

export const tableHeaders: TableColumn[] = [
  { header: "Warehouse Name", key: "warehouseName" },
  { header: "Product Id", key: "productionID" },
  { header: "Description", key: "description" },
  { header: "Unit", key: "unit" },
  {
    header: "Available Stocks",
    key: "availableStocks",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "In Quantity",
    key: "inQuantity",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Blocked",
    key: "blocked",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Returns",
    key: "returns",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "In Transits",
    key: "inTransits",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Open Order Qty",
    key: "openOrderQty",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Delivery In Transist",
    key: "deliveryInTransist",
    formatter: (value) => value.toLocaleString(),
  },
];

export const financeTableHeaders: TableColumn[] = [
  { header: "Transaction Type", key: "transactionType" },
  { header: "Transaction Date", key: "transactionDate" },
  { header: "Transaction Time", key: "transactionTime" },
  { header: "Document Number", key: "documentNumber" },
  {
    header: "Credit (SAR)",
    key: "credit",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Debit (SAR)",
    key: "debit",
    formatter: (value) => value.toLocaleString(),
  },
  {
    header: "Carry Forward Balance (SAR)",
    key: "carryForwardBalance",
    formatter: (value) => value.toLocaleString(),
  },
];

export const financeMetaData = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc:nwc,
  title: "report",
};

export const invoiceItemKeys: string[] = [
  "itemNo",
  "materialNo",
  "materialDescEn",
  "materialDescAr",
  "actQtyDel",
  "uom",
  "unitPrice",
  "vatAmount",
  "netValue",
  "grossValue",
  "total",
];

export const invoiceKeyMap: Record<string, keyof InvoiceData> = {
  "invoiceNo": "InvoiceNo",
  "invoiceDate": "InvoiceDate",
  "invoiceTime": "InvoiceTime",
  "payerID": "PayerId",
  "payerNameEn": "PayerNameEn",
  "payerNameAr": "PayerNameAr",
  "countryCode": "CountryCode",
  "address": "Adressnr",
  "street": "Street",
  "city": "City",
  "district": "District",
  "buildingNo": "BuildingNo",
  "postCode": "PostCode",
  "termDesc": "TermDesc",
  "vatNo": "VATNo",
  "crNo": "CRNo",
  // "additionalNo": "AdditionalNo",
  "orderNo": "OrderNo",
  "outletName": "OutletName",
  "receiverName": "ReceiverName",
  "receiverMobile": "ReceiverMobile",
};

export const invoiceKeyMapForPrint = {
  "Invoice No": "InvoiceNo",
  "Invoice Date": "InvoiceDate",
  "Invoice Time": "InvoiceTime",
  "Payer Id": "PayerID",
  "Payer Name En": "PayerNameEn",
  "Payer Name Ar": "PayerNameAr",
  "Country Code": "CountryCode",
  "Adress": "Adressnr",
  "Street": "Street",
  "City": "City",
  "District": "District",
  "Building No": "BuildingNo",
  "Post Code": "PostCode",
  "Term Desc": "TermDesc",
  "VAT No": "VATNo",
  "CR No": "CRNo",
  "Order No": "OrderNo",
  "Outlet Name": "OutletName",
  "Receiver Name": "ReceiverName",
  "Receiver Mobile": "ReceiverMobile",
};
