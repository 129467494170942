import React, { useEffect, useState } from "react";
import getShipmentDropdown, {
  getAddressDropdown,
  getNewShipmentDropdown,
} from "../api/createOrder";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";
import SearchableDropdown from "./SearchableDropdown";
import Dropdown from "./Dropdown";
interface NewOrderProps {
  toggleTableToShow?: () => void;
  handleClear?: () => void;
  showTable?: boolean;
  showSummesion?: boolean;
  toggleSummesion?: () => void;
  loading?: boolean;
  handleSelectoption?: any;
  wareHouseId?: any;
  warehouseDropdown: {
    isOpen: boolean;
    selectedOption: string | null;
  };
  setWarehouseDropdown: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      selectedOption: string | null;
    }>
  >;
  selectedAddress: any;
  addressOptions: any;
  addressError: boolean;
  setAddressError: (value: boolean) => void;
  handleAddressSelectChange: (
    event: React.ChangeEvent<HTMLSelectElement> | null
  ) => void;
  toasterError: boolean;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  setToasterError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface WarehouseOption {
  warehouseId: string;
  warehouseName: string;
}
interface NewWarehouseOption {
  shipmentPointName: string;
  shipmentPointId: string;
  shipmentPointNameArabic: string;
}

const NewOrder: React.FC<NewOrderProps> = ({
  toggleTableToShow,
  handleClear,
  showTable,
  showSummesion,
  toggleSummesion,
  handleSelectoption,
  wareHouseId,
  warehouseDropdown,
  setWarehouseDropdown,
  selectedAddress,
  addressOptions,
  addressError,
  setAddressError,
  handleAddressSelectChange,
  toasterError,
  searchTerm,
  setSearchTerm,
  setToasterError
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<NewWarehouseOption[]>(
    []
  );
  const warehouseOptions = [
    { warehouseId: "1", warehouseName: "Berain" },
    { warehouseId: "2", warehouseName: "NWC" },
  ];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const fetchShipmentPoints = async () => {
    setLoading(true);
    try {
      const response = await getNewShipmentDropdown({});
      const parsedResponse: NewWarehouseOption[] = response.shipmentPoints;

      setDropdownOptions(parsedResponse);
    } catch (err: any) {
      setError("Failed to load shipment points");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeOption = (id:any) => {
    setWarehouseDropdown({ isOpen: false, selectedOption: id })
    setToasterError(false);
  }

  useEffect(() => {
    fetchShipmentPoints();
  }, []);
  const [lang, setLang] = useState(false);
  useEffect(() => {
    var arabic = localStorage.getItem("lang");
    console.log("arabic", arabic);

    if (arabic === "Arabic") {
      setLang(true);
    } else {
      setLang(false);
    }
  }, []);
  return (
    <div className="sticky top-0 left-0 z-20 p-1 bg-white w-full">
  <p className="text-xs font-bold">{t("createOrder.createOrder")}</p>

  <div className="flex flex-col md:flex-row items-center lg:items-center justify-between bg-gray-200 p-4 md:p-6 mt-3 border rounded-md w-full gap-4">
    <div className="flex flex-col lg:flex-row gap-4 w-full lg:w-[60vw]">
      <div className="w-full">
        <label htmlFor="shipment-point" className="block text-sm font-medium text-gray-700">
          {t("createOrder.ShipmentPoint")}
        </label>
        <select
          id="shipment-point"
          name="shipment-point"
          value={wareHouseId}
          onChange={handleSelectoption}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
        >
            <option value="" disabled selected hidden>
            {t("createOrder.selectship")}
            </option>
          {loading ? (
            <option>Loading...</option>
          ) : error ? (
            <option>{error}</option>
          ) : dropdownOptions?.length > 0 ? (
            dropdownOptions.map((option) => (
              <option key={option.shipmentPointId} value={option.shipmentPointId}>
                {lang ? option.shipmentPointNameArabic : option.shipmentPointName}
              </option>
            ))
          ) : (
            <option>No options available</option>
          )}
        </select>
      </div>

      <div className="w-full lg:-mt-1">
        <SearchableDropdown
          addressOptions={addressOptions}
          selectedAddress={selectedAddress}
          handleAddressSelectChange={handleAddressSelectChange}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          loading={loading}
          error={error}
          addressError={addressError}
          setAddressError={setAddressError}
        />
      </div>

      <div className="w-full lg:w-1/3 lg:-mt-1">
        <label className="block text-sm font-medium text-gray-700">{t("createOrder.Delivery_By")}</label>
        <Dropdown
          title={t("createOrder.Delivery_By")}
          options={warehouseOptions}
          label=""
          isOpen={warehouseDropdown.isOpen}
          selectedOption={warehouseDropdown.selectedOption}
          onToggle={() => setWarehouseDropdown((prev) => ({ ...prev, isOpen: !prev.isOpen }))}
          onOptionChange={(id) => handleChangeOption(id)}
          onClose={() => setWarehouseDropdown((prev) => ({ ...prev, isOpen: false }))}
          section="create"
          toasterError={toasterError}
        />
      </div>
    </div>

    <div className="w-full flex flex-col sm:flex-row items-center gap-4 lg:w-[40vw] lg:mt-3">
     
       <button
       onClick={toggleTableToShow}
       className="bg-[#005879] text-white border rounded-md py-2 px-4 text-sm w-full sm:w-32 lg:w-32"
     >
       {t("createOrder.Continue")}
     </button>
      
        <button
          onClick={handleClear}
          className="bg-[#005879] text-white border rounded-md py-2 px-4 text-sm w-full sm:w-24 lg:w-32"
        >
          {t("createOrder.clearData")}
        </button>
    </div>
  </div>
</div>

  );
};

export default NewOrder;
