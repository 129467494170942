import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

interface SearchableDropdownProps {
  addressOptions: { id: string; name: string;outlet_id:string }[];
  selectedAddress: string;
  handleAddressSelectChange: (
    event: React.ChangeEvent<HTMLSelectElement> | null
  ) => void;
  loading: boolean;
  error: string | null;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  addressError?: boolean;
  setAddressError: (value: boolean) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  addressOptions,
  selectedAddress,
  handleAddressSelectChange,
  loading,
  error,
  searchTerm,
  setSearchTerm,
  addressError,
  setAddressError,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  console.log(addressOptions, "address options");

  const filteredOptions = addressOptions?.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log(filteredOptions, "filtered options");

  // const handleSelect = (selectedValue: string) => {
  //   const syntheticEvent = {
  //     target: { value: selectedValue },
  //   } as React.ChangeEvent<HTMLSelectElement>;

  //   handleAddressSelectChange(syntheticEvent);
  //   setSearchTerm(
  //     addressOptions.find((opt) => opt.outlet_id === selectedValue)?.name || ""
  //   );
  //   setIsOpen(false);
  //   setAddressError(false);
  // };
  const handleSelect = (selectedValue: string) => {
    const selectedOption = addressOptions.find((opt) => opt.outlet_id === selectedValue);
  
    if (!selectedOption) return;
  
    const syntheticEvent = {
      target: { value: selectedOption.outlet_id, dataset: { id: selectedOption.id } },
    } as unknown as React.ChangeEvent<HTMLSelectElement>;
  
    handleAddressSelectChange(syntheticEvent);
    setSearchTerm(selectedOption.name || "");
    setIsOpen(false);
    setAddressError(false);
  };
  

  const handleSetSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      handleAddressSelectChange(null);
      setSearchTerm("");
    }
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  return (
<div ref={dropdownRef} className="relative w-full lg:w-[20vw] z-40">
      <div className="flex items-center py-2 rounded-md">
        <div className="relative w-full">
      <label className="block text-sm font-medium text-gray-700">{t("createOrder.Select_AddressDDLabel")}</label>
          <input
            type="text"
            placeholder={t("createOrder.Select_AddressDD")}
            value={searchTerm}
            onChange={(e) => handleSetSearchTerm(e)}
            onFocus={() => {
              setIsOpen(true);
              setSearchTerm(searchTerm);
            }}
            className={`${
              addressError ? "border-red-500" : ""
            } block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm pr-10 focus:last:border-none outline-none`}
          />
         <div className="absolute inset-y-0 right-3 top-5 flex items-center pointer-events-none text-gray-500">
            ▼
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute ms-4 w-[90%] mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto">
          {filteredOptions?.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.id}
                className={`p-2 cursor-pointer hover:bg-indigo-100 ${
                  selectedAddress === option.outlet_id
                    ? "bg-indigo-200 font-semibold"
                    : ""
                }`}
                onClick={() => handleSelect(option.outlet_id)}
              >
                {option.name
                  .split(new RegExp(`(${searchTerm})`, "gi"))
                  .map((part, index) => (
                    <span
                      key={index}
                      className={
                        part.toLowerCase() === searchTerm.toLowerCase()
                          ? "text-indigo-600 font-bold"
                          : ""
                      }
                    >
                      {part}
                    </span>
                  ))}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
