/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback } from "react";
import DashboardPage from "../../../layout";
import { getGlobalFilter } from "../../../api/filters";
import PaginationComponent from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import Export from "../../../assets/icons/Export.svg";
import Filter from "../../../assets/icons/Filter.svg";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getPagePerSize,
  formatDate,
} from "../../../utility";
import TimeFrameSelector from "../../../components/TimeFrameSelector";
import Dropdown from "../../../components/Dropdown";
import DropdownMulti from "../../../components/DropdownMultiple";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Close from "../../../assets/close-circle.svg";
import Search from "../../../assets/Search.svg";
import { useNavigate } from "react-router-dom";
import { formatNumber, TimeZoneDisplay } from "../../../utility";
import Button from "../../../components/Button";
import { getDeliveryReport } from "../../../api/delivery-driver";
import debounce from "lodash/debounce";

const DeliveryReport: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [employees, setEmployeesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingForFilter] = useState(false);
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortType, setSortType] = useState<any>("");
  const { t } = useTranslation();
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedEmpId, setSelectedEmpId] = useState<any>([]);
  const [selectedEmpStatus, setSelectedEmpStauts] = useState<any>("");
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>([]);
  const [selectedRegion, setRegion] = useState<any>([]);
  const [territoryId, setTerritoryId] = useState<any>([]);
  const [routeId, setRouteId] = useState<any>([]);
  const [userType, setUserType] = useState<any>("");
  const [channelId, setChannelId] = useState<any>([]);
  const startIndex = (currentPage - 1) * employees?.size;
  const navigate = useNavigate();
  const employeesPerPage = getPagePerSize();

  const getGlobalFilters = async (data: any) => {
    try {
      setLoadingForFilter(true);
      const response = await getGlobalFilter(data);
      setLoadingForFilter(false);
      setGlobalFilters(response);
    } catch (error) {
      console.error("Error fetching global filters:", error);
    }
  };

  const handleFilterClick = () => {
    setOpen(true);
  };

  const handleChange = (event: any) => {
    updateSearchTerm(event);
    setSearchQuery(event);
  };

  const handleSortFillter = (data: string) => {
    if (data === sortType) {
      setSortType("");
      handleApply("");
    } else {
      setSortType(data);
      handleApply(data);
    }

    setIsDropdownOpen(false);
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleClose = () => setOpen(false);

  const handleClearFilters = () => {
    setSelectedEmpId([]);
    setSelectedSalesOffice([]);
    setRegion([]);
    setTerritoryId([]);
    setRouteId([]);
    setUserType(null);
    setChannelId([]);
    setSelectedEmpStauts(null);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setSelectTimeFrame("Today");
    setOpen(false);
    handleClearApply("");
  };

  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: 1,
      size: employeesPerPage,
    };
    setCurrentPage(1);
    getDeliveryData(data, params);
    handleClose();
  };

  const getPayload = (data: any) => {
    if (selectedEmpStatus) {
      data["status"] = selectedEmpStatus;
    }

    if (channelId.length > 0) {
      data["channel_ids"] = channelId;
    }
    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (routeId.length > 0) {
      data["route_ids"] = routeId;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmpId.length > 0) {
      data["subordinate_ids"] = selectedEmpId;
    }
    if (territoryId.length > 0) {
      data["territory_ids"] = territoryId;
    }
    if (userType) {
      data["user_type"] = userType;
    }
  };

  const handleExport = async () => {
    setLoading(true);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
      time_zone: TimeZoneDisplay(),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }
    getPayload(data);

    const params = {};
    try {
      await exportActiviyData(data, params);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportActiviyData = async (data: any, params: any) => {
    // await visitSummryReportList(params, data)
    //   .then((res) => {
    //     const url = window.URL.createObjectURL(new Blob([res]));
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = `team_visit_report.csv`;
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching Employee data:", error);
    //   });
  };

  const getDeliveryData = async (data: any, params: any) => {
    setLoading(true);
    await getDeliveryReport(params, data)
      .then((res) => {
        setEmployeesData(res.data);
        console.log(res, 'data')
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
        setLoading(false);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    getPayload(data);

    const params = {
      page: currentPage,
      size: employeesPerPage,
    };
    getDeliveryData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const data: any = {};

    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmpId.length > 0) {
      data["subordinate_ids"] = selectedEmpId;
    }
    if (territoryId.length > 0) {
      data["territory_ids"] = territoryId;
    }
    if (routeId.length > 0) {
      data["route_ids"] = routeId;
    }
    data["endpoint"] = "visit-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));

    getGlobalFilters(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmpId.length > 0) {
      data["subordinate_ids"] = selectedEmpId;
    }
    if (territoryId.length > 0) {
      data["territory_ids"] = territoryId;
    }
    if (routeId.length > 0) {
      data["route_ids"] = routeId;
    }
    data["endpoint"] = "visit-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));
    console.log("Filters Updated:", data);
    getGlobalFilters(data);
  };

  const updateSearchTerm = useCallback(
    debounce((value: string) => {
      const data: any = {
        start_date: formatCurrentStartDate(new Date(startDate)),
        end_date: formatCurrentEndDate(new Date(endDate)),
      };

      if (value) {
        data["search"] = value;
      }
      getPayload(data);
      const params = {
        page: 1,
        size: employeesPerPage,
      };
      setCurrentPage(1);
      getDeliveryData(data, params);
    }, 500),
    [
      startDate,
      endDate,
      selectedEmpStatus,
      channelId,
      selectedRegion,
      routeId,
      selectedSalesOffice,
      selectedEmpId,
      territoryId,
      userType,
      sortType,
      employeesPerPage,
    ]
  );

  const handleApply = (sort: string) => {
    setCurrentPage(1);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    if (searchQuery) {
      data["search"] = searchQuery;
    }
    getPayload(data);
    if (sort) {
      data["sort_by"] = sort;
    }
    const params = {
      page: 1,
      size: employeesPerPage,
    };

    getDeliveryData(data, params);
    handleClose();
  };

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  useEffect(() => {
    setSelectedSalesOffice("");
    setTerritoryId("");
    setRouteId("");
    setSelectedEmpId("");
  }, [selectedRegion]);

  useEffect(() => {
    setTerritoryId("");
    setRouteId("");
    setSelectedEmpId("");
  }, [selectedSalesOffice]);

  useEffect(() => {
    setRouteId("");
    setChannelId("");
    setSelectedEmpId("");
  }, [territoryId]);

  useEffect(() => {
    if (selectedEmpId) {
      globalFilters?.employees?.map((value: any) => {
        if (selectedEmpId === value.id) {
          setUserType(value.position);
        }
      });
    } else {
      setUserType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmpId]);

  return (
    <DashboardPage>
      <div className="bg-gray-50  sm:pl-0 pr-4 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={employees?.total}
              itemsPerPage={employees?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* Add your search icon here */}
                <img src={Search} alt="search" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t("planogram.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-16 border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>

            {/* Buttons group for desktop */}
            <div className="hidden sm:flex space-x-4 items-center">
              {/* <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-16 text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button> */}
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${sortType === "ASC" && "bg-lightgrey"
                      }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("planogram.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${sortType === "DESC" && "bg-lightgrey"
                      }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("planogram.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center gap-2 px-4 py-2 text-13 font-normal rounded transition-all"
              >
                <img
                  src={Filter}
                  alt={t("planogram.filter")}
                  className="w-4 h-4 mr-2"
                />
                {t("planogram.filter")}
              </button>

              <button
                onClick={handleExport}
                className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center gap-2">
                    <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                    {t("masterList.exporting")}
                  </span>
                ) : (
                  <>
                    {t("masterList.export")}
                    <img
                      src={Export}
                      alt={t("masterList.export")}
                      className="w-4 h-4 "
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleChange(e.target.value)}
              placeholder={t("planogram.searchPlaceholder")}
              className="w-full px-4 py-2 text-13 border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          {/* Hamburger menu for Sort, Filter, Export */}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center gap-2">
                  <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                  {t("masterList.exporting")}
                </span>
              ) : (
                <>
                  {t("masterList.export")}
                  <img
                    src={Export}
                    alt={t("masterList.export")}
                    className="w-4 h-4 "
                  />
                </>
              )}
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="relative bg-white shadow-lg w-full max-w-md h-full">
              <div className="absolute z-30 top-[50%] left-[45%]">
                {loadingFilter && <Loader />}
              </div>
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("visitreports.filters.title")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 overflow-y-auto scrollbar scrollbar-thin">
                <div className="flex flex-col gap-[30px]">
                  {/* <div className="flex space-x-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.region")}
                        options={globalFilters?.regions.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValues={selectedRegion}
                        onClose={handleFiltersUpdate}
                        onChange={setRegion}
                        placeholder={t("visitreports.filters.selectRegion")}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.salesOffice")}
                        options={
                          globalFilters?.sales_offices.map((sales: any) => ({
                            value: sales.id,
                            label: sales.name,
                          })) || []
                        }
                        selectedValues={selectedSalesOffice}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedSalesOffice}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.territoryID")}
                        options={
                          globalFilters?.territories?.map((emp: any) => ({
                            value: String(emp.territory_id),
                            label: emp.territory_identifier,
                          })) || []
                        }
                        selectedValues={territoryId}
                        onChange={setTerritoryId}
                        onClose={handleFiltersUpdate}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectTerritoryID"
                        )}
                      />
                    </div>

                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.channelID")}
                        options={
                          globalFilters?.channels?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValues={channelId}
                        onClose={handleFiltersUpdate}
                        onChange={setChannelId}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectChannelID")}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <DropdownMulti
                        label={t("visitreports.filters.routeID")}
                        options={
                          globalFilters?.routes?.map((emp: any) => ({
                            value: `${emp.route_id}`,
                            label: emp.route_identifier,
                          })) || []
                        }
                        selectedValues={routeId}
                        onClose={handleFiltersUpdate}
                        onChange={setRouteId}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectRouteID")}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("ActivityReport.employeeName")}
                        options={
                          globalFilters?.employees.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={selectedEmpId}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedEmpId}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("ActivityReport.employeeName")}
                        searchable={true}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("visitreports.filters.userType")}
                        options={
                          globalFilters?.user_types.map((emp: any) => ({
                            value: emp,
                            label: emp,
                          })) || []
                        }
                        selectedValue={userType}
                        onChange={setUserType}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectUserType")}
                      />
                    </div>
                  </div> */}

                  {/* Creation Date */}
                  <div className="date-range-picker space-y-2">
                    <div className="flex space-x-4 justify-between w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("visitreports.filters.startDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          onChange={handleStartDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                          max={new Date(endDate).toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("visitreports.filters.endDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          max={new Date().toISOString().split("T")[0]}
                          min={new Date(startDate).toISOString().split("T")[0]}
                          value={endDate}
                          onChange={handleEndDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-between mt-4">
                  <Button
                    onClick={handleClearFilters}
                    label={t("visitreports.filters.clearFilter")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("visitreports.filters.applyFilter")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-200px)] md:h-[calc(100vh-125px)] overflow-auto page-container p-2 pt-0 scrollbar scrollbar-thin">
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.srNo")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.salesOffice")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.salesOrder")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.customerId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.customerName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.customerAddress")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.Actual_Route_Area/Street")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.salesChannel")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.orderType")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.deliveryExecutiveId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.deliveryExecutiveName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.deliveryExecutivePhone")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.vanLocation")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.omsReference")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.ccAgentId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.ccAgentName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.omsOrderCreationDate")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.omsOrderCreationTime")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.sapOrderCreationDate")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.sapOrderCreationTime")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.slotId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.slotText")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.slotBegin")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.slotUntil")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.segment")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.berain48x200MLCarton")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.berain30x330MLCarton")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.berain24x600MLCarton")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_40X330ML_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X200ML_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X1_5LTR_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_2X12LTR_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_4X3_5LTR_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_4X5LTR_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_20X200ML_SHRINK_PACK")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.berain12x330mlShrinkPack")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X600ML_SHRINK_PACK")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_6X1_5LTR_SHRINK_PACK")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X200ML_SHRINK_PACK")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X300ML_BOTTLED_GLASS_WATER")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X750ML_BOTTLED_GLASS_WATER")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X300ML_BOTTLED_GLASS_CARBONATED")}
                </th>
             
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X750ML_BOTTLED_GLASS_CARBONATED")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_48X200ML_CARTON_SPECIAL")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_40X330ML_CARTON_SPECIAL")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X330ML_SHRINK_PACK_SPECIAL")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_20X200ML_SHRINK_PACK_SPECIAL")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X200ML_GLASS_BOTTLE_NORMAL_WATER")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X200ML_GLASS_BOTTLE_CARBONATED")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_CARTON_48X200ML_L_SODIUM")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_20X200ML_SHRINK_LOW_SODIUM")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_CARTON_40X330ML_L_SODIUM")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_12X330ML_SHRINK_LOW_SODIUM")}
                </th>
                {/* adding */}
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_28X600ML_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_15X500ML_SHRINK_PACK")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X330ML_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_28X500ML_CARTON")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X200ML_GLASS_STILL_WATER")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X200ML_GLASS_CARBONATED")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X300ML_GLASS_STILL_WATER")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_24X300ML_GLASS_CARBONATED")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_200ML_X20_PROMO_SHRINK_16_PLUS_4_FREE")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("deliveryReport.BERAIN_330ML_X12_PROMO_SHRINK_10_PLUS_2_FREE")}
                </th>
                {/* adding */}
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain1x5LiterPromoShrink")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain500mlx15PromoShrink")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.multipack300ml2x3BotW4PacNormal")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.multipack300ml2x3BotW4PacSparkl")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.posPayReference")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain12x330mlNormalShrinkPack")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain12x750mlGlassBottleStillNew")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain12x750mlBottledGlassCarbonatedNew")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x750mlGlassCarbonated")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x750mlGlassStillWaterNew")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain12x330mlPlainShrink")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain12x330mlShrinkPack")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x330mlPlainShrink")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x270mlGlassCarbonatedExtra")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain40x330mlCartonBrown")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain48x200mlCartonBrown")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x270mlCarbExtraWithoutMultipack")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x500mlCarton")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x200mlShrinkPack")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.berain24x270mlCarbLemon")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.freeQuantity")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.freePlayingCards")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.totalQty")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.totalCartonQty")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.totalWeight")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.discountPercentage")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.discountAmount")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.marketingDiscountAmount")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.delvCharge")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.totalValue")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.actualRoute")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.invoiceNo")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.totalInvoicedQty")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.totalInvoicedCartonQty")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.paymentReference")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.paymentSourceCode")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.requestedDeliveryDate")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.requestedDeliveryTime")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.actualDeliveryDate")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.actualDeliveryTime")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.deliveryLeadTimeDays")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.deliveryLeadTimeHrs")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.orderStatus")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.attemptedDeliveryReasons")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.orderCreatedBy")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.orderCancelledDateSAP")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.orderCancelledTimeSAP")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.cancelledBy")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.agentFirstName")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.agentLastName")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.promotionCode")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.presellerId")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.presellerName")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.deliveredTruckCode")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.mosqueDeliveryArea")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.ddPaymentType")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.doam")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.isNewCustomer")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.paymentTerm")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.deliveryWithinSlot")}</th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">{t("deliveryReport.Rescheduled_Order")}</th>
              </tr>
            </thead>
            <tbody>
              {employees?.items?.length > 0 &&
                !loading &&
                employees.items.map((data: any, index: number) => (
                  <tr
                    key={data.emp_id}
                    className="text-16 cursor-pointer	hover:bg-gray-50"
                  >
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{startIndex + index + 1}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sales_office}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sale_order}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.customer_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.customer_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.customer_address_street_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.actual_route_area_street}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sales_channel}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.order_type}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivery_executive_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivery_executive_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivery_executive_phone}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.van_location}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.oms_reference}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.cc_agent_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.agent_first_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.oms_order_creation_date}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.oms_order_creation_time}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{formatDate(data.sap_order_creation_date)}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sap_order_creation_time}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.slot_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.slot_text}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.slot_begin}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.slot_until}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.segment}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_48x200ml_carton}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_30x330ml_carton}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x600ml_carton}</td> 



                    {/*  add here */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_40x330ml_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x200ml_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x1_5ltr_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_2x12_ltr_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_4x3_5ltr_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_4x5ltr_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_20x200ml_shrink_pack}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x330ml_normal_shrink_pack}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x600ml_shrink_pack}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_6x1_5ltr_shrink_pack}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x200ml_shrink_pack}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x750ml_bottled_glass_water}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x300ml_bottled_glass_water}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x300ml_bottled_glass_carbonated}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x750ml_bottled_glass_carbonated}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_48x200ml_carton_special}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_40x330ml_carton_special}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x330ml_shrink_pack_special}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_20x200ml_shrink_pack_special}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x200ml_glass_bottle_normal_water}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x200ml_glass_bottle_carbonated}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_carton_48x200ml_l_sodium_3}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_20x200ml_shrink_low_sodium}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_carton_40x330ml_l_sodium_3}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x330ml_shrink_low_sodium}</td> 
                    {/*  add here */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_28x600ml_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_15x500ml_shrink_pack}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x330ml_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_28x500ml_carton}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x200ml_glass_still_water}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x200ml_glass_carbonated}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x300ml_glass_still_water}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x300ml_glass_carbonated}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_200ml_x20_promo_shrink_16_4_free}</td> 
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_330ml_x12_promo_shrink_10_2_free}</td> 

                    {/*  add here */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_1_5liter_x6_promo_shrink_5_1_free}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_500ml_x15_promo_shrink_12_3_free}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.multipack_300ml_2x3bot_w_4pac_normal_5_1_free}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.multipack_300ml_2x3bot_w_4pac_sparkl_5_1_free}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.pos_pay_reference}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x330ml_shrink_pack}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x750ml_glass_bottle_still_new}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x750ml_bottled_glass_carbonated_new}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x270ml_glass_carbonated}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x270ml_glass_still_water_new}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_12x330ml_plain_shrink}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x330ml_shrink_pack}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x330ml_plain_shrink}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x270ml_glass_carbonated_extra}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_40x330ml_carton_brown}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_48x200ml_carton_brown}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x270ml_carb_extra_wo_multipack}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x500ml_carton}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x200ml_shrink_pack}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.berain_24x270ml_carb_lemon}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.free_quantity}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.free_playing_cards}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_qty}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_carton_qty}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_weight}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.discount_percent}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.discount_amount}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.marketing_discount_amount}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delv_charge}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_value}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.actual_route}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.invoice_no}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_invoiced_qty}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_invoiced_carton_qty}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.payment_reference}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.payment_source_code}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{formatDate(data.requested_delivery_date)}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.requested_delivery_time}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{formatDate(data.actual_delivery_date)}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.actual_delivery_time}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivery_lead_time_days}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivery_lead_time_hours}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.order_status}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivery_exception_reason_sap}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.order_created_by}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sap_order_cancelled_date}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sap_order_cancelled_time}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sap_order_cancelled_by}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.agent_first_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.agent_last_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.promotion_code}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.preseller_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.preseller_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delivered_truck_code}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.mosque_delivery_area}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.dd_payment_type}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.doam}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.is_new_customer}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.payment_term}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.delv_within_slot}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.rescheduled_order}</td>


                  </tr>
                ))}
            </tbody>
          </table>
          {employees?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("planogram.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={employees?.total}
            itemsPerPage={employees?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default DeliveryReport;
