import React, { useState, ReactNode, useEffect } from "react";
import Header from '../../../src/layout/header';
import Navbarzamzam from "./Navbarzamzam"; 
import "./layout.css";
import { currentLang } from "../../utility/index";
interface DashboardPageProps {
  children: ReactNode;
  className?: string;
}

export default function Layerzamzam({
  children,
  className,
}: DashboardPageProps) {
  const [open, setOpen] = useState(false);

  const openClose = () => {
    setOpen(!open);
  };

  const [isRTL, setIsRTL] = useState(false);

  const lang = currentLang();
  useEffect(() => {
    let isRtl = false;
    if(lang === "Arabic") isRtl = true 
    else isRtl = false;
    setIsRTL(isRtl);
  }, [lang]);

  return (
    <>
      <>
        <Header openClose={openClose} />
        <Navbarzamzam open={open} setOpen={setOpen} />
        <div
          className={`relative pt-[25px] h-[calc(100vh-48px)] flex-grow overflow-y-auto scrollbar scrollbar-thin pb-8 md:pb-0 ${
            isRTL ? "md:pr-[74px]" : "md:pl-[74px]"
          }`}
        >
          {children}
        </div>
      </>
    </>
  );
}
