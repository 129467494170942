import React,{useEffect, useState} from "react";
import { DeliveryData } from "../OrderHistory";
import print from "../assets/printer.svg";
import { useTranslation } from "react-i18next";
import getOrderHistory, { getPreSigned} from '../api/orderHistory'
import { currentLang } from "../../../utility/index";
interface DeliveryModalProps {
  data: DeliveryData | undefined;
  handleHideDeliveryModal: () => void;
  printDelivery: () => void;
}

const DeliveryModal: React.FC<DeliveryModalProps> = ({ data, handleHideDeliveryModal, printDelivery }) => {
  const [id, setId] = useState<string | undefined>(undefined);
  const [presignedUrl, setPresignedUrl] = useState<string | undefined>(undefined); 

  useEffect(() => {
    if (data) {
      
      const deliveryId = data.DeliveryNo; 
      setId(deliveryId); 
  
      if (deliveryId) {
        handleShowDeliveryModal(deliveryId); 
      }
    }
  }, [data]); 
  
  const handleShowDeliveryModal = async (id: any) => {
    try {
      id=810086321
      const response = await getPreSigned(id);
      if (response.status === 200) {
        console.log(response.data, "<<<<<<<<< Delivery Data");
        console.log('presigned_url',response.data.presigned_url);
        setPresignedUrl(response.data.presigned_url)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  
  const { t } = useTranslation();
  const formatDateTime = (isoString: string, type: string): string => {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    if (type === "date") {
      return `${day}/${month}/${year}`;
    } else {
      return `${day}/${month}/${year} - ${formattedHours}:${minutes} ${amPm}`;
    }
  };
  const [lang, setLang] = useState<string | null>(null);

  useEffect(()=>{
    const lang = currentLang();
    setLang(lang);
  },[])
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center p-4 z-50">
      <div className="relative bg-white rounded-lg shadow-xl w-full lg:w-[80%] p-6">
        <div className="flex justify-between items-center border-b pb-3">
          <h2 className="text-xl font-semibold text-gray-800">
            {t("orders.deliveryDetails")}
          </h2>
          <div className="flex space-x-3 mr-4">
            <button onClick={handleHideDeliveryModal} className="text-gray-500 hover:text-gray-700 text-xl absolute top-1 right-3">
              &times;
            </button>
            <button onClick={printDelivery} className="bg-[#1dbda1] text-white rounded-md px-4 py-2 text-sm hover:bg-[#17a488] focus:outline-none flex items-center">
              <img className="mr-2" src={print} alt="Print" /> {t("orders.print")}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700 mt-4">
          <p>
            <span className="font-medium">{t("orders.shipmentNo")}:</span>{" "}
            {data?.ShipmentNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.deliveryNo")}:</span>{" "}
            {data?.DeliveryNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.deliveryNo")}:</span>{" "}
            {data?.OrderNo || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.outletName")}:</span>{" "}
            {data?.OutletName || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.receiverName")}:</span>{" "}
            {data?.ReceiverName || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.sfaOrderID")}:</span>{" "}
            {data?.SFAOrderID || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.Estimate_Date")}:</span>{" "}
            {data?.estimated_delivery_datetime ? formatDateTime(data.estimated_delivery_datetime, "date&time") : "N/A"}
          </p>
          <p className="col-span-2">
            <span className="font-medium">{t("orders.address")}:</span>{" "}
            {data?.Address || "N/A"}
          </p>
          <p>
            <span className="font-medium">{t("orders.customerID")}:</span>{" "}
            {data?.Customer || "N/A"}
          </p>
          <p className="col-span-2">
  <span className="font-medium">{t("dropdown.image")}</span>{" "}
  {presignedUrl ? (
  <a
    href={presignedUrl}
    target="_blank"
    rel="noopener noreferrer"
    download
    className="text-blue-600 hover:underline"
  >
    Click to open / download image
  </a>
) : (
  "No image available"
)}

</p>

        </div>

        <div className="mt-4 border-t pt-2">
          <h3 className="text-lg font-semibold text-gray-800">{t("orders.items")}</h3>
          {data?.Items && data?.Items?.length > 0 ? (
            <div className="overflow-x-auto">
              <table className={`w-full mt-2 border-collapse text-sm min-w-max ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                <thead>
                  <tr className="border-b bg-gray-100">
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.deliveryNo")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.itemCode")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.materialCode")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.materialEN")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.materialAR")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.quantity")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.remainingOrderQuantity")}
                    </th>
                    <th className={`text-left font-medium text-gray-900 p-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>
                    {t("orders.uom")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.Items.map((item, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">{item.DeliveryNo || "N/A"}</td>
                      <td className="p-2">{item.ItemCode || "N/A"}</td>
                      <td className="p-2">{item.MaterialCode || "N/A"}</td>
                      <td className="p-2">{item.MaterialTextEn || "N/A"}</td>
                      <td className="p-2">{item.MaterialTextAr || "N/A"}</td>
                      <td className="p-2">{item.Quantity || "0"}</td>
                      <td className="p-2">{item.pending_quantity || "N/A"}</td>
                      <td className="p-2">{item.UoMText || "N/A"}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className={`text-gray-500 mt-2 ${lang === 'Arabic' ? 'text-right' : 'text-left'}`}>{lang === 'Arabic' ? 'لا يوجد أصناف متاحة' : 'No items available.'}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryModal;
