import React, { useState, useEffect } from "react";
import Layerzamzam from "./Layerzamzam";
import AddressTable from "./components/AddressTable";
import PaginationAndSearch from "./components/PaginationAndSearch";
import getAddressTable from "./api/manageAddressTable";
import { useTranslation } from "react-i18next";
import { useDebounce } from "./hooks";
import AddressPopup from "./components/AddressPopup";

const ManageAddr = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isFirstRender, setIFirstRender] = useState<Boolean>(true)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const debounce = useDebounce(search);
  const toggeleLoding = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [address, setAddressData] = useState<any>([]);
  const [totalPages, settotalPages] = useState<any>(0);
  const { t } = useTranslation();

  const getAddressData = async (param: any, data: any) => {
    setLoading(true);
    await getAddressTable(param, data)
      .then((res) => {
        setLoading(false);
        setAddressData(res.items);
        const totalPages = Math.ceil(res.total / res.size);
        settotalPages(totalPages);
      })
      .catch((error) => {
        console.error("Error fetching Address data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const param = {
      page_number: currentPage,
      page_size: 20,
    };
    const data: any = {};
    if (search) {
      data["search"] = search;
    }
    getAddressData(param, data);
  }, [currentPage]);

  const handleSearch = async() => {
    setCurrentPage(1);
    const param = {
      page_number: 1,
      page_size: 20,
    };
    const data: any = {};

    if (search) {
      data["search"] = search;
    }
    await getAddressData(param, data);
  };

    useEffect(() => {
      if(isFirstRender){
        setIFirstRender(false);
        return;
      }
      const fetchData = async () => {
        await handleSearch();
      };
    
      fetchData();
    }, [debounce]);
  
  return (
    <div>
      <Layerzamzam>
        <p className="block text-lg font-bold text-gray-700">{t("manage.manageAddress")}</p>
        <PaginationAndSearch
          toggeleLoding={toggeleLoding}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSearch={setSearch}
          search={search}
          totalPages={totalPages}
          handleSearch={handleSearch}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
        <AddressTable
          loading={loading}
          setLoading={setLoading}
          currentPage={currentPage}
          address={address}
          getAddressData={getAddressData}
        />
        {isPopupOpen && <AddressPopup isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen} />}
      </Layerzamzam>
    </div>
  );
};

export default ManageAddr;
