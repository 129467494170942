import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";

const getGlobalFilter = async (data: any): Promise<any> => {
  const savedMode = localStorage.getItem("section");
  const mode= savedMode === 'My Team'? "SALES" :"DELIVERY"
  const response = await axios.post<any>(
    
    `/supervisors/global-filters`,data,
    {
      headers: getHeader(),
      params:{'team_type':mode}
    }
  );
  return response.data;
};

export { getGlobalFilter };
