import React, { useEffect, useState } from "react";
import logo from "../../../assets/main-logo.png";
import nwc from "../../../assets/nwc.jpg";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";

const metadata = {
  date: new Date(Date.now()).toLocaleString(),
  logoSrc: logo,
  logoSrcnwc: nwc,
  title: "Order Report",
};

interface Item {
  item_code: string | null;
  product_id: string;
  quantity: number;
}

interface Shipment {
  shipment_id: number;
  status: string;
  shimpment_date: string;
}

interface Invoice {
  invoice_id: number;
  invoice_details: string | null;
  invoice_path: string;
  invoice_date: string;
}

export interface ModalOrderData {
  sfa_order_id: string;
  status: string;
  customer_id: string;
  address_id: string;
  items: Item[];
  shipments: Shipment[];
  invoices: Invoice[];
}

interface ModalProps {
  data: ModalOrderData | undefined;
}

const ComponentToPrintModal: React.FC<ModalProps> = ({ data }) => {
  const month = new Date();
  const year = new Date();
  const [isArabic, setIsArabic] = useState(false);
  const { t } = useTranslation();

  function convertToMonth(monthString: number | null): string {
    if (!monthString) {
      return "Invalid month";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = monthString - 1;
    if (monthIndex >= 0 && monthIndex < 12) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }

  useEffect(() => {
    const lang = currentLang();
    if (lang === "Arabic") setIsArabic(true);
    else setIsArabic(false);
  }, []);

  return (
    <div id="print-content" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {metadata?.date || "-"}</p>
          {metadata?.logoSrc && (
            <div className="flex item center space-x-2">
              <img src={metadata.logoSrc} alt="Logo" className="h-16" />
              <img
                src={metadata.logoSrcnwc}
                alt="NWC Logo"
                className="h-16 self-start"
              />
            </div>
          )}
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">
            {metadata?.title || "Dynamic Report"}
          </h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>{t("finance.month")} : </b>
            {convertToMonth(month.getMonth() + 1)}
          </p>
          <p className="text-sm">
            <b>{t("finance.year")}: </b>
            {year.getFullYear()}
          </p>
        </div>
      </div>
      <div className="mt-4 text-sm flex justify-between">
        <div className="flex flex-col">
          <p>
            <strong>{t("orders.orderID")}:</strong> {data?.sfa_order_id}
          </p>
          <p>
            <strong>{t("orders.status")}:</strong> {data?.status}
          </p>
        </div>
        <div className="flex flex-col text-right">
          <p>
            <strong>{t("orders.customerID")}:</strong> {data?.customer_id}
          </p>
          <p>
            <strong>{t("orders.addressID")}:</strong> {data?.address_id}
          </p>
        </div>
      </div>

      <div className="mt-6">
        <h3 className="text-md font-semibold border-b pb-2">
          {t("orders.invoice")}
        </h3>
        {data && data?.invoices?.length > 0 ? (
          <table className="min-w-full mt-2 text-sm table-auto">
            <thead>
              <tr className="border-b">
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("invoiceReport.invoiceId")}
                </th>
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.invoiceDate")}
                </th>
                {/* <th className="py-2 px-4 text-left">Download</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.invoices.map((invoice) => (
                <tr key={invoice.invoice_id} className="border-b">
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {invoice.invoice_id}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {new Date(invoice.invoice_date).toLocaleDateString()}
                  </td>
                  {/* <td className="py-2 px-4">
                    <a
                      href={invoice.invoice_path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      Download
                    </a>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500 mt-2">No invoices available.</p>
        )}
      </div>

      <div className="mt-6">
        <h3 className="text-md font-semibold border-b pb-2">
          {t("orders.shipments")}
        </h3>
        {data && data?.shipments?.length > 0 ? (
          <table className="min-w-full mt-2 text-sm table-auto">
            <thead>
              <tr className="border-b">
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.shipments")} #
                </th>
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.status")}
                </th>
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.date")}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.shipments.map((shipment) => (
                <tr key={shipment.shipment_id} className="border-b">
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {shipment.shipment_id}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {shipment.status}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {new Date(shipment.shimpment_date).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500 mt-2">No shipments available.</p>
        )}
      </div>

      <div className="mt-6">
        <h3 className="text-md font-semibold border-b pb-2">
          {t("orders.items")}
        </h3>
        {data && data?.items?.length > 0 ? (
          <table className="min-w-full mt-2 text-sm table-auto">
            <thead>
              <tr className="border-b">
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.productID")}
                </th>
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.itemCode")}
                </th>
                <th
                  className={`py-2 px-4 text-left ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {t("orders.quantity")}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.items.map((item, index) => (
                <tr key={index} className="border-b">
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {item.product_id}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {item.item_code || "N/A"}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      isArabic ? "text-right" : "text-left"
                    }`}
                  >
                    {item.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-gray-500 mt-2">No items available.</p>
        )}
      </div>
    </div>
  );
};

export default ComponentToPrintModal;
