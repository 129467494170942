import React from "react";
import { useTranslation } from "react-i18next";

interface ReturnCancelSelectorProps {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
}

const ReturnCancelSelector: React.FC<ReturnCancelSelectorProps> = ({
  selectedOption,
  setSelectedOption,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-1">
        <label className="block text-10 text-gray-700">
          {t("returncancelselector.label")}
        </label>
        <div className="flex gap-2 justify-between border-gray-400 border rounded px-2 py-2 bg-[#F3E6F4]">
          <button
            className={`px-4 text-16 py-2 rounded transition duration-200 hover:bg-gray-300 ${
              selectedOption === "Return Delivery to Driver"
                ? "bg-white text-purple shadow"
                : "text-black"
            }`}
            onClick={() => setSelectedOption("Return Delivery to Driver")}
          >
            {t("returncancelselector.returndelivery")}
          </button>

          <button
            className={`px-4 py-2 text-16 rounded transition duration-200 hover:bg-gray-300 ${
              selectedOption === "Cancel Order"
                ? "bg-white text-purple shadow"
                : "text-black"
            }`}
            onClick={() => setSelectedOption("Cancel Order")}
          >
            {t("returncancelselector.cancelorder")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ReturnCancelSelector;
