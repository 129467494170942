import React, { useEffect, useState } from "react";
import icon from "../assets/new.png";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { currentLang } from "../../../utility/index";
interface ViewInventoryProps {
  showSummesion: boolean;
  setShowSummesion: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  warehouseData: any;
  salesQuantities: any;
  setSalesQuantities: any;
  handlePlaceOrder: () => void;
  summesionData: any;
}

const FetchedData: React.FC<ViewInventoryProps> = ({
  showSummesion,
  setShowSummesion,
  loading,
  warehouseData,
  salesQuantities,
  setSalesQuantities,
  handlePlaceOrder,
  summesionData
}) => {
  
  const [sumData, setSumData] = useState({
    total_available_stocks: 0,
    total_sales_quantity: 0,
  });
  const [inputValues, setInputValues] = useState<{ [key: number]: string }>({});
  const { t } = useTranslation();
  const [language, setLanguage] = useState<string | null>(null);

  const getAllSubmissions = () => {
    setSumData({
      total_available_stocks: 0,
      total_sales_quantity: 0,
    });

    salesQuantities?.map((item: any) => {
      setSumData((prev) => ({
        ...prev,
        total_sales_quantity: prev.total_sales_quantity + item.quantity,
      }));
    });
    warehouseData.items?.map((item: any) => {
      setSumData((prev) => ({
        ...prev,
        total_available_stocks:
          prev.total_available_stocks + item.available_stocks,
      }));
    });
  };

  useEffect(() => {
      // getAllSubmissions();
  }, []);

  useEffect(() => {
    const lang = currentLang();
    setLanguage(lang);
  }, []);

  const handleInputChange = (
    id: number,
    value: string,
    availableStocks: any
  ) => {
    const parsedValue = Number(value);
    const avStock = Number(availableStocks);
    console.log(avStock, parsedValue);

    if (parsedValue > avStock) {
      if (language === "Arabic") {
        return toast.warn(
          `لا يمكن أن تكون كمية المبيعات أكبر من المخزون المتاح ${avStock}`
        );
      } else {
        return toast.warn(
          `Sales quantity cannot be greater than available stocks (${avStock})`
        );
      }
    }
    if (parsedValue < 0) {
      if (language === "Arabic") {
        return toast.warn("يجب ادخال كمية صالحة");
      } else {
        return toast.warn(
          "Sales quantity should be greater than or equal to 0"
        );
      }
    }
    setInputValues((prev) => ({ ...prev, [id]: value }));
    setSalesQuantities((prev: any) => {
      let updatedQuantities = [...prev];

      if (parsedValue === 0) {
        // If the quantity is 0, remove the object with the matching productId
        updatedQuantities = updatedQuantities.filter(
          (item) => item.productId !== id
        );
      } else {
        // If the quantity is not 0, update or add the object
        const index = updatedQuantities.findIndex(
          (item) => item.productId === id
        );

        if (index === -1) {
          // If the productId doesn't exist, add a new entry
          updatedQuantities.push({ productId: id, quantity: parsedValue });
        } else {
          // If it exists, update the quantity
          updatedQuantities[index].quantity = parsedValue;
        }
      }

      return updatedQuantities;
    });
  };

  const [lang, setLang] = useState(false);

  useEffect(() => {
    var arabic = localStorage.getItem("lang");
    console.log("arabic", arabic);

    if (arabic === "Arabic") {
      setLang(true);
    } else {
      setLang(false);
    }
  }, []);

  return (
    <div className="bg-white shadow-sm rounded-md overflow-y-auto">
        <div
          className="h-[56vh]"
          style={{
            WebkitOverflowScrolling: "touch",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <table className="min-w-full border-collapse overflow-x-scroll">
          <thead className="bg-[#E8F6FC] sticky top-1 z-1">
            <tr>
              <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
              >
                {t("createOrder.Product_ID")}
              </th>
              <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
              >
                {t("createOrder.Description")}
              </th>
              <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
              >
                {t("createOrder.Unit")}
              </th>
              <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
              >
                {t("createOrder.Available_Stocks")}
              </th>
                <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
                >
                <div className="flex items-center space-x-2">
                  <p className="text-xs">{t("createOrder.unitprice")}</p>
                  <img className="cursor-pointer" src={icon} alt="summesion" />
                </div>
                </th>
                <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
                >
                <div className="flex items-center space-x-2">
                  <p className="text-xs">{t("createOrder.vat")}</p>
                  <img className="cursor-pointer" src={icon} alt="summesion" />
                </div>
                </th>
              <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
              >
                <div className="flex items-center space-x-2">
                  <p className="text-xs"> {t("createOrder.Sales_Quantity")}</p>
                 
                </div>
              </th>
              <th
                className={`px-4 py-3 border ${
                  lang ? "rtl text-right" : "text-left"
                } text-xs font-medium text-black-500`}
              >
                <div className="flex items-center space-x-2">
                  <p className="text-xs">{t("createOrder.total_price")}</p>
                  <img className="cursor-pointer" src={icon} alt="summesion" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {warehouseData ? (
              warehouseData?.map((warehouse: any, index: number) => (
                <tr key={index} className="text-xs hover:bg-gray-50">
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {warehouse.MaterialId || "N/A"}
                  </td>
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {lang
                      ? warehouse.MaterialDescAr || "N/A"
                      : warehouse.MaterialDesc || "N/A"}
                  </td>
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {lang
                      ? warehouse.UoMTextAr || "N/A"
                      : warehouse.UnitText || "N/A"}
                  </td>
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {warehouse.AvailableStock
                      ? parseFloat(warehouse.AvailableStock).toLocaleString()
                      : "-"}
                  </td>
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {warehouse.UnitPrice
                      ? parseFloat(warehouse.UnitPrice).toLocaleString()
                      : "-"}
                  </td>
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {warehouse.PriceWithVAT
                      ? parseFloat(warehouse.PriceWithVAT).toLocaleString()
                      : "-"}
                  </td>

                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    <input
                      type="number"
                      value={inputValues[warehouse.MaterialId] || ""}
                      onChange={(e) =>
                        handleInputChange(
                          warehouse.MaterialId,
                          e.target.value,
                          warehouse.AvailableStock
                        )
                      }
                      className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={t("orders.qty")}
                    />
                  </td>
                  <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {/* VAT      {warehouse.AvailableStock
                      ? parseFloat(warehouse.AvailableStock).toLocaleString()
                      : "-"} */}
                    {warehouse.PriceWithVAT && inputValues[warehouse.MaterialId]
                      ? (
                          parseFloat(warehouse.PriceWithVAT) *
                          parseFloat(inputValues[warehouse.MaterialId])
                        ).toLocaleString()
                      : "-"}
                  </td>
                </tr>
              ))
            ) : loading ? (
              <tr>
                <td colSpan={9} className="h-40 text-center align-middle">
                  <div className="flex justify-center items-center h-full">
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={9} className="text-center py-4 text-xs">
                  No Record Avaliable
                </td>
              </tr>
            )}
          </tbody>
            <thead className="bg-[#005879] sticky bottom-0 z-1 text-gray-100">
              <tr>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                  {t("createOrder.Total")}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold"></th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                    {summesionData?.TotalAvailableStock
                    ? parseFloat(summesionData.TotalAvailableStock).toLocaleString()
                    : "0"}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
                {summesionData?.TotalUnitPrice
                    ? parseFloat(summesionData.TotalUnitPrice).toLocaleString()
                    : "0"}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold"> {summesionData?.TotalPriceWithVAT
                    ? parseFloat(summesionData.TotalPriceWithVAT).toLocaleString()
                    : "0"}</th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
          {Object.values(inputValues).reduce((sum, value) => sum + (parseFloat(value) || 0), 0).toLocaleString()}
                </th>
                <th className="px-4 py-3 border text-left text-xs font-bold">
        {warehouseData?.reduce((total: number, warehouse: any) => {
          const quantity = parseFloat(inputValues[warehouse.MaterialId]) || 0;
          const priceWithVAT = parseFloat(warehouse.PriceWithVAT) || 0;
          return total + quantity * priceWithVAT;
        }, 0).toLocaleString()}
                </th>
              </tr>
            </thead>
        </table>
      </div>
      <button
        onClick={handlePlaceOrder}
        className={`absolute ${
          language === "Arabic" ? "left-4" : "right-4"
        } bottom-6  bg-[#005879] text-gray-100 border rounded-md p-2 text-sm w-24 mt-5`}
      >
       {t("createOrder.placeorder")}
      </button>
    </div>
  );
};

export default FetchedData;
