import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Map, Marker, APIProvider } from "@vis.gl/react-google-maps";
import { addAddress } from "../api/editAddress";

interface AddressPopupProps {
  isPopupOpen: boolean;
  setIsPopupOpen: (isOpen: boolean) => void;
}

const AddressPopup: React.FC<AddressPopupProps> = ({ isPopupOpen, setIsPopupOpen }) => {
  const [addTitle, setAddressTitle] = useState<any>();
  const [buildNo, setBuildNo] = useState<any>();
  const [city, setCity] = useState<any>();
  const [distict, setDistrict] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [lat, setLat] = useState<any>();
  const [lng, setLng] = useState<any>();
  const [mobile, setMobile] = useState<any>();
  const [name, setName] = useState<any>();
  const [postalCode, setPostalCode] = useState<any>();
  const [street, setStreet] = useState<any>();
  const [lang, setLang] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError]= useState("");
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const saudiCenter = { lat: 24.774265, lng: 46.738586 };
  const saudiBounds = {
    north: 33.0,
    south: 16.0,
    east: 60.0,
    west: 34.0,
  };
  const { t } = useTranslation();

  const handleMapClick = (e: any) => {
    const geocoder = new google.maps.Geocoder();
    const lat = e.detail.latLng.lat;
    const lng = e.detail.latLng.lng;
    setLng(lng);
    setLat(lat);
    setMarkerPosition({ lat, lng });
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results && results?.length > 0) {
        const result = results[0];
        const addressComponents = result.address_components;

        const getComponent = (type: string) =>
          addressComponents.find((component) => component.types.includes(type))
            ?.long_name;

        const address = result.formatted_address;
        const city = getComponent("locality");
        const postalCode = getComponent("postal_code");
        const district = getComponent("administrative_area_level_2");
        const street = getComponent("route");

        setCity(city);
        setPostalCode(postalCode);
        setDistrict(district);
        setStreet(street);
      } else {
        console.error("Geocode failed due to: " + status);
      }
    });
  };

  const handleSaveAddress = async () => {
    if (!lat || !lng) {
      setLat(saudiCenter.lat);
      setLng(saudiCenter.lng);
    }

    setShowModal(true);
  };

  const handleContinue = async () => {
    try {
      const data = {
        address_title: addTitle,
        building_no: buildNo,
        city: city,
        district: distict,
        email: email,
        latitude: String(lat),
        longitude: String(lng),
        mobile: mobile,
        name: name,
        postal_code: postalCode,
        street: street,
        shipment_point:"1020"
      };
      const response = await addAddress(data);
      if(lang){
        toast.success("تم إضافة العنوان بنجاح");
      }else{
        toast.success("Address Added Successfully");
      }
      setTimeout(() => {
        setShowModal(false);
        setIsPopupOpen(false);
      }, 2000);
    } catch (error: any) {
      console.error("Error saving address:", error.response.data.detail);
      setError("Failed to save address. Please try again.");

      if (error.response) {
            if (error.response.status === 500) {
              toast.error(
                lang
                  ? "حدث خطأ في الخادم، يرجى المحاولة لاحقًا"
                  : "Server error occurred, please try again later"
              );
            } else {
              toast.error(
                lang
                  ? "فشل إنشاء العنوان، يرجى المحاولة مرة أخرى"
                  : "Address Creation failed, please try again"
              );
            }
          } else {
            toast.error(
              lang
                ? "تعذر الاتصال بالخادم"
                : "Unable to connect to the server"
            );
          }
    }
  };

  useEffect(() => {
    var arabic = localStorage.getItem("lang");
    console.log("arabic", arabic);

    if (arabic === "Arabic") {
      setLang(true);
    } else {
      setLang(false);
    }
  }, []);
  
  return (
    <>
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-auto">
      <div className="bg-white rounded-lg p-6 w-[95%] h-[90%] max-w-7xl relative flex flex-col overflow-auto">
        <button
          onClick={() => setIsPopupOpen(false)}
          className="absolute top-1 right-4 text-gray-500 hover:text-gray-700 text-xl"
        >
          &times;
        </button>

        <h2 className="text-lg font-semibold mb-4">
          {t("createOrder.Add_New_Address")}
        </h2>
        <div className="flex flex-grow gap-4 flex-col md:flex-row overflow-auto">
          <div className="w-full md:w-1/2 h-64 md:h-full border bg-gray-100 rounded-md">
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}>
              <Map
                style={{ width: "100%", height: "100%" }}
                defaultCenter={saudiCenter}
                defaultZoom={5}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                minZoom={5}
                maxZoom={20}
                zoomControl={true}
                restriction={{
                  latLngBounds: saudiBounds,
                  strictBounds: true,
                }}
                onClick={handleMapClick}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </Map>
            </APIProvider>
          </div>
          <div className="w-full p-2 md:w-1/2 lg:pl-6 h-[37%] lg:h-full overflow-y-auto mt-4 md:mt-0">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveAddress();
              }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Building_Number")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={buildNo}
                    onChange={(e) => setBuildNo(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Street")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.City")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.District")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={distict}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Postal_Code")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    pattern="^\d{5,6}$"
                    value={postalCode}
                    onChange={(e) =>
                      setPostalCode(
                        e.target.value.replace(/\D/g, "").slice(0, 6)
                      )
                    }
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Address_Title")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={addTitle}
                    onChange={(e) => setAddressTitle(e.target.value)}
                  />
                </div>
                <div className="md:col-span-2">
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Receiver_Name")}
                  </label>
                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Receiver_Mobile")}
                  </label>
                  <div className="flex items-center">
                  {!lang && <span className="border border-gray-300 bg-gray-100 px-3 py-2 text-sm rounded-l-md">
                        +966
                      </span>}
                    <input
                      type="tel"
                      className="w-full border border-gray-300 rounded-r-md py-2 px-3 text-sm"
                      required
                      pattern="^\d{9}$"
                      placeholder={t("createOrder.Enter_9_digit")}
                      value={mobile}
                      onChange={(e) =>
                        setMobile(e.target.value.replace(/\D/g, "").slice(0, 9))
                      }
                      onBlur={(e) =>
                        e.target.value.length !== 9 &&
                        toast.error("Mobile number must be 9 digits")
                      }
                    />
                    {lang && <span className="border border-gray-300 bg-gray-100 px-3 py-2 text-sm rounded-l-md">
                        966+
                      </span>}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-700">
                    {t("createOrder.Receiver_Email_ID")}
                  </label>
                  <input
                    type="email"
                    className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div
                className={`absolute bottom-6 ${lang ? "left-12" : "right-6"}`}
              >
                <button
                  className="bg-[#1dbda1] text-white text-sm font-medium px-6 py-2 rounded-md shadow mt-4"
                  type="submit"
                >
                  {t("createOrder.Save_Address")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-6">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl p-6 relative overflow-auto">
            <div className="p-4">
              <div className="text-lg font-semibold">{t("createOrder.New_Address_Created")}</div>
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                onClick={() => setShowModal(false)}
              >
                ✕
              </button>
              <div className="flex flex-col md:flex-row bg-[#F5F5F5] p-6 gap-6">
                {/* Left Section: Address Details */}
                <div className="w-full md:w-1/2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm">{t("createOrder.Address_Title")}</p>
                      <p className="tracking-wide font-semibold">{addTitle}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.Building_Number")}</p>
                      <p className="tracking-wide font-semibold">{buildNo}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.Street")}</p>
                      <p className="tracking-wide font-semibold">{street}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.City")}</p>
                      <p className="tracking-wide font-semibold">{city}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.District")}</p>
                      <p className="tracking-wide font-semibold">{distict}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.Postal_Code")}</p>
                      <p className="tracking-wide font-semibold">
                        {postalCode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block border-l border-gray-400"></div>
                <div className="w-full md:w-1/2">
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <p className="text-sm">{t("createOrder.Receiver_Name")}</p>
                      <p className="tracking-wide font-semibold">{name}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.Receiver_Mobile")}</p>
                      <p className="tracking-wide font-semibold">{mobile}</p>
                    </div>
                    <div>
                      <p className="text-sm">{t("createOrder.Receiver_Email_ID")}</p>
                      <p className="tracking-wide font-semibold">{email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-6">
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                  onClick={handleContinue}
                >
                {t("createOrder.Continue")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressPopup;
