/* eslint-disable array-callback-return */
import React, { useEffect, useState, useCallback } from "react";
import DashboardPage from "../../../layout";
import { getGlobalFilter } from "../../../api/filters";
import PaginationComponent from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import Export from "../../../assets/icons/Export.svg";
import Filter from "../../../assets/icons/Filter.svg";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getPagePerSize,
  formatDate,
} from "../../../utility";
import TimeFrameSelector from "../../../components/TimeFrameSelector";
import Dropdown from "../../../components/Dropdown";
import DropdownMulti from "../../../components/DropdownMultiple";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Close from "../../../assets/close-circle.svg";
import Search from "../../../assets/Search.svg";
import { useNavigate } from "react-router-dom";
import { formatNumber, TimeZoneDisplay } from "../../../utility";
import Button from "../../../components/Button";
import { getrejectedOrderReport} from "../../../api/delivery-driver";
import debounce from "lodash/debounce";
import Eye from "../../../assets/eye.svg";
import TimelinePopup from "./timelinepop";

const RejectionReport: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [employees, setEmployeesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingForFilter] = useState(false);
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortType, setSortType] = useState<any>("");
  const { t } = useTranslation();
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedEmpId, setSelectedEmpId] = useState<any>([]);
  const [selectedEmpStatus, setSelectedEmpStauts] = useState<any>("");
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>([]);
  const [selectedRegion, setRegion] = useState<any>([]);
  const [territoryId, setTerritoryId] = useState<any>([]);
  const [routeId, setRouteId] = useState<any>([]);
  const [userType, setUserType] = useState<any>("");
  const [channelId, setChannelId] = useState<any>([]);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [selectReason, setSelectReason] = useState<string | null>(null);
  const [selectDriverId, setSelectDriverId] = useState<string | null>(null);
  const startIndex = (currentPage - 1) * employees?.size;
  const navigate = useNavigate();
  const employeesPerPage = getPagePerSize();
 const [isPopupOpen, setIsPopupOpen] = useState(false);
  const getGlobalFilters = async (data: any) => {
    try {
      setLoadingForFilter(true);
      const response = await getGlobalFilter(data);
      setLoadingForFilter(false);
      setGlobalFilters(response);
    } catch (error) {
      console.error("Error fetching global filters:", error);
    }
  };

  const handleFilterClick = () => {
    setOpen(true);
  };

  const handleChange = (event: any) => {
    updateSearchTerm(event);
    setSearchQuery(event);
  };
  const handleOpenPopup = (data: any) => {
    setSelectedOrderId(data.order_id);
    setSelectReason(data.reason_for_rejection)
    setSelectDriverId(data.driver_id)
    setIsPopupOpen(true);
    console.log(selectReason)
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleSortFillter = (data: string) => {
    if (data === sortType) {
      setSortType("");
      handleApply("");
    } else {
      setSortType(data);
      handleApply(data);
    }

    setIsDropdownOpen(false);
  };
  
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleClose = () => setOpen(false);

  const handleClearFilters = () => {
    setSelectedEmpId([]);
    setSelectedSalesOffice([]);
    setRegion([]);
    setTerritoryId([]);
    setRouteId([]);
    setUserType(null);
    setChannelId([]);
    setSelectedEmpStauts(null);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setSelectTimeFrame("Today");
    setOpen(false);
    handleClearApply("");
  };

  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: 1,
      size: employeesPerPage,
    };
    setCurrentPage(1);
    getRejectionData(data, params);
    handleClose();
  };

  const getPayload = (data: any) => {
    if (selectedEmpStatus) {
      data["status"] = selectedEmpStatus;
    }

    if (channelId.length > 0) {
      data["channel_ids"] = channelId;
    }
    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (routeId.length > 0) {
      data["route_ids"] = routeId;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmpId.length > 0) {
      data["subordinate_ids"] = selectedEmpId;
    }
    if (territoryId.length > 0) {
      data["territory_ids"] = territoryId;
    }
    if (userType) {
      data["user_type"] = userType;
    }
  };

  const handleExport = async () => {
    setLoading(true);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
      time_zone: TimeZoneDisplay(),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }
    getPayload(data);

    const params = {};
    try {
      await exportActiviyData(data, params);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportActiviyData = async (data: any, params: any) => {
    // await visitSummryReportList(params, data)
    //   .then((res) => {
    //     const url = window.URL.createObjectURL(new Blob([res]));
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = `team_visit_report.csv`;
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching Employee data:", error);
    //   });
  };

  const getRejectionData = async (data: any, params: any) => {
    setLoading(true);
    await getrejectedOrderReport(params, data)
      .then((res) => {
        setEmployeesData(res.data);
        console.log(res, 'data')
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
        setLoading(false);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    getPayload(data);

    const params = {
      page: currentPage,
      size: employeesPerPage,
    };
    getRejectionData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const data: any = {};

    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmpId.length > 0) {
      data["subordinate_ids"] = selectedEmpId;
    }
    if (territoryId.length > 0) {
      data["territory_ids"] = territoryId;
    }
    if (routeId.length > 0) {
      data["route_ids"] = routeId;
    }
    data["endpoint"] = "visit-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));

    getGlobalFilters(data);
    console.log(selectedOrderId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmpId.length > 0) {
      data["subordinate_ids"] = selectedEmpId;
    }
    if (territoryId.length > 0) {
      data["territory_ids"] = territoryId;
    }
    if (routeId.length > 0) {
      data["route_ids"] = routeId;
    }
    data["endpoint"] = "visit-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));
    console.log("Filters Updated:", data);
    getGlobalFilters(data);
  };

  const updateSearchTerm = useCallback(
    debounce((value: string) => {
      const data: any = {
        start_date: formatCurrentStartDate(new Date(startDate)),
        end_date: formatCurrentEndDate(new Date(endDate)),
      };

      if (value) {
        data["search"] = value;
      }
      getPayload(data);
      const params = {
        page: 1,
        size: employeesPerPage,
      };
      setCurrentPage(1);
      getRejectionData(data, params);
    }, 500),
    [
      startDate,
      endDate,
      selectedEmpStatus,
      channelId,
      selectedRegion,
      routeId,
      selectedSalesOffice,
      selectedEmpId,
      territoryId,
      userType,
      sortType,
      employeesPerPage,
    ]
  );

  const handleApply = (sort: string) => {
    setCurrentPage(1);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    if (searchQuery) {
      data["search"] = searchQuery;
    }
    getPayload(data);
    if (sort) {
      data["sort_by"] = sort;
    }
    const params = {
      page: 1,
      size: employeesPerPage,
    };

    getRejectionData(data, params);
    handleClose();
  };

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  useEffect(() => {
    setSelectedSalesOffice("");
    setTerritoryId("");
    setRouteId("");
    setSelectedEmpId("");
  }, [selectedRegion]);

  useEffect(() => {
    setTerritoryId("");
    setRouteId("");
    setSelectedEmpId("");
  }, [selectedSalesOffice]);

  useEffect(() => {
    setRouteId("");
    setChannelId("");
    setSelectedEmpId("");
  }, [territoryId]);

  useEffect(() => {
    if (selectedEmpId) {
      globalFilters?.employees?.map((value: any) => {
        if (selectedEmpId === value.id) {
          setUserType(value.position);
        }
      });
    } else {
      setUserType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmpId]);

  return (
    <DashboardPage>
      <div className="bg-gray-50  sm:pl-0 pr-4 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={employees?.total}
              itemsPerPage={employees?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* Add your search icon here */}
                <img src={Search} alt="search" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t("planogram.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-16 border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>

            {/* Buttons group for desktop */}
            <div className="hidden sm:flex space-x-4 items-center">
              {/* <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-16 text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button> */}
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${sortType === "ASC" && "bg-lightgrey"
                      }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("planogram.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${sortType === "DESC" && "bg-lightgrey"
                      }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("planogram.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center gap-2 px-4 py-2 text-13 font-normal rounded transition-all"
              >
                <img
                  src={Filter}
                  alt={t("planogram.filter")}
                  className="w-4 h-4 mr-2"
                />
                {t("planogram.filter")}
              </button>

              <button
                onClick={handleExport}
                className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center gap-2">
                    <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                    {t("masterList.exporting")}
                  </span>
                ) : (
                  <>
                    {t("masterList.export")}
                    <img
                      src={Export}
                      alt={t("masterList.export")}
                      className="w-4 h-4 "
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleChange(e.target.value)}
              placeholder={t("planogram.searchPlaceholder")}
              className="w-full px-4 py-2 text-13 border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          {/* Hamburger menu for Sort, Filter, Export */}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center gap-2">
                  <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                  {t("masterList.exporting")}
                </span>
              ) : (
                <>
                  {t("masterList.export")}
                  <img
                    src={Export}
                    alt={t("masterList.export")}
                    className="w-4 h-4 "
                  />
                </>
              )}
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="relative bg-white shadow-lg w-full max-w-md h-full">
              <div className="absolute z-30 top-[50%] left-[45%]">
                {loadingFilter && <Loader />}
              </div>
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("visitreports.filters.title")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 overflow-y-auto scrollbar scrollbar-thin">
                <div className="flex flex-col gap-[30px]">
                  {/* <div className="flex space-x-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.region")}
                        options={globalFilters?.regions.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValues={selectedRegion}
                        onClose={handleFiltersUpdate}
                        onChange={setRegion}
                        placeholder={t("visitreports.filters.selectRegion")}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.salesOffice")}
                        options={
                          globalFilters?.sales_offices.map((sales: any) => ({
                            value: sales.id,
                            label: sales.name,
                          })) || []
                        }
                        selectedValues={selectedSalesOffice}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedSalesOffice}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.territoryID")}
                        options={
                          globalFilters?.territories?.map((emp: any) => ({
                            value: String(emp.territory_id),
                            label: emp.territory_identifier,
                          })) || []
                        }
                        selectedValues={territoryId}
                        onChange={setTerritoryId}
                        onClose={handleFiltersUpdate}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectTerritoryID"
                        )}
                      />
                    </div>

                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.channelID")}
                        options={
                          globalFilters?.channels?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValues={channelId}
                        onClose={handleFiltersUpdate}
                        onChange={setChannelId}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectChannelID")}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <DropdownMulti
                        label={t("visitreports.filters.routeID")}
                        options={
                          globalFilters?.routes?.map((emp: any) => ({
                            value: `${emp.route_id}`,
                            label: emp.route_identifier,
                          })) || []
                        }
                        selectedValues={routeId}
                        onClose={handleFiltersUpdate}
                        onChange={setRouteId}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectRouteID")}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("ActivityReport.employeeName")}
                        options={
                          globalFilters?.employees.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={selectedEmpId}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedEmpId}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("ActivityReport.employeeName")}
                        searchable={true}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("visitreports.filters.userType")}
                        options={
                          globalFilters?.user_types.map((emp: any) => ({
                            value: emp,
                            label: emp,
                          })) || []
                        }
                        selectedValue={userType}
                        onChange={setUserType}
                        disabled={!selectedRegion || loadingFilter}
                        placeholder={t("visitreports.filters.selectUserType")}
                      />
                    </div>
                  </div> */}

                  {/* Creation Date */}
                  <div className="date-range-picker space-y-2">
                    <div className="flex space-x-4 justify-between w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("visitreports.filters.startDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          onChange={handleStartDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                          max={new Date(endDate).toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("visitreports.filters.endDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          max={new Date().toISOString().split("T")[0]}
                          min={new Date(startDate).toISOString().split("T")[0]}
                          value={endDate}
                          onChange={handleEndDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-between mt-4">
                  <Button
                    onClick={handleClearFilters}
                    label={t("visitreports.filters.clearFilter")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("visitreports.filters.applyFilter")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-200px)] md:h-[calc(100vh-125px)] overflow-auto page-container p-2 pt-0 scrollbar scrollbar-thin">
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.srNo")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.supervisorId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.employeeId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.orderId")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.customerName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.mobileNumber")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.orderCreationDate")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.rejectionDate")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.salesOffice")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.salesChannel")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.totalQuantity")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.reasonForRejection")}
                </th>
   
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.driverName")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.driverMobileNumber")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.feedback")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.returnDeliveryToDriver")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-normal w-24">
                  {t("rejectionReport.cancelOrder")}
                </th>
              </tr>
            </thead>
            <tbody>
              {employees?.items?.length > 0 &&
                !loading &&
                employees.items.map((data: any, index: number) => (
                  <tr
                    key={data.emp_id}
                    className="text-16 cursor-pointer	hover:bg-gray-50"
                  >
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{startIndex + index + 1}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.supervisor_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.emp_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.order_id}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.cyst_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.mobile_number}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{formatDate(data.order_creation_date)}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{formatDate(data.rejection_date)}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sales_office}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.sales_channel}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.total_qty}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.reason_for_rejection}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.driver_name}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.driver_mobile}</td>
                    <td className="right-0 bg-white px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap  flex items-center justify-center ">
                      <img
                        src={Eye}
                        onClick={() => {
                          handleOpenPopup(data);
                        }}
                        alt="Action Item"
                        className="h-5 w-5 sm:h-6 sm:w-6 cursor-pointer"
                      />
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.return_delivery_to_driver}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{data.cancel_order}</td>
                    





                  </tr>
                ))}
            </tbody>
          </table>
          {employees?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("planogram.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
          {isPopupOpen && (
            <TimelinePopup
            driverId={selectDriverId}
            reasonofcancellation={selectReason}
              deliveryNumber={selectedOrderId}
              onClose={handleClosePopup}
            />
          )}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={employees?.total}
            itemsPerPage={employees?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default RejectionReport;
