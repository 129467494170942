import { currentLang } from "../../../utility/index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type TableColumn = {
  header: string;
  key: string;
  formatter?: (value: any) => string;
};
console.log("s");
interface TableData {
  results: Array<{
    StorageLocName: string;
    StorageLocNameAr: string;
    NavStockToItem: Array<{
      MaterialId: string;
      MaterialDesc: string;
      AvailableStock: string;
      InQualityInspection: string;
      Blocked: string;
      Return: string;
      InTransit: string;
      Unit: string;
      UnitText: string;
      OpenQty: string;
      ShippedOrderQty : string;
      UoMTextAr: string;
      MaterialDescAr: string;
    }>;
    TotalAvailableStock: string;
    TotalInQualityInspection: string;
    TotalBlocked: string;
    TotalReturns: string;
    TotalInTransits: string;
    TotalOpenQty: string;
    TotalShippedOrderQty: string;
  }>;
}

type DynamicTableProps = {
  columns: TableColumn[];
  data: TableData | null;
  metadata?: {
    date?: string;
    logoSrc?: string;
    title?: string;
    logoSrcnwc?: string;
  };
};

const ComponentToPrint: React.FC<DynamicTableProps> = ({
  columns,
  data,
  metadata,
}) => {
  const mergedStock = data?.results.flatMap((location) =>
    location.NavStockToItem.map((item) => ({
      ...item,
      StorageLocation: location.StorageLocName,
      StorageLocName: location.StorageLocName,
      StorageLocNameAr: location.StorageLocNameAr,
    }))
  );
  const totalData = data?.results?.map((prev) => ({
    TotalAvailableStock: parseFloat(prev.TotalAvailableStock) || 0,
    TotalBlocked: parseFloat(prev.TotalBlocked) || 0,
    TotalInQualityInspection: parseFloat(prev.TotalInQualityInspection) || 0,
    TotalReturn: parseFloat(prev.TotalReturns) || 0,
    TotalShippedOrderQty: parseInt(prev.TotalShippedOrderQty) || 0,
    TotalOpenQty: parseInt(prev.TotalOpenQty) || 0,
    TotalInTransits: parseInt(prev.TotalInTransits) || 0,
  }));

  const aggregatedTotals = totalData?.reduce(
    (acc, curr) => {
      acc.TotalAvailableStock += curr.TotalAvailableStock;
      acc.TotalBlocked += curr.TotalBlocked;
      acc.TotalInQualityInspection += curr.TotalInQualityInspection;
      acc.TotalReturn += curr.TotalReturn;
      acc.TotalInTransits += curr.TotalInTransits;
      acc.TotalShippedOrderQty += curr.TotalShippedOrderQty;
      acc.TotalOpenQty += curr.TotalOpenQty;
      return acc;
    },
    {
      TotalAvailableStock: 0,
      TotalBlocked: 0,
      TotalInQualityInspection: 0,
      TotalReturn: 0,
      TotalInTransits: 0,
      TotalShippedOrderQty: 0,
      TotalOpenQty: 0,
    }
  );

  console.log("Aggregated Totals:", aggregatedTotals);
  console.log("Merged Stock:", mergedStock);
  const { t } = useTranslation();
  const [isArabic, setIsArabic] = useState(false);
  useEffect(() => {
    const lang = currentLang();
    if (lang === "Arabic") setIsArabic(true);
    else setIsArabic(false);
  }, []);
  return (
    <div id="print-content" className="hidden print:block text-xs">
      <div className="flex justify-between items-center mb-4">
        <div className="flex flex-col gap-1">
          <p className="text-xs">Date: {metadata?.date || "-"}</p>
          <div className="flex items-center space-x-2">
            <img src={metadata?.logoSrc} alt="Logo" className="h-12" />
            <img src={metadata?.logoSrcnwc} alt="NWC Logo" className="h-12" />
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-lg font-bold">
            {t(`inventory.${metadata?.title}`)}
          </h1>
        </div>
      </div>

      <div className="mb-6">
        {/* <h2 className="text-sm font-bold mb-2">
          {isArabic ? result.StorageLocNameAr : result.StorageLocName}
        </h2> */}
        <table className="w-full border-collapse border border-gray-300 text-xs">
          <thead className="bg-gray-100">
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  className={`border border-gray-300 p-1 ${
                    isArabic ? "text-right" : "text-left"
                  } whitespace-nowrap`}
                >
                  {t(`inventory.${col.key}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(mergedStock ?? []).length > 0 ? (
              mergedStock?.map((item, itemIndex) => (
                <tr key={itemIndex} className="hover:bg-gray-50">
                  <td className="px-2 py-1 border whitespace-nowrap">
                    {isArabic ? item.StorageLocNameAr : item.StorageLocName}
                  </td>
                  <td className="px-2 py-1 border">{item.MaterialId || "0"}</td>
                  <td className="px-2 py-1 border">
                    {isArabic ? item.MaterialDescAr : item.MaterialDesc || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {isArabic ? item.UoMTextAr : item.UnitText || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.AvailableStock.toLocaleString()) || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.InQualityInspection) || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.Blocked) || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.Return) || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.InTransit) || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.OpenQty) || "0"}
                  </td>
                  <td className="px-2 py-1 border">
                    {parseInt(item.ShippedOrderQty) || "0"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center py-2">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
          <thead className="bg-[#005879] text-black-100 text-xs">
            <tr>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {t("inventory.total")}
              </th>
              <th className="px-2 py-1 border"></th>
              <th className="px-2 py-1 border"></th>
              <th className="px-2 py-1 border"></th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalAvailableStock || 0}
              </th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalInQualityInspection || 0}
              </th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalBlocked || 0}
              </th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalReturn || 0}
              </th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalInTransits || 0}
              </th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalOpenQty}
              </th>
              <th
                className={`px-2 py-1 border ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                {aggregatedTotals?.TotalShippedOrderQty}
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div className="flex justify-between pt-16">
        <div className="w-1/2 text-center border-t border-gray-300 pt-2"></div>
        <div className="w-1/2 text-center border-t border-gray-300 pt-2"></div>
      </div>
    </div>
  );
};

export default ComponentToPrint;
