import React, { useState } from "react";
import searchIcon from "../../assets/search-icon.svg";
import { useTranslation } from "react-i18next";
import clockIn from "../../assets/clock-in.svg";
import IdleVehicle from "../../assets/idle-vehicle.svg";
import DrivingVehicle from "../../assets/driving-vehicle.svg";
import Loader from "../../components/Loader";
import moment from "moment";
import { getRoleAccess } from "../../utility";
interface VisitItemProps {
  visit: any;
  setRouteForSelectedCustomer: any;
  routeForSelectedCustomer: any;
}

const VisitItem: React.FC<VisitItemProps> = ({
  visit,
  setRouteForSelectedCustomer,
  routeForSelectedCustomer,
}) => {
  type Status = "SUCCESSFUL" | "PRODUCTIVE" | "FAILED";

  const getStatusComp = (status: Status): any => {
    const section = localStorage.getItem('section')

    if (section === 'Delivery_My_Team') {
      switch (status) {
        case "SUCCESSFUL":
          return (
            <div className="bg-cugreen w-max px-2 p-1 text-white rounded-sm text-8 w-max uppercase">
              {t('visit.deliverd')}
            </div>
          );
        case "PRODUCTIVE":
          return (
            <div className="bg-[#FFAA2A] w-max px-2 p-1 text-white rounded-sm text-8 w-max uppercase">
              {t('visit.attempted')}
            </div>
          );
        case "FAILED":
          return (
            <div className="bg-[#FF4D68] px-2 p-1 text-white rounded-sm text-8 w-max uppercase">
              {t('visit.rejected')}
            </div>
          );
      }
    } else {
      switch (status) {
        case "SUCCESSFUL":
          return (
            <div className="bg-cugreen w-max px-2 p-1 text-white rounded-sm text-8 w-max uppercase">
              {t("visit.successfull_visit")}
            </div>
          );
        case "PRODUCTIVE":
          return (
            <div className="bg-[#FFAA2A] w-max px-2 p-1 text-white rounded-sm text-8 w-max uppercase">
              {t("visit.collection_visit")}
            </div>
          );
        case "FAILED":
          return (
            <div className="bg-[#FF4D68] px-2 p-1 text-white rounded-sm text-8 w-max uppercase">
              {t("visit.failed_visit")}
            </div>
          );
      }
    }

  };
  const getColorForBorder = (status: Status): any => {
    switch (status) {
      case "SUCCESSFUL":
        return "border-cugreen border-4";
      case "PRODUCTIVE":
        return "border-[#FFAA2A] border-4";
      case "FAILED":
        return "border-[#FF4D68] border-4";
      default:
        return "border-black border-4";
    }
  };
  const { t } = useTranslation();
  const getColor = (status: Status): any => {
    switch (status) {
      case "SUCCESSFUL":
        return "bg-cugreen";
      case "PRODUCTIVE":
        return "bg-[#FFAA2A]";
      case "FAILED":
        return "bg-[#FF4D68]";
      default:
        return "bg-black";
    }
  };

  const handleCustomerSelection = (data: any) => {
    if (routeForSelectedCustomer.sequence === visit?.sequence) {
      setRouteForSelectedCustomer("");
    } else {
      setRouteForSelectedCustomer(data);
    }
  };
  return (
    <div
      className={`pl-4 pt-4 border-l-2 border-black relative ml-3`}
      onClick={() => handleCustomerSelection(visit)}
    >
      <div className="absolute left-[-6px] top-[20%] transform -translate-y-1/2 bg-black rounded-full w-3 h-3"></div>
      <div>
        {visit?.is_break_taken ? (
          <div
            className={`flex items-center justify-between p-2 cursor-pointer ${routeForSelectedCustomer.sequence === visit?.sequence
              ? getColorForBorder(visit?.outcome_status)
              : ""
              } border rounded-md shadow-sm bg-white`}
          >
            <div className="items-center space-x-1 w-full">
              <div className="flex gap-2 justify-between w-full">
                <div className="flex gap-2 w-full">
                  <div
                    className={`${getColor(
                      "FAILED"
                    )} rounded-full w-full max-w-[30px] h-[30px] text-white flex items-center justify-center`}
                  >
                    B
                  </div>
                  <div className="w-full">
                    <div className="font-bold text-16">
                      {t("visit.break_stop")}
                    </div>
                    <div className="font-medium text-13">
                      {visit.outlet_name_en}
                    </div>
                    <div className="font-medium text-13">
                      {t("visit.customer_id")}: {visit.outlet_id || "-"}
                    </div>
                    {getRoleAccess('Delivery') && <div className="font-medium text-13">
                      {t("visit.delivery_id")}: {visit.delivery_number || '-'}
                    </div>}
                    <div className="font-medium text-13">
                      {t("visit.time_taken")}: {visit.total_visit_time || "-"}
                    </div>
                  </div>
                </div>
                <div className="text-16 font-bold text-[#FF4D68]">
                  {visit.break_total_duration}
                </div>
              </div>
              <div className="border-t-2 flex gap-2 justify-between p-2 mt-2">
                <div className="text-13 font-medium">
                  {t("visit.break_start")} :{" "}
                  <b>
                    {visit.break_started_at
                      ? moment
                        .utc(visit.break_started_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
                <div className="text-13 font-medium">
                  {t("visit.break_end")} :{" "}
                  <b>
                    {visit.break_ended_at
                      ? moment
                        .utc(visit.break_ended_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
              </div>
            </div>
          </div>
        ) : visit?.is_customer_created ? (
          <div
            className={`flex items-center justify-between p-2 cursor-pointer ${routeForSelectedCustomer.sequence === visit?.sequence
              ? getColorForBorder(visit?.outcome_status)
              : ""
              } border rounded-md shadow-sm bg-white`}
          >
            <div className="items-center space-x-1 w-full">
              <div className="flex gap-2 justify-between w-full">
                <div className="flex gap-2 w-full">
                  <div
                    className={`${getColor(
                      "FAILED"
                    )} rounded-full w-full max-w-[30px] h-[30px] text-white flex items-center justify-center`}
                  >
                    N
                  </div>
                  <div className="w-full">
                    <div className="font-bold text-16">
                      {t("visit.new_customer")}
                    </div>
                    <div className="font-medium text-13">
                      {visit.outlet_name_en}
                    </div>
                    <div className="font-medium text-13">
                      {t("visit.customer_id")}: {visit.outlet_id || "-"}
                    </div>
                    {getRoleAccess('Delivery') && <div className="font-medium text-13">
                      {t("visit.delivery_id")}: {visit.delivery_number || '-'}
                    </div>}
                    <div className="font-medium text-13">
                      {t("visit.time_taken")}: {visit.total_visit_time || "-"}
                    </div>
                  </div>
                </div>
                <div>{getStatusComp(visit.outcome_status)}</div>
              </div>
              <div className="border-t-2 flex gap-2 justify-between p-2 mt-2">
                <div className="text-13 font-medium">
                  {t("visit.created_time")} :{" "}
                  <b>
                    {visit.customer_created_at
                      ? moment
                        .utc(visit.customer_created_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
              </div>
            </div>
          </div>
        ) : visit?.is_planned_visit ? (
          <div
            className={`flex items-center justify-between p-2 cursor-pointer ${routeForSelectedCustomer.sequence === visit?.sequence
              ? getColorForBorder(visit?.outcome_status)
              : ""
              } border rounded-md shadow-sm bg-white`}
          >
            <div className="items-center space-x-1 w-full">
              <div className="flex gap-2 justify-between w-full">
                <div className="flex gap-2 w-full">
                  <div
                    className={`${getColor(
                      visit?.outcome_status
                    )} rounded-full w-full max-w-[30px] h-[30px] text-white flex items-center justify-center`}
                  >
                    {visit?.sequence}
                  </div>
                  <div className="w-full">
                    <div className="font-bold text-16">
                      {visit?.sequence}
                    </div>
                    <div className="font-medium text-13">
                      {visit.outlet_name_en}
                    </div>
                    <div className="font-medium text-13">
                      {t("visit.customer_id")}: {visit.outlet_id || "-"}
                    </div>
                    {getRoleAccess('Delivery') && <div className="font-medium text-13">
                      {t("visit.delivery_id")}: {visit.delivery_number || '-'}
                    </div>}
                    <div className="font-medium text-13">
                      {t("visit.time_taken")}: {visit.total_visit_time || "-"}
                    </div>
                  </div>
                </div>
                <div>{getStatusComp(visit.outcome_status)}</div>
              </div>
              <div className="border-t-2 flex gap-2 justify-between p-2 mt-2">
                <div className="text-13 font-medium">
                  {t("visit.check_in")} :{" "}
                  <b>
                    {visit.check_in_at
                      ? moment
                        .utc(visit.check_in_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
                <div className="text-13 font-medium">
                  {t("visit.check_out")} :{" "}
                  <b>
                    {visit.check_out_at
                      ? moment
                        .utc(visit.check_out_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`flex items-center justify-between p-2 cursor-pointer ${routeForSelectedCustomer.sequence === visit?.sequence
              ? getColorForBorder(visit?.outcome_status)
              : ""
              } border rounded-md shadow-sm bg-white`}
          >
            <div className="items-center space-x-1 w-full">
              <div className="flex gap-2 justify-between w-full">
                <div className="flex gap-2 w-full">
                  <div
                    className={`${getColor(
                      visit?.outcome_status
                    )} rounded-full w-full max-w-[30px] h-[30px] text-white flex items-center justify-center`}
                  >
                    U
                  </div>
                  <div className="w-full">
                    <div className="font-bold text-16">
                      {t("visit.unplanned_visit")}
                    </div>
                    <div className="font-medium text-13">
                      {visit.outlet_name_en}
                    </div>
                    <div className="font-medium text-13">
                      {t("visit.customer_id")}: {visit.outlet_id || "-"}
                    </div>
                    {getRoleAccess('Delivery') && <div className="font-medium text-13">
                      {t("visit.delivery_id")}: {visit.delivery_number || '-'}
                    </div>}
                    <div className="font-medium text-13">
                      {t("visit.time_taken")}: {visit.total_visit_time || "-"}
                    </div>
                  </div>
                </div>
                <div>{getStatusComp(visit.outcome_status)}</div>
              </div>
              <div className="border-t-2 flex gap-2 justify-between p-2 mt-2">
                <div className="text-13 font-medium">
                  {t("visit.check_in")} :{" "}
                  <b>
                    {visit.check_in_at
                      ? moment
                        .utc(visit.check_in_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
                <div className="text-13 font-medium">
                  {t("visit.check_out")} :{" "}
                  <b>
                    {visit.check_out_at
                      ? moment
                        .utc(visit.check_out_at, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")
                      : "--:--"}
                  </b>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div className="py-2">
        <div className="bg-yellow-200 text-yellow-800 p-4 rounded-2xl flex items-center gap-4 w-full">
          <img src={IdleVehicle} alt="idle" className="w-12 h-12" />
          <div>
            <h3 className="text-lg font-semibold">Idle</h3>
            <p className="text-sm">User is not moving</p>
          </div>
        </div>
      </div> */}
      {/* <div className="py-2">
        <div className="bg-green-200 text-green-800 p-4 rounded-2xl flex items-center gap-4  w-full">
          <img src={DrivingVehicle} alt="driving" className="w-12 h-12" />
          <div>
            <h3 className="text-lg font-semibold">Driving</h3>
            <p className="text-sm">User is on the move</p>
          </div>
        </div>
      </div> */}
    </div>

  );
};

interface VisitListProps {
  employeeSchedule: any;
  setRouteForSelectedCustomer: any;
  routeForSelectedCustomer: any;
  loader: boolean;
}

const VisitList: React.FC<VisitListProps> = ({
  employeeSchedule,
  loader,
  setRouteForSelectedCustomer,
  routeForSelectedCustomer,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<any>("");

  const filteredVisits = employeeSchedule.customer_visits?.filter(
    (visit: any) =>
      searchTerm
        ? visit?.outlet_name_en
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase())
        : visit
  );

  return (
    <div className="">
      <div className="relative flex items-center p-2 pl-4 pr-4 border-b-2">
        <span className="absolute left-7">
          <img src={searchIcon} alt="Search Icon" className="h-5 w-5" />
        </span>
        <input
          type="text"
          placeholder={t("visit.serach_visit")}
          className="pl-10 p-2 border rounded w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="pl-4 pr-4 pt-4">
        <div className="border-2 w-full min-h-[40px] flex justify-between p-2 ">
          <div className="flex gap-2 items-center font-bold	text-16">
            <img src={clockIn} alt="clock in" />
            {t("visit.clock_in")}
          </div>
          <div className="font-bold	">
            {employeeSchedule?.employee_details?.clock_in
              ? moment
                .utc(
                  employeeSchedule?.employee_details?.clock_in,
                  "HH:mm:ss.SSSSSS"
                )
                .local()
                .format("hh:mm A")
              : "--:--"}
          </div>
        </div>
        {loader && <Loader />}
        <div className=" overflow-auto h-auto md:h-[calc(100vh-244px)] scrollbar scrollbar-thin ">
          {filteredVisits?.length > 0 && !loader ? (
            filteredVisits?.map((visit: any, index: number) => (
              <VisitItem
                key={index}
                visit={visit}
                setRouteForSelectedCustomer={setRouteForSelectedCustomer}
                routeForSelectedCustomer={routeForSelectedCustomer}
              />
            ))
          ) : (
            <p className="text-gray-500">{t("visit.no_visits_found")}</p>
          )}
          <div className="border-2 w-full min-h-[40px] flex justify-between p-2">
            <div className="flex gap-2 items-center font-bold	text-16">
              <img src={clockIn} alt="clock out" />
              {t("visit.clock_out")}
            </div>
            <div className="font-bold	">
              {employeeSchedule?.employee_details?.clock_out
                ? moment
                  .utc(
                    employeeSchedule?.employee_details?.clock_out,
                    "HH:mm:ss.SSSSSS"
                  )
                  .local()
                  .format("hh:mm A")
                : "--:--"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitList;
