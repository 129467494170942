import React, { useEffect, useState } from "react";
import Layerzamzam from "./Layerzamzam";
import ViewInventory from "./Pages/ViewInventory";
import Table from "./components/Table";
import { toast, ToastContainer } from "react-toastify";
import ComponentToPrint from "./Pages/ComponentToPrint";
import { metaData, tableHeaders } from "./constants";
import {
  getDataTobePrinted,
  getExportData,
  getHeader,
  getProductData,
  getWarehouseData,
} from "./api/inventory";
import { currentLang } from "../../utility/index";
export interface TableData {
  results: Array<{
    StorageLocName: string;
    StorageLocNameAr: string;
    NavStockToItem: Array<{
      MaterialId: string;
      MaterialDesc: string;
      MaterialDescAr: string;
      AvailableStock: string;
      InQualityInspection: string;
      Blocked: string;
      Return: string;
      InTransit: string;
      Unit: string;
      TotalAvailableStock: string;
      TotalInQualityInspection: string;
      TotalBlocked: string;
      TotalReturn: string;
      UnitText:string
      TotalInTransit: string;
      OpenQty: string;
      ShippedOrderQty : string;
      UoMTextAr: string;
      TotalOpenQty: string;
      TotalShippedOrderQty: string;
    }>;
    TotalAvailableStock: string;
    TotalInQualityInspection: string;
    TotalBlocked: string;
    TotalReturns: string;
    TotalInTransits: string;
    TotalOpenQty: string;
    TotalShippedOrderQty: string;

  }>;
}

const DashboardZamzam: React.FC = () => {
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [showSummesion, setShowSummesion] = useState(false);
  const [printTableData, setPrintTableData] = useState<TableData | null>(null);
  const [loading, setLoading] = useState(true);
  const [dataEmpty, setDataEmpty] = useState(false);
  const [language, setLanguage] = useState<string | null>(null);

  const [wareHouseOptions, setWareHouseOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([{productId: "null", productName: "No data available",productNameArabic: "لا توجد بيانات متاحة" }]);

  const [warehouseDropdown, setWarehouseDropdown] = useState<{
    isOpen: boolean;
    selectedOption: string[] | null;
  }>({ isOpen: false, selectedOption: null });

  const [productDropdown, setProductDropdown] = useState<{
    isOpen: boolean;
    selectedOption: string | null;
  }>({ isOpen: false, selectedOption: null });

  const toggleTableToShow = () => {
    getTableData();
  };
  const resetDD=()=>{
    setWarehouseDropdown({ isOpen: false, selectedOption: null });
    setProductDropdown({ isOpen: false, selectedOption: null });
    setProductOptions([]);
  
  }
  const header = getHeader();

  const handleClearData = () => {
    setShowTable(false);
    setShowSummesion(false);
    setLoading(true);
    setWarehouseDropdown({ isOpen: false, selectedOption: null });
    setProductDropdown({ isOpen: false, selectedOption: null });
    setProductOptions([]);
  };

  const exportData = async () => {
    console.log('222',language);
    if (warehouseDropdown.selectedOption == null) {
      if(language === "Arabic"){
        toast.error("يجب اختيار المخزن");
      }else{
        toast.error("Please select a warehouse");
      }
      return;
    }
    try {
      const response = await getExportData(
        warehouseDropdown,
        productDropdown.selectedOption,
        language
      );
      if (response.status === 200) {
        const data = response.data;
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "inventory_report.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getPrintableData = async () => {
    if (warehouseDropdown.selectedOption == null) {
      if(language === "Arabic"){
        toast.error("يجب اختيار المخزن");
      }else{
        toast.error("Please select a warehouse");
      }
      return;
    }
    try {
      const response = await getDataTobePrinted(
        warehouseDropdown,
        productDropdown.selectedOption
      );
      if (response.status === 200) {
        setPrintTableData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTableData = async () => {
    if (warehouseDropdown.selectedOption == null) {
      if(language === "Arabic"){
        toast.error("يجب اختيار المخزن");
      }else{
        toast.error("Please select a warehouse");
      }
      return;
    }
    // console.log('header',header);
    const env = process.env.REACT_APP_ENV;
    let baseURL: string = "";

    switch (env) {
      case "development":
        baseURL = process.env.REACT_APP_DEV_API_URL || "";
        break;
      case "staging":
        baseURL = process.env.REACT_APP_STAGING_API_URL || "";
        break;
      case "production":
        baseURL = process.env.REACT_APP_PROD_API_URL || "";
        break;
      default:
        console.error("No valid environment specified");
        return;
    }
    
    try {
      const warehouseIds = warehouseDropdown.selectedOption?.map(id => `warehouseId=${id}`).join("&");
      const response = await fetch(
        `${baseURL}/nwc/inventory?${warehouseIds}&productId=${productDropdown.selectedOption}`,
        {
          headers: header,
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTableData(data);
        setShowTable(true);
        setShowSummesion(true);
      }else {
        setShowTable(false);
        setDataEmpty(true);
        setShowSummesion(false);
        if(language === "Arabic"){
          // toast.error("لا يوجد بيانات متاحة لهذا المخزن");
        }else{
          // toast.error("No Data Found for this warehouse");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if(language === "Arabic"){
        toast.error("حدث خطأ ما");
      }else{
        toast.error("Internal Server Error");
      }
      setShowTable(false);
      setDataEmpty(false);
      setShowSummesion(false);
      setLoading(true);
    }
  };

  const getWareHouseOptions = async () => {
    try {
      const response = await getWarehouseData();
      if (response.status === 200) {
        const data = response.data;
        if(language === "Arabic"){
          setWareHouseOptions(
            data?.warehouses?.map(
              (item: { warehouseId: string; warehouseNameArabic: string }) => ({
                warehouseId: item.warehouseId,
                warehouseName: item.warehouseNameArabic,
              })
            )
          );
        }else{
          setWareHouseOptions(
            data?.warehouses?.map(
              (item: { warehouseId: string; warehouseName: string }) => ({
                warehouseId: item.warehouseId,
                warehouseName: item.warehouseName,
              })
            )
          );
        }
      }
    } catch (error) {
      console.error("Error fetching warehouse options:", error);
    }
  };

  const getProductOptions = async (warehouseId: any) => {
    try {
      const response = await getProductData(warehouseId);
      if(response.status === 200) {
        const data = response.data;
        if(data.products.length === 0){
          setProductOptions([{ productId: "null", productName: "No data available", productNameArabic: "No data available"}]);
          return;
        }
        setProductOptions(
          data.products?.map(
            (item: { MaterialId: string; MaterialDesc: string,MaterialDescAr:string  }) => ({
              productId: item.MaterialId,
              productName: item.MaterialDesc,
              productNameArabic: item.MaterialDescAr,
            })
          )
        );
      }
    } catch (error) {
      console.error("Error fetching product options:", error);
    }
  };

  // useEffect(() => {
  //   getWareHouseOptions();
  // }, []);

  useEffect(() => {
    if (language === null) {
      setLanguage("English");
    } else if (language) {
      getWareHouseOptions();
    }
  }, [language]);
  
  useEffect(() => {
    if (warehouseDropdown.selectedOption) {
      getProductOptions(warehouseDropdown);
    }
  }, [warehouseDropdown.selectedOption]);

  const printData = async () => {
    await getPrintableData();
  };

  // useEffect(() => {
  //   if (!printTableData || !showTable) {
  //     return;
  //   }
  
  //   console.log(showTable, "<<< Content of Printable Table");
  //   const printContent = document.getElementById("print-content");
  //   if (!printContent) return;
  
  //   const newWindow = window.open("", "_blank", "width=800,height=600");
  //   if (newWindow) {
  //     printContent.classList.remove("hidden");
  //     printContent.classList.add("block");
  
  //     const link = newWindow.document.createElement("link");
  //     link.href =
  //       "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
  //     link.rel = "stylesheet";
  //     newWindow.document.head.appendChild(link);
  //     const isArabic = currentLang() === "Arabic";
  
  //     link.onload = () => {
  //       newWindow.document.body.innerHTML = `
  //         <html>
  //           <head>
  //             <title>Print</title>
  //             <style>
  //               body {
  //                 direction: ${isArabic ? "rtl" : "ltr"};
  //                 text-align: ${isArabic ? "right" : "left"};
  //                 font-family: 'Arial', sans-serif;
  //                 padding: 20px;
  //               }
  //             </style>
  //           </head>
  //           <body>${printContent.outerHTML}</body>
  //         </html>
  //       `;
  
  //       newWindow.document.close();
  //       newWindow.focus();
  //       newWindow.print();
  //       newWindow.close();
  
  //       printContent.classList.remove("block");
  //       printContent.classList.add("hidden");
  //     };
  //   }
  // }, [printTableData]);
  

  useEffect(() => {
    if (!printTableData || !showTable) {
      return;
    }
  
    console.log(showTable, "<<< Content of Printable Table");
    const printContent = document.getElementById("print-content");
    if (!printContent) return;
  
    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
  
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);
      
      const isArabic = currentLang() === "Arabic";
  
      link.onload = () => {
        newWindow.document.body.innerHTML = `
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  direction: ${isArabic ? "rtl" : "ltr"};
                  text-align: ${isArabic ? "right" : "left"};
                  font-family: 'Arial', sans-serif;
                  padding: 20px;
                }
              </style>
            </head>
            <body>${printContent.outerHTML}</body>
          </html>
        `;
  
        newWindow.document.close();
        newWindow.focus();

        const images = newWindow.document.images;
        let loadedImages = 0;
  
        const checkAndPrint = () => {
          loadedImages++;
          if (loadedImages === images.length) {
            setTimeout(() => {
              newWindow.print();
              newWindow.close();
            }, 500);
          }
        };
  
        if (images.length > 0) {
          for (let img of Array.from(images)) {
            if (img.complete) {
              checkAndPrint();
            } else {
              img.onload = checkAndPrint;
              img.onerror = checkAndPrint;
            }
          }
        } else {
          newWindow.print();
          newWindow.close();
        }
  
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printTableData]);
  useEffect(()=>{
    const lang = currentLang();
    setLanguage(lang || "English");
    console.log(lang, "<<< lang");
  },[])

  return (
    <Layerzamzam>
      <ToastContainer />
      <div className="bg-gray-50 sm:pl-0 lg:pr-4">
        <ViewInventory
          toggleTableToShow={toggleTableToShow}
          toggleTableToHide={handleClearData}
          showTable={showTable}
          resetDD={resetDD}
          warehouseDropdown={warehouseDropdown}
          setWarehouseDropdown={setWarehouseDropdown}
          productDropdown={productDropdown}
          setProductDropdown={setProductDropdown}
          wareHouseOptions={wareHouseOptions}
          productOptions={productOptions}
          exportData={exportData}
          printData={printData}
        />
        {showTable ? (
          <Table
            showSummesion={showSummesion}
            loading={loading}
            setLoading={setLoading}
            tableData={tableData}
          />
        ):  dataEmpty &&<p className="text-center text-sm mt-3">{language === "Arabic" ? "لا يوجد بيانات متاحة" : "No Data Found"}</p> }
      </div>
      <div>
        <ComponentToPrint
          columns={tableHeaders}
          data={printTableData}
          metadata={metaData}
        />
      </div>
    </Layerzamzam>
  );
};

export default DashboardZamzam;
