import React, { useEffect, useState } from "react";
import Layerzamzam from "./Layerzamzam";
import { toast, ToastContainer } from "react-toastify";
import NewOrder from "./components/NewOrder";
import {
  confirmOrder,
  createOrder,
  getAddressDropdown,
  productList,
} from "./api/createOrder";
import FetchedData from "./components/FetchedData";
import ConfirmPoup from "./components/ConfirmPoup";
import URL_MAPPING from "../../routes/constants";
import { useNavigate } from "react-router-dom";
import { currentLang } from "../../utility/index";

const NewCreateOrder = () => {
  const [wareHouseId, setWareHouseId] = useState("");
  const [language, setLanguage] = useState<string | null>(null);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSummesion, setShowSummesion] = useState(false);
  const [warehouseData, setWarehouseData] = useState(null);
  const [salesQuantities, setSalesQuantities] = useState<
    { productId: number; quantity: number }[]
  >([]);
  const [warehouseDropdown, setWarehouseDropdown] = useState<{
    isOpen: boolean;
    selectedOption: string | null;
  }>({
    isOpen: false,
    selectedOption: null,
  });
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
 console.log('selectedAddress',selectedAddress);
  const [addressOptions, setAddressOptions] = useState<any>();
  const [addressError, setAddressError] = useState(false);
  const [toasterError, setToasterError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [orderId, setOrderId] = useState();
  const [summesionData, setSummessionData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null);
  const navigate = useNavigate();

    useEffect(()=>{
      const lang = currentLang();
      setLanguage(lang);
    },[])
  const getProductOptions = async (id: string ,dType: any ,outletID:any) => {
    try {
      setLoading(true);
      setWarehouseData(null);
      setSummessionData(null);
      const res = await productList(id,dType,outletID);
      setWarehouseData(res.results[0]?.NavStockToItem || []);
      setSummessionData(res.results[0] || {})
    } catch (error) {
      console.error("Error fetching product options:", error);
      toast.error("Error fetching product options")
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000)
    }
  };

  const toggleTableToShow = async () => {
  
    const errorMessages: Record<
      string,
      { shipment: string; delivery: string; address: string }
    > = {
      Arabic: {
        shipment: "يجب اختيار الموقع المرافق",
        delivery: "يجب اختيار الموقع المرافق",
        address: "يجب اختيار الموقع المرافق",
      },
      English: {
        shipment: "Please select shipment point",
        delivery: "Please Select Delivery By",
        address: "Please Select Address",
      },
    };

    const messages = errorMessages[language ?? "English"];

    if (!wareHouseId) {
      toast.error(messages.shipment);
      return;
    }

    const isDeliveryMissing = !warehouseDropdown.selectedOption;
    const isAddressMissing = !selectedAddress;

    // if (isDeliveryMissing && isAddressMissing) {
    //   toast.error(messages.delivery);
    //   setToasterError(true);
    //   setAddressError(true);
    //   toast.error(messages.address);
    //   return;
    // }

    if (isDeliveryMissing) {
      toast.error(messages.delivery);
      setToasterError(true);
      return;
    }

    if (isAddressMissing) {
      toast.error(messages.address);
      setAddressError(true);
      return;
    }

    setToasterError(false);
    setAddressError(false);
    setShowTable(true);

    await getProductOptions(wareHouseId,warehouseDropdown.selectedOption,selectedAddress);
  };

  const fetchAddressDropdown = async () => {
    setLoading(true);
    try {
      const response = await getAddressDropdown({});
      setAddressOptions(response.items);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // const handleAddressSelectChange = (
  //   e: React.ChangeEvent<HTMLSelectElement> | null
  // ) => {
  //   if (e === null) {
  //     setSelectedAddress(null);
  //     return;
  //   }
  //   setSelectedAddress(e.target.value);
  // };

  const handleAddressSelectChange = (event: React.ChangeEvent<HTMLSelectElement> | null) => {
    if (event) {
      const { value, dataset } = event.target;
      setSelectedAddressId(dataset.id || null);
      setSelectedAddress(value);
    }
  };
  
  const handleClear = () => {
    setWareHouseId("");
    setSelectedAddress(null);
    setSearchTerm("");
    setWarehouseDropdown({
      isOpen: false,
      selectedOption: null,
    });
    setShowTable(false);
    setLoading(true);
    setWarehouseData(null);
  };

  // const handlePlaceOrder = async () => {
  //   if (!wareHouseId) {
  //     toast.error(
  //       language === "Arabic"
  //         ? "يجب اختيار الموقع المرافق"
  //         : "Please select shipment point"
  //     );
  //     return;
  //   } else if (!warehouseDropdown.selectedOption) {
  //     toast.error(
  //       language === "Arabic"
  //         ? "يجب اختيار الموقع المرافق"
  //         : "Please Select Delivery By"
  //     );
  //     setToasterError(true);
  //     return;
  //   } else if (!selectedAddress) {
  //     setAddressError(true);
  //     toast.error(
  //       language === "Arabic"
  //         ? "يجب اختيار الموقع المرافق"
  //         : "Please Select Address"
  //     );
  //     return;
  //   } else if (salesQuantities.length === 0) {
  //     toast.error(
  //       language === "Arabic"
  //         ? "يجب اختيار الموقع المرافق"
  //         : "Please Select Quantities"
  //     );
  //     return;
  //   }
  //   let data = {
  //     addressId: String(selectedAddressId), 
  //     orderDetails: salesQuantities,
  //     deliveryBy: warehouseDropdown.selectedOption === "1" ? "BERAIN" : "NWC",
  //     // warehouseId: warehouseDropdown.selectedOption,
  //   };
  //   setToasterError(false);
  //   setAddressError(false);
  //   await createOrder(data)
  //     .then(async (res: any) => {
  //       setShowPopup(true);
  //       console.log('res',res);
  //       setOrderId(res.sapOrderId);
  //       setTimeout(() => {
  //         navigate(URL_MAPPING.ORDERHISTORY);
  //       }, 3000);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setShowPopup(false);
  //     });
  // };

const handlePlaceOrder = async () => {
  if (!wareHouseId) {
    toast.error(
      language === "Arabic"
        ? "يجب اختيار الموقع المرافق"
        : "Please select shipment point"
    );
    return;
  } else if (!warehouseDropdown.selectedOption) {
    toast.error(
      language === "Arabic"
        ? "يجب اختيار الموقع المرافق"
        : "Please Select Delivery By"
    );
    setToasterError(true);
    return;
  } else if (!selectedAddress) {
    setAddressError(true);
    toast.error(
      language === "Arabic"
        ? "يجب اختيار الموقع المرافق"
        : "Please Select Address"
    );
    return;
  } else if (salesQuantities.length === 0) {
    toast.error(
      language === "Arabic"
        ? "يجب اختيار الموقع المرافق"
        : "Please Select Quantities"
    );
    return;
  }

  let data = {
    addressId: String(selectedAddressId),
    orderDetails: salesQuantities,
    shippingPoint: wareHouseId,
    deliveryBy: warehouseDropdown.selectedOption === "1" ? "BERAIN" : "NWC",
  };

  setToasterError(false);
  setAddressError(false);

  try {
    const res: any = await createOrder(data);
    setShowPopup(true);
    console.log("res", res);
    setOrderId(res.sapOrderId);
    setTimeout(() => {
      navigate(URL_MAPPING.ORDERHISTORY);
    }, 3000);
  } catch (error: any) {
    console.error("Order Creation Failed:", error);

    // Handle different error cases
    if (error.response) {
      if (error.response.status === 500) {
        toast.error(
          language === "Arabic"
            ? "حدث خطأ في الخادم، يرجى المحاولة لاحقًا"
            : "Server error occurred, please try again later"
        );
      } else {
        toast.error(
          language === "Arabic"
            ? "فشل الطلب، يرجى المحاولة مجددًا"
            : "Order failed, please try again"
        );
      }
    } else {
      toast.error(
        language === "Arabic"
          ? "تعذر الاتصال بالخادم"
          : "Unable to connect to the server"
      );
    }

    setShowPopup(false);
  }
};

  useEffect(() => {
    fetchAddressDropdown();
  }, []);

  return (
    <Layerzamzam>
      <ToastContainer />
      <NewOrder
        toggleTableToShow={toggleTableToShow}
        handleClear={handleClear}
        showTable={showTable}
        showSummesion={showSummesion}
        loading={loading}
        handleSelectoption={(e: any) => {
          setWareHouseId(e.target.value);
        }}
        wareHouseId={wareHouseId}
        warehouseDropdown={warehouseDropdown}
        setWarehouseDropdown={setWarehouseDropdown}
        addressOptions={addressOptions}
        addressError={addressError}
        setAddressError={setAddressError}
        selectedAddress={selectedAddress}
        handleAddressSelectChange={handleAddressSelectChange}
        toasterError={toasterError}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setToasterError={setToasterError}
      />
      {showTable && (
        <FetchedData
          showSummesion={showSummesion}
          setShowSummesion={setShowSummesion}
          loading={loading}
          warehouseData={warehouseData}
          salesQuantities={salesQuantities}
          setSalesQuantities={setSalesQuantities}
          handlePlaceOrder={handlePlaceOrder}
          summesionData={summesionData}
        />
      )}
      {showPopup && <ConfirmPoup orderId={orderId} />}
    </Layerzamzam>
  );
};

export default NewCreateOrder;
