import axios, { instanceGps } from "./config";
import { getHeader, getUserInfo } from "../utility/index";
import Cookies from "js-cookie";

interface ApiResponse {
  data: any;
}

const getEmployeeList = async (data?:any): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const savedMode = localStorage.getItem("section");
  const mode= savedMode === 'My Team'? "SALES" :"DELIVERY"
  const response = await axios.get<ApiResponse>(
    `/supervisors/${user_info.emp_id}/subordinates?${data}`,
    {
      headers: getHeader(),
      params:{'team_type':mode}
    }
  );
  return response;
};

const getEmployeeSchedule = async (
  sub_id: any,
  params: any
): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.get<ApiResponse>(
    `/supervisors/${user_info.emp_id}/${sub_id}/schedule`,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response;
};

const getIdleTime = async (
  emp_id: any,
  data:any
): Promise<ApiResponse> => {
  const response = await instanceGps.get<ApiResponse>(
    `/gps/${emp_id}/idle_time`,
    {
      params:data,
      headers: getHeader(),
    }
  );
  return response;
};

const getMarkerInfo = async (
  sub_id: any,
  params: any
): Promise<ApiResponse> => {
  const user_info = getUserInfo();
  const response = await axios.get<ApiResponse>(
    `/supervisors/${user_info.emp_id}/${sub_id}/visit-info`,
    {
      headers: getHeader(),
      params: params,
    }
  );
  return response;
};

const getStream = async (data: any): Promise<ApiResponse> => {
  const response = await instanceGps.post<ApiResponse>("/gps/getStream", data, {
    headers: getHeader(),
  });
  return response;
};

const getStreamHistory = async (data: any): Promise<ApiResponse> => {
  const response = await instanceGps.post<ApiResponse>(
    "/gps/getHistory",
    data,
    { headers: getHeader() }
  );
  return response;
};

const getDistanceTravelled = async (id: any ,data:any): Promise<ApiResponse> => {
  const response = await instanceGps.post<ApiResponse>(
        `/gps/${id}/distance_travelled`,null,
    { params:data, headers: getHeader() }
  );
  return response;
};

const getStreamList = async (stream_id: any): Promise<ApiResponse> => {
  const token = Cookies.get("token");
  const response = await instanceGps.get<ApiResponse>(
    `/gps/stream/${stream_id}?token=${token}`
  );
  return response;
};

const getLastLocation = async (empids:any): Promise<ApiResponse> => {
  const response = await instanceGps.post<ApiResponse>(
    `/gps/last_locations`,empids, {  headers: getHeader() }
  );
  return response;
};

const getSnappedHistory = async (data:any): Promise<ApiResponse> => {
  const response = await instanceGps.post<ApiResponse>(
    `/gps/get_snapped_history`,data, {  headers: getHeader() }
  );
  return response;
};

const getDeviation = async (data:any): Promise<ApiResponse> => {
  const response = await instanceGps.post<ApiResponse>(
    `/gps/get_deviation`,data, {  headers: getHeader() }
  );
  return response;
};

export default getEmployeeList;
export {
  getEmployeeSchedule,
  getStream,
  getStreamHistory,
  getStreamList,
  getMarkerInfo,
  getSnappedHistory,
  getLastLocation,
  getDistanceTravelled,
  getDeviation,
  getIdleTime
};
